import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import { ADDONS_EMAIL_DETAIL } from 'app/const/Api';
import { SMS_GET_LIST_SENDER } from 'app/const/api/Sms';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER,
    SMART_VIEW_BULK_ACTIONS,
    SMART_VIEW_BULK_TYPES,
    SMART_VIEW_CUSTOM_EVENTS
} from '../../constants';
import { useSmartView } from '../../context';
import { convertToQuery } from '../utils/query';
import { sortToQuery } from '../utils/sortQuery';
import BulkContent from './components/BulkEmailContent';
import RowWithDropdown, { convertData } from './components/RowWithDropdown';
import WarningDisplay from './components/WarningDisplay';
import WarningSending from './components/WarningSending';

const BulkModal = forwardRef((props, ref) => {
    const initialState = {
        isOpen: false,
        canSend: false,
        isPreviewing: false,
        phone: '',
        typeBulk: SMART_VIEW_BULK_TYPES.EMAIL,
        type: OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER[0],
        dataSmartView: {},
        dataPreview: null
    };
    const { t } = useTranslation('smartView');
    const { count, filtered, sort, limit } = useSmartView();
    const totalSend = limit ? (limit >= count ? count : limit) : count || 0;
    const emailAddon = useSelector(({ auth }) => auth.user.settings.addons.inbox_email);
    const smsAddon = useSelector(({ auth }) => auth.user.settings.addons.smsplivo);

    const [state, dispatchState] = useReducer(reducer, initialState);
    const { isOpen, isPreviewing, isLoading, emailAccount, dataPreview, canSend, type, typeBulk, phone } = state;
    const bulkAction = SMART_VIEW_BULK_ACTIONS[typeBulk];

    useImperativeHandle(ref, () => ({ open: _handleOpen, close: _handleClose }));
    const refButtonSave = useRef(null);
    const refWarningSending = useRef(null);

    const _handleOpen = (dataSmartView = {}, typeBulk = SMART_VIEW_BULK_TYPES.EMAIL) => {
        const isEmailBulk = typeBulk === SMART_VIEW_BULK_TYPES.EMAIL;
        const isLoading = isEmailBulk ? !!emailAddon : !!smsAddon;

        dispatchState((prevState) => ({
            ...prevState,
            typeBulk,
            isOpen: true,
            isLoading,
            canSend: isEmailBulk ? !!emailAddon : !!smsAddon,
            dataSmartView,
            emailAccount: null
        }));

        if (emailAddon && isEmailBulk) {
            clientQuery(ADDONS_EMAIL_DETAIL, {}, ({ data }) => {
                dispatchState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    emailAccount: data?.account,
                    canSend: !!data?.account?.smtp?.status
                }));
            });
        }

        if (smsAddon && !isEmailBulk) {
            clientQuery(SMS_GET_LIST_SENDER, { method: 'GET', data: {} }, ({ data }) => {
                const phones = data.map((item) => convertData(item));
                dispatchState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    phone: phones[0] || null,
                    phones
                }));
            });
        }
    };

    const _handleClose = () => {
        dispatchState(initialState);
    };

    const _handleBack = () => {
        dispatchState({ isPreviewing: false });
    };

    const _handlePreview = () => {
        dispatchState({ isPreviewing: true });
    };

    const _handleSend = () => {
        const dataSend = {
            template_id: dataPreview.id,
            send_type: type.value,
            query: JSON.stringify({
                q: { bool: { queries: [{ bool: { queries: convertToQuery(filtered) } }] } },
                sort: sortToQuery(sort),
                limit
            })
        };
        if (typeBulk === SMART_VIEW_BULK_TYPES.SMS) dataSend['sender'] = state.phone?.id || '';
        clientQuery(
            bulkAction['sendEndpoint'],
            { data: dataSend, method: 'POST', toFormData: false },
            _handleSendSuccess,
            _handleSendFail,
            _handleSendFinally
        );
    };

    const _handleSendSuccess = () => {
        refWarningSending.current.display({ message: bulkAction['sendingMessage'] });
    };

    const _handleSendFail = ({ message }) => {
        const customEvent = new CustomEvent(SMART_VIEW_CUSTOM_EVENTS.DISPLAY_ALERT, {
            detail: { notification: message[0], timeout: 3000 }
        });
        dispatchEvent(customEvent);
    };

    const _handleSendFinally = () => {
        _handleClose();
    };

    const _handleSelectPhone = (phone) => {
        dispatchState((prevState) => ({ ...prevState, phone }));
    };

    const _handleSelectTemplate = (template) => {
        dispatchState((prevState) => ({ ...prevState, dataPreview: template }));
    };

    const _handleSelectType = (type) => {
        dispatchState({ type });
    };

    const _handleSetLoading = (loading = false) => {
        refButtonSave.current?.setDisable(loading);
    };

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                style={{ overlay: { background: 'transparent' } }}
                ariaHideApp={false}
                className={classNames('modal container-modal --email-smartview open', { '--preview': isPreviewing })}
                onRequestClose={_handleClose}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleClose} />
                <div className="modal__container show-contact">
                    <div className="header-modal">
                        <h2 className="header-modal__label fs-17 fw-600">{bulkAction['title']}</h2>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                            <IconClose />
                        </div>
                    </div>

                    {isPreviewing ? (
                        <BulkContent
                            dataEmail={emailAccount || {}}
                            count={totalSend}
                            phone={phone}
                            emailId={dataPreview?.id}
                            emailName={dataPreview?.label}
                            onSetLoading={_handleSetLoading}
                            typeBulk={typeBulk}
                        />
                    ) : (
                        <div className="body-modal">
                            {isLoading ? (
                                <div className="wrap-loading rows --lead bg-white">
                                    <div className="field-h20">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="field-h20">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                            ) : (
                                <WarningDisplay emailAccount={emailAccount} count={totalSend} typeBulk={typeBulk} />
                            )}

                            <div className="has-form">
                                {typeBulk === SMART_VIEW_BULK_TYPES.EMAIL ? (
                                    <div className="rows">
                                        <div className="txt">{t('from')}</div>
                                        <input
                                            className="field-input is-disable"
                                            type="text"
                                            readOnly
                                            value={
                                                !emailAddon || isLoading
                                                    ? t('no_email_configured')
                                                    : `${emailAccount?.email}`
                                            }
                                        />
                                    </div>
                                ) : (
                                    <RowWithDropdown
                                        isPhoneSelect
                                        title={t('from')}
                                        typeBulk={typeBulk}
                                        isDeactivate={!smsAddon || isLoading}
                                        defaultSelect={state.phones?.[0]}
                                        options={state.phones || []}
                                        onSelect={_handleSelectPhone}
                                    />
                                )}
                                <RowWithDropdown
                                    title={t('template')}
                                    isTemplate
                                    typeBulk={typeBulk}
                                    options={[]}
                                    isDeactivate={!smsAddon || isLoading}
                                    defaultSelect={dataPreview || null}
                                    onSelect={_handleSelectTemplate}
                                />
                                <RowWithDropdown
                                    title={bulkAction['titleTypeSelect']}
                                    isDeactivate={!smsAddon || isLoading}
                                    onSelect={_handleSelectType}
                                    defaultSelect={type}
                                    options={OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER}
                                />
                            </div>

                            {type?.value === OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER[0].value ? (
                                <div className="rows border-top-grey-verylight-brown">
                                    <p className="txt-ellipsis">{bulkAction['warningSend']}</p>
                                </div>
                            ) : null}
                        </div>
                    )}

                    <div className="footer-modal btn-close">
                        {isPreviewing ? (
                            <div className="flex-1">
                                <div className="v2-btn-default has-icon --grey" onClick={_handleBack}>
                                    <IconArrow isPrev />
                                    {t('back')}
                                </div>
                            </div>
                        ) : null}

                        <span className="v2-btn-default --transparent" onClick={_handleClose}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            disabled={!canSend || isLoading || !dataPreview}
                            isNotLoading={!isPreviewing}
                            title={
                                isPreviewing
                                    ? totalSend > 1
                                        ? t('send_email_to_leads', { count: totalSend })
                                        : t('send_email_to_lead', { count: totalSend })
                                    : t('preview')
                            }
                            onSave={isPreviewing ? _handleSend : _handlePreview}
                        />
                    </div>
                </div>
            </ReactModal>
            <WarningSending ref={refWarningSending} />
        </>
    );
});

export default BulkModal;
