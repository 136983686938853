import { PAYMENT_CARD_CUSTOMER } from './Customers';
import {
    ALL_COLUMNS_PAYMENT,
    ALL_COLUMNS_RECURRING_INVOICE,
    ALL_SUBSCRIPTION_COLUMNS,
    CREDIT_COLUMNS_SELECT_OPTION,
    NEW_JOB_COLUMNS_SELECT_ALL
} from './report/ReportFilter';
import { REPORT_TYPE as REPORT_TYPE_CONTENT } from 'app/const/report/ReportTypeContent';
import { VOIP_TECHNICIAN_SEAT } from './Voip';

export const REPORT_TYPE = {
    INVOICE: 'invoice',
    ESTIMATE: 'estimate',
    DOCUMENT: 'document',
    CUSTOMERS: 'customers',
    CUSTOMER_PAYMENTS: 'customer_payments',
    DOCUMENT_CUSTOMER_LIST: 'document_customer_list',
    CREDIT_CUSTOMER_LIST: 'credit_customer_list',
    ESTIMATE_CUSTOMER_LIST: 'estimate_customer_list',
    CUSTOMER_INVOICES: 'customer_invoices',
    CUSTOMER_JOBS: 'customer_jobs',
    PAYMENT: 'payment',
    CREDIT: 'credit',
    RECURRING_INVOICE: 'recurring_invoice',
    SUBSCRIPTION: 'subscription',
    SERVICE_LOOKUP: 'service_lookup',
    LOCATIONS_WO_ACTIVE_JOBS: 'location_wo',
    CUSTOMERS_WO_ACTIVE_JOBS: 'customers_wo',
    ACCOUNTS_AGING: 'accounts_aging',
    SALES_FORECAST: 'sales_forecast',

    SALES_TAX_SUMMARY: 'sales_tax_summary',
    SALES_TAX_SUMMARY_SALES_TAX_TAB: 'sales_tax_tab',
    SALES_TAX_SUMMARY_TAX_SUMMARY_TAB: 'tax_summary_tab',

    REVENUE_SERVICE: {
        revenue_service_month: 'revenue_service_month',
        revenue_service_detail: 'revenue_service_detail'
    },
    REVENUE_CLIENT: {
        revenue_client_month: 'revenue_client_month',
        revenue_client_detail: 'revenue_client_detail'
    },
    REVENUE_ITEM: {
        revenue_item_month: 'revenue_item_month',
        revenue_item_detail: 'revenue_item_detail'
    },
    REVENUE_STAFF: {
        revenue_staff_month: 'revenue_staff_month',
        revenue_staff_detail: 'revenue_staff_detail'
    },
    REVENUE_SOURCE: {
        revenue_source_month: 'revenue_source_month',
        revenue_source_detail: 'revenue_source_detail'
    },
    REVENUE_TAGS: {
        revenue_tags_month: 'revenue_tags_month',
        revenue_tags_detail: 'revenue_tags_detail'
    },
    EMAIL_HISTORY: 'email_history',
    MATERIAL_USE: 'material_use',
    MATERIAL_USE_DETAIL: 'material_use_detail',
    TOTAL_SALES: 'totals_sales',
    PAYMENTS_COLLECTED: 'payments_collected',
    NEW_CUSTOMERS: 'new_customers',
    LEADS: {
        inbound_leads: 'inbound_leads',
        online_bookings: 'online_bookings'
    },
    ACTIVITY_COMPARISON: 'activity_comparison',
    CALL_ACTIVITY: 'call_activity',
    OPPORTUNITIES_PIPELINE: 'opportunities_pipeline',
    MANAGE_MULTIPLE_BRANCH: 'manage_multiple_branch',
    WORK_LOG: 'worklogs',
    SALES_FUNNEL: 'sales_funnel',
    BLOCK_NUMBERS: 'block_numbers',
    STATUS_CHANGES: 'status_changes',
    OPPORTUNITIES_PERMISSIONS: 'opportunity_permissions',
    CUSTOM_FIELDS_PERMISSIONS: 'custom_fields_permissions',
    CUSTOM_FIELDS_MANAGE: 'custom_fields_manage',
    CUSTOM_JOB_STATUS_PERMISSIONS: 'custom_job_status_permissions',
    CUSTOM_JOB_STATUS_MANAGE_DELETE: 'custom_job_status_manage_delete',
    ADVANCE_ROUTING_PERMISSIONS: 'advance_routing_permissions',
    CHATBOT_REPORT: 'chatbot_report',
    CHATBOT_TRAIN_AI: 'chatbot_train_ai',
    CHATBOT_CUSTOM_ANSWER: 'chatbot_custom_answer',
    CHATBOT_CONTENT: 'chatbot_content'
};

// Status Style
export const REPORT_STATUS_STYLE = {
    draft: 'draft',
    void: 'void',
    partial: 'partial',
    partial_paid: 'partial-paid',
    sent: 'sent',
    paid: 'paid',
    write_off: 'write-off',
    failed: 'failed',
    won: 'sent',
    invoiced: 'sent', // won & invoiced
    lost: 'sent',
    emailed: 'sent',
    pending: 'sent',
    signed: 'sent',
    pending_e_sig: 'sent',
    unconfirm: 'sent',
    confirm: 'sent',
    reschedule: 'sent',
    pending_confirmation: 'sent',
    completed: 'sent',
    canceled: 'sent',
    recurrence: 'sent',
    complete: 'sent',
    active: 'paid',
    past_due: 'sent'
};

export const REPORT_COLOR_STYLE = {
    default: '#000000',
    active: '#1eaa5c',
    inactive: ' #ee4646',
    border_color_tooltip_chart: '#3ee0e4',
    background_color_tooltip_chart: '#ffffff',
    fill_text_color: '#029f81',
    fill_text_align: 'center',
    fill_text_base_line: 'bottom'
};

export const REPORT_HEADER = {
    invoice: ['total'],
    estimate: ['total'],
    payment: [],
    document: [],
    credit: ['total', 'available_credit'],
    recurring_invoice: [],
    subscription: [],
    service_lookup: ['invoice_value', 'w_o_tax'],
    location_wo: [],
    customers_wo: [],
    accounts_aging: ['0_30_days', '31_60_days', '61_90_days', 'over_90_days', 'total', 'available_credit'],
    sales_forecast: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total'],
    sales_tax: ['taxable_sales', 'tax_rate', 'tax_collected', 'surcharge_tax'],
    tax_summary: ['invoice_total', 'taxable', 'tax_collected', 'surcharge_tax'],
    revenue_service_month: [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
        'total'
    ],
    revenue_service_detail: ['amount', 'w_o_tax'],
    revenue_client_month: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total'],
    revenue_client_detail: ['amount', 'w_o_tax'],
    revenue_item_month: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total'],
    revenue_item_detail: ['amount', 'w_o_tax'],
    revenue_staff_month: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total'],
    revenue_staff_detail: ['amount', 'w_o_tax'],
    revenue_source_month: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total'],
    revenue_source_detail: ['amount', 'w_o_tax'],
    revenue_tags_month: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total'],
    revenue_tags_detail: ['amount', 'w_o_tax'],
    email_history: [],
    material_use: [],
    material_use_detail: [],
    totals_sales: ['amount', 'w_o_tax'],
    payments_collected: ['amount', 'w_o_tax'],
    new_customers: [],
    inbound_leads: [],
    online_bookings: []
};

export const LIMIT_PHONE_NUMBER = 10;

export const SIGNATURE_STATUS = 'sign_draft';

export const WON_STATUS = 'won';

export const PORTAL_CONTACT_US = 'Portal / Contact Us';

export const KEY_SHOW_CHART = 'show_chart';

export const KEY_CHECK_METHOD_CREDIT = 2;

export const LIMIT_ROW_NUMBER = 7;

export const HEIGHT_TO_SCROLL = 100;

export const REPORT_LIMIT = 50;

export const REPORT_LIMIT_LOADMORE = 45;
export const REPORT_LIMIT_HEIGHT_COLUMN = 180;

export const SORT_ORDER = { ASC: 'asc', DESC: 'desc' };

export const MONTH = 'month';
export const YEAR = 'year';
export const TOTAL = 'total';
export const OUT_STANDING = 'outstanding';
export const TEMPLATES = 'templates';
export const LIST_PARAM_FIELD = [TOTAL, OUT_STANDING, TEMPLATES, VOIP_TECHNICIAN_SEAT.ROLE];
export const REPORTS_REORDER_COLUMNS = {
    [REPORT_TYPE_CONTENT.CREDIT]: CREDIT_COLUMNS_SELECT_OPTION,
    [REPORT_TYPE_CONTENT.PAYMENT]: ALL_COLUMNS_PAYMENT,
    [REPORT_TYPE_CONTENT.RECURRING_INVOICE]: ALL_COLUMNS_RECURRING_INVOICE,
    [REPORT_TYPE_CONTENT.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]: ALL_SUBSCRIPTION_COLUMNS,
    [REPORT_TYPE_CONTENT.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]]: ALL_SUBSCRIPTION_COLUMNS,
    [REPORT_TYPE_CONTENT.NEW_JOB]: NEW_JOB_COLUMNS_SELECT_ALL
};
export const SERVICE_TYPE = 'service_type';
export const INVOICE_NUMBER = 'invoice_number';
export const REPORT_BATCH_LIMIT = 100;
const FILTER_MERCHANT_SURCHARGE = -4;
const FILTER_DEPOSIT = -7;
const FILTER_LATE_PAYMENT_FEE = -8;
export const DEFAULT_ADDITIONAL_REVENUE_ITEMS = [
    { id: FILTER_MERCHANT_SURCHARGE, name: 'merchant_surcharge' },
    { id: FILTER_DEPOSIT, name: 'deposit' },
    { id: FILTER_LATE_PAYMENT_FEE, name: 'late_payment_fee' }
];
export const EXPORT_LIMIT = 2000;
