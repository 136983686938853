import classNames from 'classnames';
import React, {
    Fragment,
    cloneElement,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useReducer,
    useRef
} from 'react';
import { Manager, Popper, Reference } from 'react-popper';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import IconError from 'assets/icon/IconError';

const DropdownPopper = (
    {
        children,
        isDisable = false,
        isCalculateWidth = false,
        customStyle = {},
        isCheckHidden = false,
        isAlwayShowOptions = false,
        placement = 'bottom-end',
        modifiers = null,
        wrapperClassName = '',
        wrapperListClass = 'v2-dropdown__menu scrolls',
        buttonClassName = 'dropbtn v2-btn-default has-bg-grey --icon-sm',
        onOpen = () => {},
        onScroll = () => {},
        onClose = () => {},
        onKeydown = () => {},
        customButton = null,
        dropdownIcon = <IconPlus />,
        strategy = 'fixed',
        isUseToggle = false,
        isUseMouseDownOutSide = false,
        error = '',
        isShowIconError = true
    },
    ref
) => {
    const [state, dispatchState] = useReducer(reducer, { isVisible: false });
    const { isVisible } = state;

    const refDropdown = useRef(null);
    const refButton = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close, _toggle }));

    useEffect(() => {
        const eventMouse = isUseMouseDownOutSide ? 'mousedown' : 'click';

        if (isVisible) {
            document.addEventListener(eventMouse, handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
            onOpen();
        } else {
            document.removeEventListener(eventMouse, handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
            onClose();
        }

        return () => {
            document.removeEventListener(eventMouse, handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    const _open = (e) => {
        e && e.stopPropagation();
        !isVisible && dispatchState({ isVisible: true });
    };

    const _toggle = (e) => {
        e && e.stopPropagation();
        dispatchState({ isVisible: !isVisible });
    };

    const _close = () => {
        isVisible && dispatchState({ isVisible: false });
    };

    const handleClickOutside = (event) => {
        const elPrevent = refDropdown.current;
        const buttonPrevent = refButton.current;

        if (elPrevent && !elPrevent.contains(event.target) && buttonPrevent && !buttonPrevent.contains(event.target)) {
            _close();
        }
    };

    const handleHideDropdown = (event) => {
        const elPrevent = refDropdown.current;
        onKeydown(event);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _close();
        }
    };

    const setButtonRef = useCallback((node, ref) => {
        refButton.current = node;
        return ref(node);
    }, []);

    const setListRef = useCallback((node, ref) => {
        refDropdown.current = node;
        return ref(node);
    }, []);

    return (
        <Manager>
            <div
                className={classNames(wrapperClassName, {
                    active: isVisible,
                    disable: isDisable
                })}
            >
                <Reference>
                    {({ ref }) => (
                        <Fragment>
                            <div
                                ref={(node) => setButtonRef(node, ref)}
                                tabIndex={0}
                                className={classNames(buttonClassName, { 'field-error': !!error })}
                                onMouseDown={isUseToggle ? _toggle : _open}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {customButton || dropdownIcon}
                            </div>
                            {!!error ? (
                                <p className="txt-incorrect">
                                    {isShowIconError ? <IconError /> : null}
                                    {error}
                                </p>
                            ) : null}
                        </Fragment>
                    )}
                </Reference>

                {isVisible || (isAlwayShowOptions && refDropdown.current) ? (
                    <Popper
                        placement={placement}
                        strategy={strategy}
                        modifiers={modifiers || [{ name: 'offset', options: { offset: [0, 5] } }]}
                    >
                        {({ ref, style, placement, update, isReferenceHidden }) => {
                            const styleHiddenPopper = isCheckHidden && isReferenceHidden ? { display: 'none' } : {};
                            return (
                                <div
                                    ref={(node) => setListRef(node, ref)}
                                    onScroll={onScroll}
                                    style={
                                        isCalculateWidth
                                            ? {
                                                  ...style,
                                                  minWidth: refButton.current?.offsetWidth,
                                                  maxWidth: refButton.current?.offsetWidth,
                                                  ...customStyle,
                                                  ...styleHiddenPopper
                                              }
                                            : {
                                                  ...style,
                                                  ...customStyle,
                                                  ...styleHiddenPopper
                                              }
                                    }
                                    data-placement={placement}
                                    className={wrapperListClass}
                                >
                                    {typeof children?.type === 'string' ? children : cloneElement(children, { update })}
                                </div>
                            );
                        }}
                    </Popper>
                ) : null}
            </div>
        </Manager>
    );
};

export default forwardRef(DropdownPopper);
