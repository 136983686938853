import loadable from '@loadable/component';
import { React, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_LIST_CUSTOM_SETTING } from 'app/const/Api';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { getGridColumnsSettingCustom } from 'app/const/setting/SettingCustomTable';
import { LIST_TOOLTIP, TYPE_TEMPLATE, TYPE_TEMPLATE_ID } from 'app/const/Settings';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import SettingsTemplate from '../templates';

const GdConfirm = loadable(() => import('app/components/confirm'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ModalLoading = loadable(() => import('app/components/loading/ModalLoading'));
const AlertCustomer = loadable(() => import('app/modules/customer/components/AlertCustomer'));
const BoxTipsSetting = loadable(() => import('app/modules/settings/components/BoxTipsSetting'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const ActionHeader = loadable(() => import('./components/ActionHeader'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

const SettingsCustom = () => {
    const { t } = useTranslation(['setting']);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        checkedItems: { is_check_all: false, ids: [] },
        tutorial: {}
    });
    const { isLoading, data, checkedItems, tutorial } = state;
    const totalSelected = checkedItems.ids.length || 0;
    const ids = checkedItems.ids;
    const refConfirm = useRef(null);
    const refLoading = useRef(null);
    const refAlert = useRef(null);
    const refTemplate = useRef(null, data);

    useEffect(() => {
        _getListCustomSetting();
    }, []);

    const _getListCustomSetting = () => {
        !isLoading && dispatchState({ isLoading: true });

        const _success = ({ data, tutorial }) => {
            dispatchState({ data: data, isLoading: false, tutorial });
        };

        const _failed = (err) => {
            dispatchState({ data: [], isLoading: false });
            checkAccessFail(err, ACCESS_SETTINGS_TAB);
        };

        clientQuery(GET_LIST_CUSTOM_SETTING, { method: 'GET' }, _success, _failed);
    };

    const onChangeStateCheckedItems = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _handleDeleteTemplate = () => {
        refConfirm.current.open();
    };

    const _handleConfirmDelete = () => {
        refLoading.current._open();

        const _handleSuccessDelete = ({ message }) => {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.SUCCESS,
                message,
                type: LIST_STATUS.SUCCESS
            });
            dispatchState({
                data: data.filter((item) => !ids.includes(item.id)),
                checkedItems: { is_check_all: false, ids: [] }
            });
        };
        const _handleSuccessFinally = () => refLoading.current._close();

        clientQuery(
            GET_LIST_CUSTOM_SETTING,
            { data: { ids }, method: 'DELETE' },
            _handleSuccessDelete,
            null,
            _handleSuccessFinally
        );
    };

    const _renderContentConfirm = () => <p>{t('setting:warning_delete_template')}</p>;

    const _handleOpenTemplate = (item, type) => {
        const idTemplate = item?.id || null;
        refTemplate.current.displayTemplate(idTemplate || 'default', type, !!!idTemplate, data, true);
    };

    const _handleCloseTemplate = (newTemplate, isCreate = false, message = '') => {
        if (newTemplate) {
            if (isCreate) {
                dispatchState({ data: [...data, newTemplate] });
                refAlert.current.showStatusBar({
                    id: LIST_STATUS.SUCCESS,
                    message,
                    type: LIST_STATUS.SUCCESS
                });
            } else {
                dispatchState({
                    data: data.map((item) => {
                        if (item.id === newTemplate.id) return { ...item, ...newTemplate };
                        return item;
                    })
                });
            }
        }
    };

    const _renderHeaderRight = () => {
        return (
            <div
                className="v2-btn-main has-icon svg-white js-toggle-details"
                onClick={() => _handleOpenTemplate(null, 1)}
            >
                <IconPlus /> {t('setting:add_message')}
            </div>
        );
    };

    return (
        <>
            <MainHeaderSettings contentRight={_renderHeaderRight} tutorial={tutorial} isLoading={isLoading} />
            <div className="wrapper-columns setting-custom">
                <div className="container-print contents-pages">
                    <AlertCustomer ref={refAlert} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.AUTOMATED_EMAILS} />
                    <ActionHeader
                        totalSelected={totalSelected}
                        onDelete={_handleDeleteTemplate}
                        checkedItems={checkedItems}
                    />
                    <GdGridView
                        showCheckBox
                        isLoading={isLoading}
                        content={data}
                        checkedItems={checkedItems}
                        classTable="has-checkbox scrolls-x --email"
                        fileTranslation={'setting'}
                        onChangeStateCheckedItems={onChangeStateCheckedItems}
                        onNameClick={_handleOpenTemplate}
                        msgEmpty={t('setting:create_your_first_template')}
                        {...getGridColumnsSettingCustom()}
                    />
                    <GdConfirm
                        ref={refConfirm}
                        renderContent={_renderContentConfirm()}
                        title={t('common:confirm')}
                        listButton={{ confirm: true, cancel: true }}
                        onConfirm={_handleConfirmDelete}
                    />
                    <ModalLoading ref={refLoading} />
                </div>
                <SettingsTemplate
                    ref={refTemplate}
                    type={TYPE_TEMPLATE.AUTO_MESSAGE}
                    templateType={TYPE_TEMPLATE_ID.CUSTOM}
                    onClose={_handleCloseTemplate}
                />
            </div>
        </>
    );
};

export default SettingsCustom;
