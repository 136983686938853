import React, { forwardRef, useId, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { KEY_CODE_ENTER } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import ButtonSave from '../button/ButtonSave';
import GDInputNumber from '../input/InputNumber';

const CustomValueModal = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const id = useId();
    const {
        title: defaultTitle = '',
        label: defaultLabel,
        placeholder: defaultPlaceholder = '',
        isIntegerNumber = null,
        maxLength = 5,
        isCustomText = false,
        onSave = () => {}
    } = props;

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        title: defaultTitle,
        label: defaultLabel,
        placeholder: defaultPlaceholder
    });
    const { isOpen, title, label, defaultValue = '', keyValue = '', placeholder } = state;
    const isDistanceRange = keyValue === 'distance';
    const integerNumber = isIntegerNumber !== null ? isIntegerNumber : !isDistanceRange;

    const refButtonSave = useRef(null);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({ open, close }));

    const open = ({ label = defaultLabel, title = defaultTitle, defaultValue = '', keyValue = '' }) => {
        dispatchState((prevState) => {
            return { ...prevState, isOpen: true, title, label, defaultValue, keyValue };
        });
    };

    const close = () => {
        dispatchState({ isOpen: false });
    };

    const handleSave = () => {
        onSave(refInput.current?.value);
        close();
    };

    const handleChange = (value) => {
        refButtonSave.current?.setDisable(!!value.length);
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id={id}
            isOpen={isOpen}
            style={{ overlay: { background: 'transparent' } }}
            portalClassName="ReactModalPortal_alert_customer"
            className="modal container-modal open"
            onRequestClose={close}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{title}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={close}>
                        <IconClose />
                    </div>
                </div>

                <div className="body-modal has-form scrolls">
                    <div className="rows --inmodal">
                        <span className="txt">
                            <span className="flex-1 txt-ellipsis">{label}</span>
                        </span>
                        {isCustomText ? (
                            <input
                                ref={refInput}
                                className="field-input"
                                type="text"
                                autoFocus
                                placeholder={placeholder}
                                defaultValue={defaultValue}
                                onChange={handleChange}
                                onKeyDown={(e) => e.keyCode === KEY_CODE_ENTER && handleSave()}
                            />
                        ) : (
                            <GDInputNumber
                                ref={refInput}
                                className="field-input"
                                type={isDistanceRange ? 'number' : 'text'}
                                integerNumber={integerNumber}
                                placeholder={placeholder || '5'}
                                autoFocus
                                exceptNegative
                                maxLength={maxLength}
                                defaultValue={defaultValue}
                                onChange={handleChange}
                                onEnter={handleSave}
                            />
                        )}
                    </div>
                </div>

                <div className="footer-modal">
                    <span className="v2-btn-default --transparent" onClick={close}>
                        {t('common:cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} defaultDisabled onSave={handleSave} />
                </div>
            </div>
        </ReactModal>
    );
});

CustomValueModal.displayName = 'CustomValueModal';
export default CustomValueModal;
