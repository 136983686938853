import { roundingNumber } from 'common/utils/NumberUtils';
import { MATERIAL_EDIT_FIELD_NAME } from '../consts';

export const calculateNumericValue = (value, defaultValue = 0) => {
    const valueTarget = Number(value);
    return isNaN(valueTarget) ? defaultValue : valueTarget && valueTarget >= 0 ? roundingNumber(valueTarget) : 0;
};

/// NEW CODE
const convertPreset = ({ key, data, presetData }) => {
    switch (key) {
        case MATERIAL_EDIT_FIELD_NAME.UNITS:
        case MATERIAL_EDIT_FIELD_NAME.AREAS:
        case MATERIAL_EDIT_FIELD_NAME.METHODS:
        case MATERIAL_EDIT_FIELD_NAME.CUSTOM_MATERIALS:
            return {
                data: data || [],
                presetData: presetData?.data || null,
                presetValues: presetData?.values || null,
                value: null
            };
        case MATERIAL_EDIT_FIELD_NAME.LOCATIONS:
        case MATERIAL_EDIT_FIELD_NAME.TARGETS:
            return { data: data || [], presetData: presetData?.data || [], selected: [] };
        case MATERIAL_EDIT_FIELD_NAME.DILUTIONS:
            return { presetData: presetData?.data || [], presetValues: presetData?.values || null };
        default:
            break;
    }
};
export const applyPresetData = ({ preset, data }) => {
    const result = {};
    for (const key in preset) {
        if (Object.prototype.hasOwnProperty.call(preset, key)) {
            const dataConvert = convertPreset({ key, data: data[key], presetData: preset[key] });
            if (dataConvert) result[key] = dataConvert;
        }
    }
    return result;
};
export const getDefaultDataMaterial = ({ materialData, data, materialPreset = {} }) => {
    const result = {};
    for (const key in materialData) {
        const element = materialData[key];
        let dataApply = {};
        const keyWithS = key + 's';
        if (!element) continue;
        switch (key) {
            case MATERIAL_EDIT_FIELD_NAME.LOCATION:
            case MATERIAL_EDIT_FIELD_NAME.TARGET:
                if (element.items) {
                    dataApply = { data: data[key], selected: element['items'] || [] };
                    if (materialPreset[keyWithS]) dataApply.presetData = materialPreset[keyWithS].data || null;
                    result[keyWithS] = dataApply;
                }
                break;
            case MATERIAL_EDIT_FIELD_NAME.UNIT:
            case MATERIAL_EDIT_FIELD_NAME.AREA:
            case MATERIAL_EDIT_FIELD_NAME.METHOD:
            case MATERIAL_EDIT_FIELD_NAME.CUSTOM_MATERIAL:
                dataApply = {
                    data: data[keyWithS] || [],
                    selected: { value: element['quantity'] || null, id: element['id'] || '' }
                };
                if (materialPreset[keyWithS]) {
                    dataApply.presetData = materialPreset[keyWithS].data || null;
                    dataApply.presetValues = materialPreset[keyWithS].values || null;
                }
                result[keyWithS] = dataApply;
                break;
            case MATERIAL_EDIT_FIELD_NAME.DILUTION:
                dataApply = { ...element };
                if (materialPreset[keyWithS]) dataApply.presetValues = materialPreset[keyWithS].values || null;
                result[keyWithS] = dataApply;
                break;
            default:
                break;
        }
    }
    return result;
};
export const clearDataMaterial = ({ items = [], rules = {} }) => {
    const result = {};
    for (const key in rules) {
        const element = rules[key];
        if (!element.status) continue;
        switch (key) {
            case MATERIAL_EDIT_FIELD_NAME.LOCATION:
            case MATERIAL_EDIT_FIELD_NAME.TARGET:
                result[key + 's'] = { data: items[key + 's'] || [], selected: [] };
                break;
            case MATERIAL_EDIT_FIELD_NAME.UNIT:
            case MATERIAL_EDIT_FIELD_NAME.AREA:
            case MATERIAL_EDIT_FIELD_NAME.METHOD:
            case MATERIAL_EDIT_FIELD_NAME.CUSTOM_MATERIAL:
            case MATERIAL_EDIT_FIELD_NAME.DILUTION:
                result[key + 's'] = { data: items[key + 's'] || [], selected: { value: '', id: '' } };
                break;
            default:
                break;
        }
    }
    return result;
};
