import React, { useReducer, useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    LIST_TYPE_PACKAGE,
    LIST_TYPE_TERM,
    PLAN_PACKAGE_NAME,
    getRoutePlanSuccess
} from 'app/const/setting/SettingPlans';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    GET_DETAIL_ACCOUNT,
    GET_LIST_ACCOUNT_PLAN,
    GET_LIST_ACCOUNT_CARD,
    ACTIVE_PLAN_WITH_OLD_CARD,
    ACTIVE_PLAN_WITH_NEW_CARD
} from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import { useLocation, useHistory } from 'react-router-dom';
import loadable from '@loadable/component';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelPlan } from 'app/modules/mixpanel/MixpanelPlan';
import { MIXPANEL_EVENT_NAME } from 'app/const/Mixpanel';
import { countSavedFee, formatPrice } from 'common/utils/SettingUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { ACCESS_ACCOUNT } from 'app/const/Permissions';
import { addBranchPath } from 'app/const/Branch';
import { capitalizeFirstLetter } from 'common/utils/StringUtils';
import { DEFAULT_CURRENCY_SYMBOL } from 'app/const/App';

const Loading = loadable(() => import('./components/Loading'));
const UpgradeSuccess = loadable(() => import('./components/UpgradeSuccess'));
const ConfirmCharge = loadable(() => import('./components/ConfirmCharge'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const AccountPlan = loadable(() => import('app/modules/settings/plans/components/AccountPlan'));
const PlanPackage = loadable(() => import('app/modules/settings/plans/components/PlanPackage'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

function SettingsPlans() {
    const { t } = useTranslation(['setting']);
    const location = useLocation();
    const history = useHistory();
    const { notifications, profile } = useSelector(({ auth }) => auth.user);
    const isDeleteSchedule = location?.state?.isDeleteSchedule || false;
    const [state, dispatchState] = useReducer(reducer, {
        term: LIST_TYPE_TERM.MONTH,
        users: 1,
        fee: 0,
        savedFee: 0,
        package: LIST_TYPE_PACKAGE.BASIC,
        isLoading: true,
        prevYearly: false,
        preFee: 0,
        plans: {},
        isExpired: false,
        accountPlan: null
    });

    const listCard = useRef({
        isFetch: false,
        cards: []
    });
    const refConfirmCharge = useRef(null);
    const refStatusBar = useRef(null);
    const refPackageDefault = useRef(null);

    const {
        term: finalTerm,
        users: finalNumberUsers,
        fee: finalFee,
        savedFee: finalSavedFee,
        package: finalPackage,
        preFee: finalPreFree,
        prevYearly: isRrevYearly,
        plans: finalPlans,
        accountPlan,
        isSuccess,
        isLoading,
        isExpired
    } = state;

    const isPaymentFailed = notifications.payment_failed;
    const isReactivePlan = isExpired || isPaymentFailed;
    const isUpgradePlan = finalFee > finalPreFree || isReactivePlan;
    const finalPrice = `${DEFAULT_CURRENCY_SYMBOL}${formatPrice(finalFee)}`;

    useEffect(() => {
        setTimeout(() => {
            isDeleteSchedule &&
                refStatusBar.current?.showStatusBar('success', location.state.message, LIST_STATUS.SUCCESS);
        }, 2000);

        clientQuery(GET_DETAIL_ACCOUNT, { method: 'GET' }, _getDetailSuccess, (err) =>
            checkAccessFail(err, ACCESS_ACCOUNT)
        );
    }, []);

    function _getDetailSuccess(response) {
        const responseData = { ...response.data };
        clientQuery(GET_LIST_ACCOUNT_PLAN, { method: 'GET' }, (response) => _getPlansSuccess(response, responseData));
    }

    function _getPlansSuccess(response, responseData) {
        const currentPlan = responseData.plan || {};
        const planName = currentPlan.name || '';
        const [currentPackage, currentUsers] = planName.split(' ');
        const currentTerm = responseData.period || 'mo';
        const currentPackageLowerCase = currentPackage.toLowerCase();
        const finalPackage = Object.values(LIST_TYPE_PACKAGE).includes(currentPackageLowerCase)
            ? currentPackageLowerCase
            : LIST_TYPE_PACKAGE.BASIC;
        const numberUsers = Number(currentUsers) || 1;
        refPackageDefault.current = finalPackage;
        dispatchState({
            fee: currentPlan.price || _getPackageData(finalPackage, response.data).price || 0,
            users: numberUsers,
            term: currentTerm === LIST_TYPE_TERM.YEAR ? currentTerm : LIST_TYPE_TERM.MONTH,
            package: finalPackage,
            isLoading: false,
            prevYearly: currentTerm === LIST_TYPE_TERM.YEAR,
            preFee: currentPlan.price || 0,
            plans: response.data,
            isExpired: responseData.trialLeft < 1,
            accountPlan: responseData.plan,
            savedFee: countSavedFee({ selectedPackage: finalPackage, listPlans: response.data, numUser: numberUsers })
        });

        clientQuery(GET_LIST_ACCOUNT_CARD, { method: 'GET' }, _getListCardSuccess, _getListCardFailed);
    }

    const getTermPlan = (term = finalTerm) => {
        return term === LIST_TYPE_TERM.YEAR ? 'annual' : term;
    };

    function _getPackageData(selectedPackage, listPans) {
        const selectedTerm = [...listPans[getTermPlan()][selectedPackage]];
        return selectedTerm.find((item) => item.schedule_num === finalNumberUsers) || {};
    }

    function _getListCardSuccess(response) {
        listCard.current = {
            isFetch: true,
            cards: response.data
        };
    }

    function _getListCardFailed(response) {
        refStatusBar.current?.showStatusBar(LIST_STATUS.ERROR, response.message, LIST_STATUS.ERROR);
    }

    function _handleChangePlan(value) {
        const listPans = { ...finalPlans };
        const selectedTerm = [...listPans[getTermPlan(value)][finalPackage]];
        const packageData = selectedTerm.find((item) => item.schedule_num === finalNumberUsers);
        dispatchState({
            fee: packageData.price,
            term: value
        });
    }

    function _handleChangeAmountUser(number) {
        const listPans = { ...finalPlans };
        const selectedTerm = [...listPans[getTermPlan()][finalPackage]];
        const packageData = selectedTerm.find((item) => item.schedule_num === number);
        if (!packageData) {
            return false;
        }
        dispatchState({
            fee: packageData.price,
            users: number,
            savedFee: countSavedFee({ selectedPackage: finalPackage, listPlans: listPans, numUser: number })
        });
    }

    function _handleChangePackage(selectedPackage) {
        dispatchState({
            fee: _getPackageData(selectedPackage, { ...finalPlans }).price || 0,
            package: selectedPackage,
            savedFee: countSavedFee({
                selectedPackage: selectedPackage,
                listPlans: { ...finalPlans },
                numUser: finalNumberUsers
            })
        });
    }

    function _handleSubmit() {
        const listCardTemp = [...listCard.current.cards];
        const cardDefault = listCardTemp.find((item) => item.is_default) || listCardTemp?.[0] || null;
        refConfirmCharge.current?._open(cardDefault, {
            plan: `${t(`setting:${PLAN_PACKAGE_NAME[finalPackage]}`)} ${finalNumberUsers}`,
            price: finalPrice
        });
    }

    function _handleSubmitCharge() {
        const listPans = { ...finalPlans };
        const selectedTerm = [...listPans[getTermPlan()][finalPackage]];
        const packageData = selectedTerm.find((item) => item.schedule_num === finalNumberUsers);

        clientQuery(
            ACTIVE_PLAN_WITH_OLD_CARD,
            {
                method: 'POST',
                data: {
                    plan_id: packageData.id,
                    plan_type: packageData.type
                }
            },
            _handleSubmitSuccess,
            _handleSubmitFailed
        );
    }

    function _handleSubmitNewCard(cardData) {
        const cardInfo = cardData.token || {};
        const cardDetail = cardInfo.card || {};
        const listPans = { ...state.plans };
        const selectedTerm = [...listPans[getTermPlan()][finalPackage]];
        const packageData = selectedTerm.find((item) => item.schedule_num === finalNumberUsers) || {};

        clientQuery(
            ACTIVE_PLAN_WITH_NEW_CARD,
            {
                method: 'POST',
                data: {
                    plan_id: packageData.id,
                    plan_type: packageData.type,
                    credit_card: cardDetail.last4,
                    card_expire_month: cardDetail.exp_month,
                    card_expire_year: cardDetail.exp_year,
                    card_name: cardDetail.name,
                    stripe_token: cardInfo.id
                }
            },
            _handleSubmitSuccess,
            _handleSubmitFailed
        );
    }

    function _handleSubmitFailed(response) {
        refConfirmCharge.current?._removeLoading(response?.message?.toString() || t('common:please_try_again'));
    }

    function _handleSubmitSuccess({ data }) {
        handleTrackingEvent(
            mixpanelPlan({
                id: profile.id,
                plan: data.plan,
                type: !accountPlan
                    ? MIXPANEL_EVENT_NAME.PURCHASE
                    : isUpgradePlan
                      ? MIXPANEL_EVENT_NAME.UPGRADE
                      : MIXPANEL_EVENT_NAME.DOWNGRADE
            })
        );
        refConfirmCharge.current?._close();
        refStatusBar.current?.showStatusBar('success', t('setting:handle_upgrade_success'), LIST_STATUS.SUCCESS);
        refPackageDefault.current = finalPackage;
        history.push({
            pathname: addBranchPath(getRoutePlanSuccess({ isUpgrade: isUpgradePlan, isTrial: !accountPlan })),
            search: `?plan=${capitalizeFirstLetter(finalPackage)}&term=${capitalizeFirstLetter(
                getTermPlan()
            )}&price=${finalFee}&value=${finalTerm === LIST_TYPE_TERM.YEAR ? finalFee : finalFee * 12}`,
            state: { promptSMSPlan: !accountPlan }
        });
    }

    function _renderButton() {
        if (isReactivePlan) {
            return (
                <p onClick={_handleSubmit} className="v2-btn-main">
                    {t('setting:reactivate')}
                </p>
            );
        }

        if (isUpgradePlan) {
            return (
                <p onClick={(e) => _handleSubmit(e)} className="v2-btn-main">
                    {t('setting:upgrade')}
                </p>
            );
        }

        if (finalFee === finalPreFree && refPackageDefault.current === finalPackage) {
            return <p className="status-btn unpaid">{t('setting:current_plan')}</p>;
        }

        return (
            <p onClick={(e) => _handleSubmit(e)} className="v2-btn-main">
                {t('setting:downgrade')}
            </p>
        );
    }

    const renderLayoutSuperAdminAccount = () => {
        if (isLoading) {
            return <Loading />;
        }

        if (isSuccess) {
            return <UpgradeSuccess />;
        }

        return (
            <div className="contents-pages scrolls setting-plans">
                {isPaymentFailed && (
                    <div className="alert --error">
                        <p className="alert__description">{t('setting:account_have_failed_recurring_payment')}</p>
                    </div>
                )}
                {isExpired && !isPaymentFailed && (
                    <div className="alert --danger">
                        <p className="alert__description">
                            {t(
                                !!accountPlan
                                    ? 'setting:account_have_plan_expired'
                                    : 'setting:your_free_trial_has_expired_please_subscribed_to_a_plan_to_continue_using_gorilladesk'
                            )}
                        </p>
                    </div>
                )}
                <StatusBar ref={refStatusBar} />
                <div className="page-plans">
                    <AccountPlan
                        users={finalNumberUsers}
                        fee={finalFee}
                        savedFee={finalSavedFee}
                        term={finalTerm}
                        packageType={finalPackage}
                        onChangeTerm={_handleChangePlan}
                        onChangeAmountUser={_handleChangeAmountUser}
                        isRrevYearly={isRrevYearly}
                        numberSchedules={finalPlans[getTermPlan()]?.[finalPackage]?.length || 1}
                    />

                    <PlanPackage selectedPackage={finalPackage} onChange={_handleChangePackage} />

                    <div className="boxs upgrade-plans">
                        <div className="plan-price">
                            <span className="price">{finalPrice}</span>
                            <span className="date-time">/{t(`common:${finalTerm}`)}</span>
                        </div>
                        {_renderButton()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <MainHeaderSettings isShowTutorial={false} />
            <div className="wrapper-columns">{renderLayoutSuperAdminAccount()}</div>
            <ConfirmCharge
                ref={refConfirmCharge}
                onSubmit={_handleSubmitCharge}
                onSubmitNewCard={_handleSubmitNewCard}
            />
        </Fragment>
    );
}

export default SettingsPlans;
