import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';

const Checkbox = ({ id, label, nameInput = '', defaultChecked = false }) => {
    const idCb = useId(id);
    const { t } = useTranslation('addons');

    return (
        <div className="check-items">
            <input id={idCb} type="checkbox" defaultChecked={defaultChecked} name={nameInput} />
            <div className="item-checkbox">
                <label htmlFor={idCb}>
                    <span className="txt-ellipsis dp-block txt-checkbox">{t(label)}</span>
                </label>
            </div>
        </div>
    );
};

const CheckboxColPreset = ({ name = '', data = [], typeValue = '' }) => {
    const { t } = useTranslation('addons');
    return (
        <div className="preset-column flex-column h-100 flex-1">
            <div className="preset-column__header">
                <p className="fw-500 txt-ellipsis">{t(name)}</p>
            </div>
            <div className="preset-column__content flex-1 flex-column gap-8 mt-1">
                {data.map((item, idx) => {
                    const nameInput = `[${typeValue}][${item.id}]`;
                    return (
                        <Checkbox
                            key={item.id || idx}
                            id={item.id}
                            label={item.value || item.name || item}
                            nameInput={nameInput}
                            defaultChecked={item.active}
                        />
                    );
                })}
            </div>
        </div>
    );
};
export default CheckboxColPreset;
