import React, { useEffect, useReducer } from 'react';

import { ADDONS_MATERIAL_PRESET } from 'app/config/routes';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { UPDATE_ADDONS_MATERIAL_OPTIONS } from 'app/const/Api';
import { ADDON_MATERIAL_USAGE_DETAIL } from 'app/const/api/Material';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import { AddonsOptionsItem } from '../components/AddonsOptions';
import Column from './column';
import HeaderItem from './components/HeaderItem';
import Loading from './components/Loading';

export default function AddonsMaterialUsage() {
    const [state, dispatchState] = useReducer(reducer, { isLoading: true });
    const { options = [] } = state;
    const materialPreset = options?.find((item) => item.keyword === ADDONS_FOOTER_OPTIONS.MATERIAL_PRESET);

    useEffect(() => {
        _getMaterialDetail();
    }, []);

    function _getMaterialDetail() {
        clientQuery(ADDON_MATERIAL_USAGE_DETAIL, { method: 'GET' }, _getMaterialDetailSuccess, checkAccessFail);
    }

    function _getMaterialDetailSuccess(response) {
        dispatchState({ ...response.data, isLoading: false });
    }

    function _renderColumns() {
        return state.materials.map((itemColumn) => {
            return <Column key={itemColumn.id} data={itemColumn} />;
        });
    }

    const _handleUpdate = ({ material_preset = 0 }) => {
        dispatchState((prev) => ({
            ...prev,
            options: prev.options.map((item) =>
                item.keyword === ADDONS_FOOTER_OPTIONS.MATERIAL_PRESET ? { ...item, value: material_preset } : item
            )
        }));
    };

    if (state.isLoading) return <Loading />;
    return (
        <div className="addons-wrap__container addons-wrap__material">
            <AddonHeaderDetail isLoading={state.isLoading} tutorial={state.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper material-content pb-0">
                    <div className="boxs boxs--no-border --hasline">
                        <AddonsBoxHeader
                            keyword={state.keyword}
                            name={state.name}
                            upgrade={state.upgrade}
                            status={state.status}
                            level={state.level}
                            urlUpdateStatus={UPDATE_ADDONS_MATERIAL_OPTIONS}
                        />
                        <HeaderItem options={state.options} />
                    </div>
                    {materialPreset ? (
                        <AddonsOptionsItem
                            key={materialPreset.key}
                            keyAddon="material.material_preset"
                            urlUpdate={UPDATE_ADDONS_MATERIAL_OPTIONS}
                            pathManage={ADDONS_MATERIAL_PRESET}
                            onUpdateValueSuccess={_handleUpdate}
                            {...materialPreset}
                        />
                    ) : null}
                </div>
                <div className="boxs-wrapper material-content-full mt-5">{_renderColumns()}</div>
            </div>
        </div>
    );
}
