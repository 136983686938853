import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { getPlanUser } from 'common/utils/PermissionUtils';
import QuestlogHeader from '../QuestlogHeader';
import { MENU_CONTENT } from './constants';
import MenuWithDropdown from './pinMenu/MenuWithDropdown';
import { KEY_ONBOARDING } from 'app/modules/onboarding/constant';

const PinMenus = forwardRef(({ addons = [], typeAccount, canEditSetting, onLinkClick = () => {} }, ref) => {
    const refQuestLog = useRef(null);
    const refDropdown = useRef(null);
    const refWrapper = useRef(null);
    const menuItemRefs = useRef([]);
    const dataPins = useSelector(({ auth }) => auth.user.settings.header_menu || []);
    const questLog = useSelector(({ auth }) => auth?.user?.settings?.questlog?.question_active);
    const worklogAddon = useSelector(({ auth }) => auth.user.settings?.addons?.work_log);
    const permissionTracking = useSelector(({ auth }) => !!auth.user.settings?.time_tracking?.tracking);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const [state, dispatchState] = useReducer(reducer, { menus: [], hiddenMenus: [] });
    const { hiddenMenus } = state;
    const { isProPlan, isGrowthPlan, isTrial } = getPlanUser(profileData);
    const hasPermissionWorklog = (isProPlan || isTrial || isGrowthPlan) && worklogAddon && permissionTracking;
    const finalHiddenMenus =
        Array.isArray(hiddenMenus) && !!hiddenMenus.length ? hiddenMenus.filter((item) => item !== KEY_ONBOARDING) : [];

    useEffect(() => {
        refQuestLog.current && refQuestLog.current.setActive(!!dataPins.includes(KEY_ONBOARDING) && questLog);
    }, [dataPins]);

    useImperativeHandle(ref, () => ({ setActive: (value) => refQuestLog.current?.setActive(value) }));

    const updateMenuVisibility = () => {
        if (!refWrapper.current) return;
        let visibleWidth = 0;

        const wrapperWidth = questLog ? refWrapper.current.clientWidth - 35 : refWrapper.current.clientWidth;
        let hiddenMenusTemp = [...dataPins];
        if (!hasPermissionWorklog) hiddenMenusTemp = hiddenMenusTemp.filter((item) => item !== 'worklog');

        const visibleMenusTemp = [];
        const hiddenMenusFinal = [];

        menuItemRefs.current.forEach((menuItemRef) => {
            if (!menuItemRef) return;
            const menuItemWidth = menuItemRef.clientWidth;
            if (visibleWidth + menuItemWidth <= wrapperWidth) {
                const newItem = hiddenMenusTemp.shift();
                visibleMenusTemp.push(newItem);
                menuItemRef.style.position = 'unset';
                menuItemRef.style.top = 0;
                menuItemRef.style.left = 0;
                menuItemRef.style.visibility = 'visible';
                visibleWidth += menuItemWidth;
            } else {
                const newItem = hiddenMenusTemp.shift();
                if (newItem) {
                    hiddenMenusFinal.push(newItem);
                    menuItemRef.style.position = 'fixed';
                    menuItemRef.style.top = 0;
                    menuItemRef.style.left = 0;
                    menuItemRef.style.visibility = 'hidden';
                }
            }
        });

        dispatchState((prevState) => {
            return { ...prevState, menus: visibleMenusTemp, hiddenMenus: hiddenMenusFinal };
        });
    };

    const resizeObserver = new ResizeObserver(() => {
        updateMenuVisibility();
    });

    useLayoutEffect(() => {
        resizeObserver.observe(refWrapper.current);
        return () => {
            resizeObserver.unobserve(refWrapper.current);
        };
    }, [dataPins, questLog]);

    const _closeDropdown = () => {
        refDropdown.current?._closeDropdown();
    };

    return (
        <div ref={refWrapper} className="horizontal-menu mr-1 dp-flex flexcenter">
            {Array.isArray(dataPins) && dataPins.length ? (
                <>
                    {dataPins.map((pin, index) => {
                        if (!hasPermissionWorklog && pin === 'work_log') return null;
                        if (pin === KEY_ONBOARDING) return null;
                        return (
                            <div
                                key={pin}
                                ref={(el) => {
                                    if (el) menuItemRefs.current[index] = el;
                                }}
                            >
                                <MenuWithDropdown
                                    key={pin}
                                    addons={addons}
                                    typeAccount={typeAccount}
                                    canEditSetting={canEditSetting}
                                    onCloseDropdown={_closeDropdown}
                                    onLinkClick={_closeDropdown}
                                    {...MENU_CONTENT[pin]}
                                />
                            </div>
                        );
                    })}
                    {!!finalHiddenMenus.length ? (
                        <CalendarDropdown
                            id="pin_more_menu"
                            ref={refDropdown}
                            wrapperClassName="wrap-nav nav-menu"
                            buttonClassName="dropbtn v2-btn-default nav btn-ellipsis"
                            wrapperListClass="v2-dropdown__menu p-2"
                            customDropButton={() => <IconThreeDots />}
                        >
                            <div className="flex-column gap-8">
                                {finalHiddenMenus.map((pin) => (
                                    <MenuWithDropdown
                                        key={pin}
                                        addons={addons}
                                        typeAccount={typeAccount}
                                        isInsideDropdown
                                        onLinkClick={onLinkClick}
                                        onCloseDropdown={_closeDropdown}
                                        {...MENU_CONTENT[pin]}
                                    />
                                ))}
                            </div>
                        </CalendarDropdown>
                    ) : null}
                </>
            ) : null}
            <QuestlogHeader ref={refQuestLog} />
        </div>
    );
});

export default PinMenus;
