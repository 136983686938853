import React from 'react';
import { useTranslation } from 'react-i18next';

import { ACCESS_TOKEN } from 'app/const/App';
import { getBranchId } from 'app/const/Branch';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { URL_PRINT_MASTER_KEY } from 'app/const/api/Export';
import { ACTION_MATERIAL_ADDON_OPTIONS } from 'app/const/api/Material';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';

let timerHide, timerShow;
export default function HeaderItem({ options }) {
    const { t } = useTranslation(['addons']);
    function _handleChange(e, key) {
        if (key === 'show_key') {
            timerShow && clearTimeout(timerShow);
            timerShow = setTimeout(() => {
                _handleUpdate(key, e.target.checked);
            }, 500);
        } else {
            timerHide && clearTimeout(timerHide);
            timerHide = setTimeout(() => {
                _handleUpdate(key, e.target.checked);
            }, 500);
        }
    }

    function _handleUpdate(keyword, value) {
        clientQuery(ACTION_MATERIAL_ADDON_OPTIONS, {
            method: 'PUT',
            data: { value: value ? 1 : 0, keyword }
        });
    }

    function _handleExport() {
        const token = getLocalStorageValue(ACCESS_TOKEN);
        const form = document.createElement('form');
        form.action = URL_PRINT_MASTER_KEY;
        form.method = 'POST';
        form.style.display = 'none';
        form.innerHTML = `
            <input name="token" value="${token}">
            <input name="gd-branch-id" value="${getBranchId()}">
        `;
        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();

        const elements = document.getElementsByClassName('print-master-key');
        elements.length > 0 && elements[0].parentNode.removeChild(elements[0]);
    }

    return (
        <div className="boxs__contents">
            <div className="description">
                {t(
                    'addons:this_is_where_you_add_a_master_list_of_all_your_materials_locations_target_pests_and_methods'
                )}
            </div>
            <div className="v2-btn-main" onClick={_handleExport}>
                {t('addons:print_master_key')}
            </div>
            <OptionsList data={options} onChange={_handleChange} />
        </div>
    );
}

const OptionsList = ({ data = [], onChange = () => {} }) => {
    if (data.length === 0) return null;
    return data.map((item) => {
        const itemKey = item.keyword;
        if (itemKey === ADDONS_FOOTER_OPTIONS.MATERIAL_PRESET) return null;
        return (
            <div key={itemKey} className="check-items">
                <input
                    id={itemKey}
                    type="checkbox"
                    defaultChecked={!!item.value}
                    onChange={(e) => onChange(e, itemKey)}
                />
                <div className="item-checkbox">
                    <label htmlFor={itemKey}>
                        <span className="txt-ellipsis">{item.name}</span>
                    </label>
                </div>
            </div>
        );
    });
};
