import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ADD_MATERIAL_STICKY } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { MATERIAL_STICKY_TYPES } from 'app/const/Sticky';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleStickyMaterial, sortSticky } from 'common/utils/FunctionUtils';
import PinToTop from './PinToTop';

const initializer = ({ data, presetData, isHaveValue = false, defaultSelected }) => {
    const initialState = { isShowPreset: !!presetData?.length, presetData, data };
    const options = presetData?.length ? presetData : data;
    initialState['options'] = sortSticky(options || []);
    const backupOption = isHaveValue ? initialState['options'][0] : {};
    initialState['selected'] = defaultSelected
        ? data.find((item) => item.id === defaultSelected) || backupOption
        : backupOption;
    return initialState;
};
const DropdownWithPreset = forwardRef((props, ref) => {
    const {
        typePin = '',
        forceUpdate = 0,
        isEdit = false,
        isHaveValue = false,
        data = [],
        presetData = null,
        selected: defaultSelected = '',
        name = '',
        onSelect = () => {}
    } = props;
    const isMaterial = typePin === MATERIAL_STICKY_TYPES['material'];
    const { t } = useTranslation(['jobDetail']);
    const [state, dispatchState] = useReducer(
        reducer,
        { data, isHaveValue: isHaveValue || isMaterial, presetData, defaultSelected },
        initializer
    );
    const { isShowPreset, options, selected } = state;
    const refDropdown = useRef(null);
    const refDataChange = useRef(defaultSelected);

    useImperativeHandle(ref, () => ({ getData: () => selected?.id || '' }));
    useEffect(() => {
        if (forceUpdate) {
            dispatchState((prevState) => ({
                ...prevState,
                ...initializer({
                    data,
                    isHaveValue: isHaveValue || isMaterial,
                    presetData,
                    defaultSelected: defaultSelected || refDataChange.current
                })
            }));
        }
    }, [forceUpdate]);

    const handleSelect = (item) => {
        onSelect(item.id || '');
        dispatchState((prevState) => ({ ...prevState, selected: item }));
        refDropdown.current?._closeDropdown();
        refDataChange.current = item.id || '';
    };

    const handleSticky = (sticky, itemId) => {
        const { ids, list: listData } = handleStickyMaterial(itemId, sticky, [...data]);
        const { list: listPreset } = handleStickyMaterial(itemId, sticky, [...(presetData || [])]);
        dispatchState((prevState) => ({
            ...prevState,
            data: listData,
            presetData: listPreset,
            options: isShowPreset ? listPreset : listData
        }));
        clientQuery(ADD_MATERIAL_STICKY, { method: 'PUT', data: { ids, type: typePin } });
    };

    const handleShowAll = () => {
        dispatchState((prevState) => ({
            ...prevState,
            isShowPreset: !isShowPreset,
            options: isShowPreset ? data : presetData
        }));
    };

    return (
        <>
            <CalendarDropdown
                ref={refDropdown}
                wrapperClassName={classNames('v2-dropdown flex-1', { 'ml-0': !isHaveValue })}
                wrapperListClass="v2-dropdown__menu content-checked"
                selected={selected?.value || selected?.name || ''}
                onSelect={handleSelect}
            >
                <div className="scrolls">
                    {isEdit && typePin === MATERIAL_STICKY_TYPES['method'] ? (
                        <div className="items" onClick={() => handleSelect({})} />
                    ) : null}
                    {options.map((item) => (
                        <div
                            key={item.id}
                            className={classNames('items', { active: selected?.id === item.id })}
                            onClick={() => handleSelect(item)}
                        >
                            <span className="word-break flex-1">{isShowPreset ? item.value : item.name}</span>
                            <PinToTop sticky={item.sticky} onPin={(sticky) => handleSticky(sticky, item.id)} />
                        </div>
                    ))}
                    {Array.isArray(options) && !options.length ? (
                        <ul>
                            <li className="items pointer-events-none">
                                {t('common:no_data_to_display', { title: name })}
                            </li>
                        </ul>
                    ) : null}
                    {isShowPreset && !!presetData ? (
                        <>
                            <div className="is-divider --horizontal" />
                            <div className="d-flex justify-center p-2" onClick={handleShowAll}>
                                <div className="v2-btn-default --transparent">{t('show_all_options')}</div>
                            </div>
                        </>
                    ) : null}
                </div>
            </CalendarDropdown>
        </>
    );
});

export default DropdownWithPreset;
