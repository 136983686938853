import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ADDONS_QUICKBOOKS_SYNC_LOG } from 'app/config/routes';
import { QUICK_BOOK_CUSTOMER_PAYMENTS, REPORT_PAYMENT_GET_LIST } from 'app/const/Api';
import { DELAY_TIME, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { TYPE_PAYMENT_FOR } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT, REPORT_LIMIT_LOADMORE } from 'app/const/Reports';
import { URL_EXPORT_PAYMENT } from 'app/const/api/Export';
import { QB_SYNC_STATUS, QUICK_BOOK_PAYMENT } from 'app/const/quickbook';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGridColumns } from 'app/const/report/Payments';
import { PAYMENT_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_LIST_BUTTON, REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import CustomerEditPayment from 'app/modules/customer/detail/payments/components/CustomerEditPayment';
import { _returnCheckValue, handleAbortController } from 'app/modules/customer/utils';
import { PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { deleteReportPaymentRequest } from 'common/redux/actions/reports/paymentAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { convertParamFields, createRowTotal, getLocalParamsReport, getTitleRowTotal } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const Export = loadable(() => import('app/modules/report/components/Export'));
const GdButton = loadable(() => import('app/components/button'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));
const SearchQBModal = loadable(() => import('app/components/quickbooks/SearchQBModal'));

function ReportPayment() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const history = useHistory();
    const isActiveQuickBook = useSelector((state) => state?.auth?.user?.settings?.addons?.quickbooks || false);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refresh: false,
        dataProgressBar: false,
        checkedItems: { is_check_all: false, ids: [] },
        data: [],
        isLoading: true,
        total: 0,
        refreshScreen: 0,
        totalPayments: null,
        isLoadmore: false,
        actionSuccess: 0
    });
    const refConfirm = useRef(null);
    const refAlert = useRef(null);
    const refQBModal = useRef(null);
    const refDuplicateInvoiceQB = useRef(true);
    const refViewPayment = useRef(null);
    const abortController = useRef(null);
    const {
        refreshScreen,
        checkedItems,
        isLoadmore: finalIsLoadmore,
        total: finalTotal,
        data: finalData,
        isLoading: finalIsLoading,
        actionSuccess
    } = dataReport;
    const idsChecked = checkedItems.ids;
    const idsCheckedLength = idsChecked.length;
    const numberCurrentData = finalData.length;

    const finalTypeReport = REPORT_TYPE.PAYMENT;
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', finalTypeReport);
    const paramsReport = getLocalParamsReport(keyLocalStore, finalTypeReport);

    useEffect(() => {
        getListReport(paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        if (
            actionSuccess &&
            numberCurrentData < finalTotal &&
            numberCurrentData < REPORT_LIMIT_LOADMORE &&
            !finalIsLoading &&
            !finalIsLoadmore
        ) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    isLoadmore: true
                };
            });
        }
    }, [actionSuccess]);

    useEffect(() => {
        const loadMoreTimer =
            finalIsLoadmore &&
            setTimeout(() => {
                getListReport({ ...paramsReport, offset: finalData.length }, true, false);
            }, DELAY_TIME);

        return () => {
            if (loadMoreTimer) clearTimeout(loadMoreTimer);
        };
    }, [finalIsLoadmore]);

    const getListReport = (params, notShowLoading = false, isReset = true) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        params = getNewAPIRequest(params);
        const checkShouldLoading = !notShowLoading && !finalIsLoading;

        const columns = params?.columns || [];
        if (idsCheckedLength || checkShouldLoading) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading,
                    checkedItems: checkShouldLoading ? { is_check_all: false, ids: [] } : prev.checkedItems
                };
            });
        }

        delete params?.columns;
        delete params?.filterTab;
        delete params?.currentPage;

        clientQuery(
            REPORT_PAYMENT_GET_LIST,
            {
                data: convertParamFields({ params, isEmptyFields: finalIsLoadmore }),
                method: 'GET',
                abortController: abortController.current
            },
            (response) => getListSuccess(response, columns),
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        if (!params.total) {
            params.total = 1;
        }
        params.payment_methods = params.payment_methods.toString();
        params.limit = REPORT_LIMIT;
        return params;
    };

    const _handleCreateRowTotal = ({ data = {}, columns = [], total = 0 }) => {
        return createRowTotal({
            data,
            columns: [...columns, 'view'],
            title: getTitleRowTotal({ title: 'payment', total }),
            isActiveQuickBook,
            typeReport: finalTypeReport
        });
    };

    const getListSuccess = ({ data, total, total_payments }, columns) => {
        dispatchActionReport((prev) => {
            const {
                checkedItems,
                isLoadmore: prevLoadMore,
                rowTotal: prevRowTotal,
                totalPayments: prevTotalPayments,
                total: prevTotal,
                data: prevData
            } = prev;
            let finalData = [...prevData, ...data];
            const newTotal = total ?? prevTotal ?? 0;
            finalData = finalData.map((item) => {
                return {
                    ...item,
                    method: prevLoadMore
                        ? item.method
                        : `${item.method}${_returnCheckValue(item.check_value, currency)}`,
                    isHidden:
                        [PAYMENT_METHODS.CREDIT, PAYMENT_METHODS.DEPOSIT].includes(item.method_name) &&
                        item.document?.type === TYPE_PAYMENT_FOR.INVOICE
                };
            });

            return {
                ...prev,
                isLoading: false,
                data: finalData,
                total: newTotal,
                refreshScreen: refreshScreen + 1,
                totalPayments: prevLoadMore ? prevTotalPayments : total_payments,
                isLoadmore: false,
                rowTotal: prevLoadMore
                    ? prevRowTotal
                    : _handleCreateRowTotal({ data: total_payments, columns, total: newTotal }),
                checkedItems: {
                    ...checkedItems,
                    ids: checkedItems.is_check_all ? finalData.map((item) => item.id) : checkedItems.ids
                }
            };
        });
    };

    const _handleTotal = ({ itemsDeleted, totalPayments, rowTotal, newTotal }) => {
        const newTotalPayments = { ...totalPayments };
        const totalPaymentsKeys = Object.keys(totalPayments);
        itemsDeleted.forEach((item) => {
            totalPaymentsKeys.forEach((key) => {
                newTotalPayments[key] -= item[key];
            });
        });
        const newRowTotal = rowTotal.map((item) => {
            if (totalPaymentsKeys.includes(item.id))
                return {
                    ...item,
                    totalAmount: newTotalPayments[item.id]
                };

            return item;
        });

        if (!newRowTotal[0].isCurrency)
            newRowTotal[0].title = t('report:total_rows', {
                number: newTotal,
                title: t('report:payments')
            });

        return { newTotalPayments, newRowTotal };
    };

    function getListFailed({ isAborted = false }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadmore: false }));
    }

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') {
            dispatchActionReport((prev) => ({
                ...prev,
                refresh: !prev.refresh,
                rowTotal: _handleCreateRowTotal({
                    data: prev.totalPayments,
                    columns: params?.columns,
                    total: prev.total
                })
            }));
        }
    };

    const _handleUpdate = () => {
        getListReport(getLocalStorage(keyLocalStore));
    };

    const _updateReportByFilter = () => {
        const params = getLocalStorage(keyLocalStore);
        params.offset = 0;
        params.currentPage = 1;
        setLocalStorage(keyLocalStore, params);
        getListReport(params);
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchActionReport({ checkedItems: stateCheckedItems });
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: finalTypeReport,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _handleClick = (row) => {
        const document = row.document;
        if (!document) return;
        switch (document.type) {
            case TYPE_PAYMENT_FOR.INVOICE:
                dispatch(actionOpenInvoice({ id: row.invoice_id, status: row.status, total: '', isRecurring: false }));
                break;
            case TYPE_PAYMENT_FOR.ESTIMATE:
                dispatch(actionOpenEstimate({ id: document.id, status: document.status }));
                break;
            default:
                break;
        }
    };

    const _deletePayments = () => {
        dispatchActionReport((prev) => {
            const { data, total } = prev;
            const newTotal = total - idsCheckedLength;
            return {
                ...prev,
                data: [...data].map((item) => {
                    if (idsChecked.includes(item.id)) {
                        item.manualHide = true;
                    }
                    return item;
                }),
                total: newTotal
            };
        });

        dispatch(deleteReportPaymentRequest({ ids: idsChecked }, _deletePaymentSuccess, _deletePaymentFailed));
    };

    const handleClickButton = (value) => {
        switch (value) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                refConfirm.current.open(null, t('message_delete_payments', { total: idsChecked.length }));
                break;
            case TYPE_BUTTON_ACTIONS.SYNC_QB:
                const data = { ids: idsChecked };
                if (idsCheckedLength === 1) {
                    const item = finalData.find((item) => item.id === idsChecked[0]);
                    if (item) data.invoice_id = item.invoice_id;
                }
                clientQuery(QUICK_BOOK_CUSTOMER_PAYMENTS, { data, method: 'POST' }, null, null, _redirectQuickbookLogs);
                break;
            default:
                break;
        }
    };
    const _redirectQuickbookLogs = () => {
        history.push(addBranchPath(ADDONS_QUICKBOOKS_SYNC_LOG));
    };

    function _deletePaymentFailed(response) {
        refAlert.current.handeAddStatus({
            id: 'delete_customer_error',
            message: response.message,
            type: LIST_STATUS.ERROR
        });
    }

    const _deletePaymentSuccess = (response) => {
        const errorResponse = response.error;
        const idsFailed = errorResponse?.items || [];
        const numberIdsFailed = idsFailed.length;

        if (!!errorResponse || !!numberIdsFailed) {
            refAlert.current.handeAddStatus({
                id: 'delete_payement_error',
                message: errorResponse.message,
                type: LIST_STATUS.ERROR
            });
        }

        refAlert.current.handeAddStatus({
            id: 'delete_payement_success',
            message: response.message,
            type: LIST_STATUS.SUCCESS
        });

        dispatchActionReport((prev) => {
            const { data: prevData, checkedItems, totalPayments, rowTotal, total } = prev;
            const prevCheckedIds = checkedItems.ids;
            const itemsDeleted = [];
            const newTotal = total + numberIdsFailed;
            const newData = prevData
                .map((item) => {
                    if (idsFailed.includes(item.id)) {
                        item.manualHide = false;
                    }
                    return item;
                })
                .filter((itemRemove) => {
                    if (!itemRemove.manualHide) return true;
                    itemsDeleted.push(itemRemove);
                    return false;
                });

            const { newTotalPayments, newRowTotal } = _handleTotal({ itemsDeleted, totalPayments, rowTotal, newTotal });

            return {
                ...prev,
                checkedItems: {
                    is_check_all: numberIdsFailed === idsChecked.length,
                    ids: prevCheckedIds.filter((item) => idsFailed.includes(item))
                },
                data: newData,
                total: newTotal,
                actionSuccess: Date.now(),
                totalPayments: newTotalPayments,
                rowTotal: newRowTotal
            };
        });
    };

    const _handleLoadmore = () => {
        const numberData = finalData?.length;

        if (!!numberData && numberData < finalTotal && !finalIsLoadmore) {
            dispatchActionReport({ isLoadmore: true });
        }
    };

    const _handleEditPayment = (data) => {
        const methodName = data.method_name || '';
        const dataCreditEdit = {
            ...data,
            method_name: { name: methodName, id: methodName.trim().toLowerCase() },
            check_number: data.check_number,
            memo: data.memo,
            amount: data.amount
        };

        refViewPayment.current._open(dataCreditEdit);
    };

    const _editSuccess = (message) => {
        refAlert.current.handeAddStatus({
            id: 'edit_sussess',
            message: message,
            type: LIST_STATUS.SUCCESS
        });

        getListReport(getLocalStorage(keyLocalStore));
    };

    const _handleSearchQuickBooks = (item) => {
        refQBModal.current._open(null, null, item?.invoice_id, item?.invoice_number);
    };

    const _handleDeleteInvoiceSuccessQB = (itemChecked, isErrorDuplicate) => {
        refDuplicateInvoiceQB.current = isErrorDuplicate;
        dispatchActionReport((prevState) => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.number === itemChecked.number) item.quickbooks.status = QB_SYNC_STATUS.FAIL;
                return item;
            })
        }));
    };

    const renderListActionButton = () => {
        let listButton = REPORT_LIST_BUTTON.PAYMENT;
        if (!isActiveQuickBook) {
            listButton = listButton.filter((button) => button.value !== TYPE_BUTTON_ACTIONS.SYNC_QB);
        }
        return listButton.map((item) => {
            return (
                <GdButton
                    key={item.id}
                    title={t(`report:${item.label}`)}
                    onClick={() => handleClickButton(item.value)}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: finalTotal })}
                    activePrint
                    url={URL_EXPORT_PAYMENT}
                    params={paramsReport}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_PAYMENTS}
                    isDisable={finalIsLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={finalTypeReport}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    function _renderHeaderBootom() {
        return (
            <div className={classNames('header --filter', { 'is-disable': !idsCheckedLength })}>
                <CheckBoxHeader isShowTotal total={finalTotal} checkedItems={dataReport.checkedItems} />
                {renderListActionButton()}
            </div>
        );
    }

    const gridColumn = getGridColumns(paramsReport?.order, paramsReport?.columns);

    return (
        <Fragment>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={finalTypeReport}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <GDStatusBar ref={refAlert} />
                    <HeaderBottom
                        typeReport={finalTypeReport}
                        filters={PAYMENT_LIST_FILTER}
                        handleChangeFilter={handleChangeFilter}
                        handleUpdate={_handleUpdate}
                        isLoading={finalIsLoading}
                        updateReportByFilter={_updateReportByFilter}
                    />

                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBootom()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column has-checkbox scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={finalData.filter((item) => !item.manualHide)}
                            showCheckBox
                            onEdit={_handleEditPayment}
                            fileTranslation={'report'}
                            handleClick={_handleClick}
                            checkedItems={dataReport.checkedItems}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            handleClickHeader={_handleActionHeader}
                            {...gridColumn}
                            quickbookActive={isActiveQuickBook}
                            typeQuickBook={QUICK_BOOK_PAYMENT}
                            styleQBSync={'col col-xs'}
                            isDuplicateInvoiceQB={refDuplicateInvoiceQB.current}
                            onSearchQB={_handleSearchQuickBooks}
                            showNumberQB={false}
                            isScroll
                            isLoadmore={finalIsLoadmore}
                            onScrollToEnd={_handleLoadmore}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={gridColumn.contentConfig}
                                    showCheckBox={true}
                                    {...props}
                                />
                            )}
                            isShowToolTip
                        />
                    </div>
                    <CustomerEditPayment ref={refViewPayment} editSuccess={_editSuccess} />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('delete_confirmation')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_deletePayments}
                    />
                </div>
            </div>

            {isActiveQuickBook && <SearchQBModal ref={refQBModal} onDeleteSuccess={_handleDeleteInvoiceSuccessQB} />}
        </Fragment>
    );
}

export default ReportPayment;
