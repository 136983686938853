import loadable from '@loadable/component';
import classNames from 'classnames';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { COMMON } from 'app/const/App';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { SOURCES } from 'app/const/setting/SettingPageName';
import { getTabParams } from 'app/const/setting/SettingParams';
import { LIST_BUTTONS, LIST_BUTTON_MESSAGES, TAB, getColumns } from 'app/const/setting/SettingSources';
import { LIST_TOOLTIP } from 'app/const/Settings';
import { getListSettingSources, updateStatusSettingSources } from 'common/redux/actions/settings/sourcesAction';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import {
    getLocalStorageSettingPage,
    removeLocalStorageSettingPage,
    updateLocalStorageSettingPage
} from '../utils/localStorage';
import { getNewParamsOrderStatus } from '../utils/statusOrder';

const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const GdButton = loadable(() => import('app/components/button'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ListButtonAction = loadable(() => import('app/modules/settings/components/ListButtonAction'));
const SettingTabsFilter = loadable(() => import('app/modules/settings/components/SettingTabsFilter'));
const SettingPagination = loadable(() => import('app/modules/settings/components/SettingPagination'));
const SourceForm = loadable(() => import('app/modules/settings/sources/components/SourceForm'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function SettingsSources() {
    const initState = useMemo(() => {
        return {
            data: [],
            isLoading: false,
            checkedItems: {
                is_check_all: false,
                ids: []
            },
            listTab: TAB.LIST,
            currentTab: getLocalStorageSettingPage({
                namePage: SOURCES,
                key: COMMON.CURRENT_TAB,
                defaultCurrentTab: TAB.DEFAULT_SELECTED
            }),
            total: 1,
            params: getLocalStorageSettingPage({ namePage: SOURCES, key: COMMON.PARAMS }),
            tutorial: {}
        };
    }, []);

    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();

    const [state, dispatchState] = useReducer(reducer, initState);
    const {
        data: listLineItems = [],
        checkedItems = {},
        currentTab = {},
        params: paramsSettingSources = {},
        tutorial,
        isLoading
    } = state || {};

    const refModal = useRef(null);
    const refConfirm = useRef(null);
    const totalSelected = checkedItems.ids.length;
    const refStatusBar = useRef(null);

    useEffect(() => {
        _getListSource(paramsSettingSources);
        return () => {
            removeLocalStorageSettingPage({ namePage: SOURCES });
        };
    }, []);

    // Callback after call API
    const _getListSourceSuccess = ({ data, total, tutorial }) => {
        dispatchState({
            ...state,
            isLoading: false,
            data,
            currentTab: getLocalStorageSettingPage({ namePage: SOURCES, key: COMMON.CURRENT_TAB }),
            checkedItems: { is_check_all: false, ids: [] },
            total,
            params: getLocalStorageSettingPage({ namePage: SOURCES, key: COMMON.PARAMS }),
            tutorial
        });
    };

    const _getListSourceFailure = (err) => {
        dispatchState({ ...state, isLoading: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _updateData = (arrayData, dataUpdated) => {
        const result = [...arrayData];
        for (var i in result) {
            if (result[i].id === dataUpdated.id) {
                result[i].name = dataUpdated.name;
                break; //Stop this loop, we found it!
            }
        }
        return result;
    };

    const _handleSuccess = (response, isEdit = false) => {
        if (isEdit) {
            const newDataSources = _updateData(listLineItems, response.data);
            dispatchState({ ...state, isLoading: false, data: newDataSources });
        }

        refStatusBar.current.showStatusBar(
            COMMON.SUCCESS,
            response.message || t('setting:create_source_success'),
            COMMON.SUCCESS
        );

        _getListSource(paramsSettingSources);
    };

    const _handleFailed = () => {
        dispatchState({ ...state, isLoading: false });
    };

    const _checkIsInLastPage = ({ total, offset, limit }) => {
        if (offset + limit >= total) return true;
        return false;
    };

    const _checkIsInFirstPage = ({ total, limit }) => {
        if (limit >= total) return true;
        return false;
    };

    const _updateStatusSourcesSuccess = (response) => {
        refModal.current.hideModal();
        const params = getLocalStorageSettingPage({ namePage: SOURCES, key: COMMON.PARAMS });
        const isLastPage = _checkIsInLastPage({ total: state.total, offset: params.offset, limit: params.limit });
        //Process went action in last page,
        if (isLastPage) {
            const isRemoveAllItemInPage = response.listIdChecked.length === listLineItems.length;
            const isInFirstPage = _checkIsInFirstPage({ total: state.total, limit: params.limit });
            if (isRemoveAllItemInPage && !isInFirstPage) {
                const newParams = params;
                newParams.offset = params.offset - params.limit;
                _getListSource(newParams, currentTab, false);
            }
        }
        if (!isLastPage) _getListSource(params, currentTab, false);
        refStatusBar.current.showStatusBar(COMMON.SUCCESS, response.message, COMMON.SUCCESS);
    };

    const _updateStatusSourcesFailure = (response) => {
        refStatusBar.current.showStatusBar(COMMON.ERROR, response.message, COMMON.ERROR);
        dispatchState({ ...state, isLoading: false });
    };

    // Dispatch call API
    const _getListSource = (params, tabId = currentTab, isLoading = true) => {
        updateLocalStorageSettingPage({
            namePage: SOURCES,
            value: { params: params, current_tab: tabId }
        });
        dispatchState({ isLoading: isLoading, params: params, currentTab: tabId });
        dispatch(getListSettingSources(params, _getListSourceSuccess, _getListSourceFailure));
    };

    const _handleChangeTab = (tabId) => {
        const newTabParams = getTabParams(tabId);
        const newParams = { ...paramsSettingSources, ...newTabParams, offset: 0 };
        _getListSource(newParams, tabId);
    };

    const _handleChangePage = (newParams) => {
        _getListSource(newParams);
    };

    const _onConfirmUpdateStatusSources = (params) => {
        const sourcesUpdated = params.ids;
        const newDataSources = listLineItems.filter((sourceItem) => {
            if (sourcesUpdated.includes(sourceItem.id)) return false;
            return true;
        });
        dispatchState({
            ...state,
            data: newDataSources,
            checkedItems: {
                is_check_all: false,
                ids: []
            }
        });
        dispatch(updateStatusSettingSources(params, _updateStatusSourcesSuccess, _updateStatusSourcesFailure));
    };

    const _handleClickButtonAction = (value) => {
        const listIdChecked = checkedItems.ids;
        if (listIdChecked.length) {
            refConfirm.current.open({ ids: listIdChecked, type: value }, t(`common:${LIST_BUTTON_MESSAGES[value]}`));
        }
    };

    const _onClickHeaderCallBack = (action) => {
        const newParams = getNewParamsOrderStatus({ params: paramsSettingSources, actionEvent: action });
        _getListSource(newParams);
    };

    // Dispatch action

    const _handleEditSources = (sourcesItem) => {
        const sourceSelected = { ...listLineItems.find((source) => source.id === sourcesItem.row.id) };
        refModal.current.showModal(sourceSelected);
    };

    const _handleAddItem = () => {
        refModal.current.showModal();
    };

    const _onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState({ ...state, checkedItems: stateCheckedItems });
    };

    const _renderHeaderLeft = () => {
        return <SettingTabsFilter list={state.listTab} onChange={_handleChangeTab} tabActive={currentTab} />;
    };

    const _renderHeaderRight = () => {
        return (
            <div className="modal-setting header-items">
                <GdButton
                    className="btn-modal v2-btn-main has-icon svg-white"
                    title={t('setting:add_source')}
                    iconSvg={<IconPlus />}
                    onClick={_handleAddItem}
                />
                <SourceForm ref={refModal} onHandleSuccess={_handleSuccess} onHandleFailed={_handleFailed} />
            </div>
        );
    };

    const _renderActionHeader = () => {
        return (
            <div
                className={classNames('header --filter', {
                    'is-disable': !totalSelected
                })}
            >
                <CheckBoxHeader checkedItems={checkedItems} />
                <ListButtonAction
                    list={LIST_BUTTONS[currentTab]}
                    fileTranslation="report"
                    onSubmit={_handleClickButtonAction}
                    disabled={!totalSelected}
                />
            </div>
        );
    };

    return (
        <>
            <MainHeaderSettings
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                tutorial={tutorial}
                isLoading={isLoading}
            />
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages maintables-page">
                    <StatusBar ref={refStatusBar} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.SOURCES} onAddNew={_handleAddItem} />
                    {_renderActionHeader()}
                    <div className="tab-contents box-auto has-footer">
                        <div className="tab-conts tab-content-active">
                            <GdGridView
                                isLoading={state.isLoading}
                                classTable="scrolls-x has-checkbox"
                                content={listLineItems}
                                showCheckBox
                                fileTranslation={'setting'}
                                handleClick={_handleEditSources}
                                checkedItems={state.checkedItems}
                                onChangeStateCheckedItems={_onChangeStateCheckedItems}
                                handleClickHeader={(event) => {
                                    _onClickHeaderCallBack(event);
                                }}
                                {...getColumns({
                                    actionStatus: state.params?.order,
                                    currentTab: state.currentTab
                                })}
                                isScroll
                            />
                        </div>
                    </div>
                    <SettingPagination totalItem={state.total} namePage={SOURCES} onSelect={_handleChangePage} />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:confirm')}
                        message={t('setting:are_you_sure_delete_this_item')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_onConfirmUpdateStatusSources}
                    />
                </div>
            </div>
        </>
    );
}

export default SettingsSources;
