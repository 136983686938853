import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { getColumnsParams, SORT_BY } from 'app/const/report/ReportFilter';
import { handleAbortController } from 'app/modules/customer/utils';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { createRowTotal, getLocalParamsReport, getTitleExport, getTitleRowTotal } from 'common/utils/ReportUtils';
import HeaderBottom from '../../components/HeaderBottom';
import MainHeaderReport from '../../components/MainHeader';
import ReportSearch from '../../components/ReportSearch';
import { SALE_FORECAST_KEY } from '../constant';

const StatusBar = loadable(() => import('app/components/status/statusbar'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const Export = loadable(() => import('app/modules/report/components/Export'));

const TableSalesForecast = ({
    reportType = '',
    filters = [],
    endPoint = '',
    urlExport = '',
    pageExport = 0,
    keyGetTitle = SALE_FORECAST_KEY.CLIENT.title,
    keyGetTotal = SALE_FORECAST_KEY.CLIENT.total,
    keyGetGridColumns = SALE_FORECAST_KEY.CLIENT.keyGetColumns,
    getGridColumns = () => {}
}) => {
    const { t } = useTranslation(['report']);
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    const paramsReport = getLocalParamsReport(keyLocal, reportType);
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true, rowTotal: [], refreshScreen: 0 });
    const { data = [], isLoading, rowTotal = [], refreshScreen } = state;
    const total = data.length;
    const gridColumns = getGridColumns(paramsReport.order, keyGetGridColumns) || {};

    const abortController = useRef(null);
    const refStatusBar = useRef(null);

    useEffect(() => {
        _getListSalesForecast();

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const _getListSalesForecast = () => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        dispatchState({ isLoading: true });
        const _handleSuccess = (response) => {
            const { data } = response;
            const total = data.length;
            dispatchState((prev) => ({
                isLoading: false,
                data,
                rowTotal: createRowTotal({
                    data: response[keyGetTotal] || {},
                    title: getTitleRowTotal({ title: keyGetTitle, total, isShowColon: true }),
                    columns: getColumnsParams([reportType]),
                    typeReport: reportType
                }),
                refreshScreen: prev.refreshScreen + 1
            }));
        };
        const _handleFail = ({ message, isAborted = false }) => {
            if (isAborted) return;
            showStatusBar({
                id: 'get_list_fail',
                message: message || t('common:action_failure'),
                refAlert: refStatusBar
            });
            dispatchState({ isLoading: false });
        };
        const params = { ...(getLocalStorage(keyLocal) || {}) };
        delete params?.columns;
        delete params?.currentPage;
        clientQuery(endPoint, { data: params, method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _getListSalesForecast
        });
    };

    const _handleChangeFilter = (_, type) => {
        if (type === SORT_BY) _getListSalesForecast();
    };

    const _renderHeaderLeft = () => {
        return (
            <HeaderBottom
                typeReport={reportType}
                filters={filters}
                isNotShowDateRange
                isLoading={isLoading}
                handleUpdate={_getListSalesForecast}
                handleChangeFilter={_handleChangeFilter}
            />
        );
    };

    const _renderHeaderRight = () => {
        return (
            <>
                <Export
                    title={getTitleExport({ total })}
                    isDisable={isLoading}
                    activePrint
                    params={paramsReport}
                    url={urlExport}
                    pageExport={pageExport}
                    refresh={refreshScreen}
                />
                <ReportSearch reportType={reportType} placeholder={t('search')} onKeyEnter={_getListSalesForecast} />
            </>
        );
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={reportType}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <StatusBar ref={refStatusBar} />
                    <div className="wrap-tables flex-column">
                        <GdGridView
                            isEmptyFlat
                            isLoading={isLoading}
                            classTable="scrolls-x --forecast table-multi-column has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={data}
                            fileTranslation="report"
                            {...gridColumns}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={rowTotal}
                                    contentConfig={gridColumns.contentConfig}
                                    showCheckBox={false}
                                    {...props}
                                />
                            )}
                            showRowTotalOnTop
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableSalesForecast;
