export default function IconProfile({ isProfileDropdown = false }) {
    if (isProfileDropdown) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.1919 13C16.4363 13 18.3295 14.4604 18.6079 16.4065L18.9944 19.1072L18.5816 19.378C17.9701 19.7792 17.2258 20 16.4456 20H7.54495C6.76378 20 6.01862 19.7787 5.40676 19.3766L4.99445 19.1057L5.38109 16.4065C5.65947 14.4604 7.55264 13 9.79703 13H9.99999C9.99999 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13H14.1919Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C13.6569 4 15 5.34315 15 7V7.99566L14.4846 10.0596C14.1998 11.1999 13.1753 12 12 12C10.8246 12 9.80001 11.2 9.51493 10.0597L9 8V7C9 5.34315 10.3431 4 12 4Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                ></path>
            </svg>
        );
    }

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 13.5C13.3807 13.5 14.5 11.933 14.5 10C14.5 8.067 13.3807 7.5 12 7.5C10.6193 7.5 9.5 8.067 9.5 10C9.5 11.933 10.6193 13.5 12 13.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.68848 17.2796C7.06396 16.8268 7.52281 16.4535 8.05702 16.1678C8.87184 15.7321 9.7847 15.5 11.3856 15.5H12.6126C14.2136 15.5 15.1264 15.7321 15.9413 16.1678C16.4446 16.4371 16.8811 16.784 17.244 17.2021"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
