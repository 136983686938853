import React from 'react';

import TableSalesForecast from './components/TableSalesForecast';
import { SALE_FORECAST_REPORT_CONFIGS } from './constant';

const ReportSalesForecast = ({ type = '' }) => {
    return <TableSalesForecast key={type} {...SALE_FORECAST_REPORT_CONFIGS[type]} />;
};

export default ReportSalesForecast;
