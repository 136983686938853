import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ADD_MATERIAL_STICKY } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { MATERIAL_STICKY_TYPES } from 'app/const/Sticky';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconClose from 'assets/icon/IconClose';
import IconTag from 'assets/icon/IconTag';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleStickyMaterial, sortSticky } from 'common/utils/FunctionUtils';
import PinToTop from './PinToTop';

const initializer = ({ data, presetData, defaultSelected }) => {
    const finalPreset = Array.isArray(presetData) && !!presetData.length ? presetData : [];
    const initialState = { isShowPreset: !!finalPreset.length, presetData: finalPreset, data };
    const options = !!finalPreset.length ? finalPreset : data;
    initialState['options'] = sortSticky(options || []);
    initialState['selected'] = defaultSelected.map((i) => i.id);
    return initialState;
};
const DropdownMultiSelectWithPreset = (
    { title = '', type = '', data = [], presetData = [], selected: defaultSelected = [], forceUpdate = 0 },
    ref
) => {
    const { t } = useTranslation(['jobDetail']);
    const [state, dispatchState] = useReducer(reducer, { data, presetData, defaultSelected }, initializer);
    const { isShowPreset, options, selected = [] } = state;
    const haveOptions = Array.isArray(options) && !!options.length;
    const refDropdown = useRef(null);
    const refSelected = useRef(defaultSelected);

    useEffect(() => {
        if (forceUpdate) {
            dispatchState((prev) => ({
                ...prev,
                ...initializer({ data, presetData, defaultSelected: refSelected.current || defaultSelected })
            }));
        }
    }, [forceUpdate]);

    useImperativeHandle(ref, () => ({ _getValue: () => selected }));

    const _handleSelect = (value) => {
        dispatchState((prev) => {
            const existingValues = prev.selected || [];
            const newSelected = existingValues.includes(value)
                ? existingValues.filter((v) => v !== value)
                : [...existingValues, value];
            refSelected.current = newSelected.map((id) => data.find((item) => item.id === id));
            return { ...prev, selected: newSelected };
        });
    };

    const _handleRemoveItem = (id, isAll = false) => {
        dispatchState((prev) => ({ ...prev, selected: isAll ? [] : prev.selected.filter((item) => item !== id) }));
    };

    const _handleSticky = (sticky, itemId) => {
        const { ids, list: listData } = handleStickyMaterial(itemId, sticky, [...data]);
        const { list: listPreset } = handleStickyMaterial(itemId, sticky, [...(presetData || [])]);
        dispatchState((prev) => ({
            ...prev,
            data: listData,
            presetData: listPreset,
            options: prev.isShowPreset ? listPreset : listData
        }));
        clientQuery(ADD_MATERIAL_STICKY, { method: 'PUT', data: { ids, type: MATERIAL_STICKY_TYPES[type] } });
    };

    const _handleShowAll = () => {
        dispatchState((prev) => ({
            ...prev,
            isShowPreset: !prev.isShowPreset,
            options: prev.isShowPreset ? data : presetData
        }));
    };

    return (
        <div className="rows">
            <div className="txt">
                <IconTag />
                <span className="flex-1 txt-ellipsis pr-1" title={title}>
                    {title}
                </span>
            </div>
            <CalendarDropdown
                ref={refDropdown}
                wrapperClassName="details"
                buttonClassName="dropbtn items group-tags has-search"
                wrapperListClass="v2-dropdown__menu scrolls disable-anchor"
                customDropButton={() => (
                    <CustomDropButton
                        selected={selected}
                        options={data}
                        isShowPreset={isShowPreset}
                        onRemoveItem={_handleRemoveItem}
                    />
                )}
            >
                {haveOptions ? (
                    <ul>
                        {options.map(({ id, name: itemName, sticky, value }) => (
                            <li
                                key={id}
                                className={classNames('items justify-space-between', { active: selected.includes(id) })}
                                onClick={() => _handleSelect(id)}
                            >
                                <span className="tag-label">{isShowPreset ? value : itemName}</span>
                                <PinToTop sticky={sticky} onPin={(sticky) => _handleSticky(sticky, id)} />
                            </li>
                        ))}
                    </ul>
                ) : null}
                {Array.isArray(options) && !options.length ? (
                    <ul>
                        <li className="items pointer-events-none">{t('common:no_data_to_display', { title })}</li>
                    </ul>
                ) : null}
                {isShowPreset && !!presetData.length ? (
                    <Fragment>
                        {haveOptions ? <div className="is-divider --horizontal" /> : null}
                        <div className="d-flex justify-center p-2" onClick={_handleShowAll}>
                            <div className="v2-btn-default --transparent">{t('show_all_options')}</div>
                        </div>
                    </Fragment>
                ) : null}
            </CalendarDropdown>
        </div>
    );
};

export default forwardRef(DropdownMultiSelectWithPreset);

const CustomDropButton = ({ selected = [], options = [], onRemoveItem = () => {} }) => {
    const selectedLength = selected.length;

    const _handleRemove = (e, id) => {
        e.stopPropagation();
        onRemoveItem(id);
    };

    const _handleRemoveAll = (e) => {
        e.stopPropagation();
        onRemoveItem('', true);
    };

    return (
        <Fragment>
            <div className="box-tags">
                {!Array.isArray(selected) || !selectedLength
                    ? null
                    : selected.map((item) => {
                          const selectedItem = options.find((row) => row.id === item);
                          return selectedItem ? (
                              <div key={item} className="tag-label">
                                  <span className="tag-label__ellipsis">{selectedItem.value || selectedItem.name}</span>
                                  <div
                                      className="svg-remove black cursor-pointer"
                                      onClick={(e) => _handleRemove(e, selectedItem.id)}
                                  >
                                      <IconClose isSmall />
                                  </div>
                              </div>
                          ) : null;
                      })}
                {!!selectedLength ? (
                    <div className="close-tags cursor-pointer" onClick={_handleRemoveAll}>
                        <IconCircleClose />
                    </div>
                ) : null}
            </div>
            <div className="arrow">
                <IconArrowDown />
            </div>
        </Fragment>
    );
};
