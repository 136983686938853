import React from 'react';

import { PRESET_COLUMN_TYPES } from '../constants';
import CheckboxColPreset from './CheckboxColPreset';
import ValueColPreset from './ValueColPreset';

const ColumnPreset = ({ type = PRESET_COLUMN_TYPES.CHECKBOX, ...props }) => {
    switch (type) {
        case PRESET_COLUMN_TYPES.DOUBLE:
            return (
                <div className="preset-unit flextop h-100 flex-1">
                    <ValueColPreset {...props} data={props.values} />
                    <CheckboxColPreset {...props} />
                </div>
            );
        case PRESET_COLUMN_TYPES.VALUE:
            return <ValueColPreset {...props} />;
        case PRESET_COLUMN_TYPES.CHECKBOX:
            return <CheckboxColPreset {...props} />;
        default:
            return null;
    }
};
export default ColumnPreset;
