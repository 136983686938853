import {
    REPORT_SALES_FORECAST_BY_ITEM_GET_LIST,
    REPORT_SALES_FORECAST_BY_SERVICE_GET_LIST,
    REPORT_SALES_FORECAST_BY_SOURCE_GET_LIST,
    REPORT_SALES_FORECAST_BY_STAFF_GET_LIST,
    REPORT_SALES_FORECAST_BY_TAG_GET_LIST,
    REPORT_SALES_FORECAST_GET_LIST
} from 'app/const/Api';
import {
    URL_REPORT_SALES_FORECAST_BY_CLIENT,
    URL_REPORT_SALES_FORECAST_BY_ITEM,
    URL_REPORT_SALES_FORECAST_BY_SERVICE,
    URL_REPORT_SALES_FORECAST_BY_SOURCE,
    URL_REPORT_SALES_FORECAST_BY_STAFF,
    URL_REPORT_SALES_FORECAST_BY_TAG
} from 'app/const/api/Export';
import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { LIST_EXPORT } from 'app/const/report/Common';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getGridColumns } from 'app/const/report/SalesForecast';

export const SALE_FORECAST_KEY = {
    CLIENT: { type: 'CLIENT', keyGetColumns: 'client', title: 'customer', total: 'total_customer' },
    SERVICE: { type: 'SERVICE', keyGetColumns: 'service', title: 'service', total: 'total_service' },
    ITEM: { type: 'ITEM', keyGetColumns: 'item', title: 'item', total: 'total_item' },
    STAFF: { type: 'STAFF', keyGetColumns: 'staff', title: 'staff', total: 'total_staff' },
    SOURCE: { type: 'SOURCE', keyGetColumns: 'source', title: 'source', total: 'total_source' },
    TAG: { type: 'TAG', keyGetColumns: 'tag', title: 'tag', total: 'total_tag' }
};
const DEFAULT_COLUMNS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total'];
export const SALE_FORECAST_BY_CLIENT_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number', value: 'account_number' },
    { id: 'customer', name: 'customer', value: 'customer' },
    { id: 'jan', name: 'jan', value: 'jan' },
    { id: 'feb', name: 'feb', value: 'feb' },
    { id: 'mar', name: 'mar', value: 'mar' },
    { id: 'apr', name: 'apr', value: 'apr' },
    { id: 'may', name: 'may', value: 'may' },
    { id: 'jun', name: 'jun', value: 'jun' },
    { id: 'jul', name: 'jul', value: 'jul' },
    { id: 'aug', name: 'aug', value: 'aug' },
    { id: 'sep', name: 'sep', value: 'sep' },
    { id: 'oct', name: 'oct', value: 'oct' },
    { id: 'nov', name: 'nov', value: 'nov' },
    { id: 'dec', name: 'dec', value: 'dec' },
    { id: 'total', name: 'total', value: 'total' }
];
export const SALE_FORECAST_BY_CLIENT_COLUMNS = ['account_number', 'customer', ...DEFAULT_COLUMNS];
export const SALE_FORECAST_BY_SERVICE_COLUMNS = ['service_name', ...DEFAULT_COLUMNS];
export const SALE_FORECAST_BY_ITEM_COLUMNS = ['item_name', ...DEFAULT_COLUMNS];
export const SALE_FORECAST_BY_STAFF_COLUMNS = ['employee', ...DEFAULT_COLUMNS];
export const SALE_FORECAST_BY_SOURCE_COLUMNS = ['source_name', ...DEFAULT_COLUMNS];
export const SALE_FORECAST_BY_TAG_COLUMNS = ['tag_name', ...DEFAULT_COLUMNS];
const SORT_BY = 'sort_by';
const YEAR = 'year';
export const TAG_TYPE = 'tag_type';
export const ALL_TAGS = 'all_tags';
const SALES_FORECAST_LIST_FILTER = [YEAR, SORT_BY];
const SALES_FORECAST_BY_SERVICE_LIST_FILTER = [YEAR];
const SALES_FORECAST_BY_ITEM_LIST_FILTER = [YEAR];
const SALES_FORECAST_BY_STAFF_LIST_FILTER = [YEAR];
const SALES_FORECAST_BY_SOURCE_LIST_FILTER = [YEAR];
const SALES_FORECAST_BY_TAG_LIST_FILTER = [YEAR, TAG_TYPE];
export const SALE_FORECAST_REPORT_CONFIGS = {
    CLIENT: {
        reportType: REPORT_TYPE.SALES_FORECAST_BY_CLIENT,
        filters: SALES_FORECAST_LIST_FILTER,
        endPoint: REPORT_SALES_FORECAST_GET_LIST,
        urlExport: URL_REPORT_SALES_FORECAST_BY_CLIENT,
        pageExport: LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST_BY_CLIENT,
        keyGetTitle: SALE_FORECAST_KEY.CLIENT.title,
        keyGetTotal: SALE_FORECAST_KEY.CLIENT.total,
        keyGetGridColumns: SALE_FORECAST_KEY.CLIENT.keyGetColumns,
        getGridColumns
    },
    SERVICE: {
        reportType: REPORT_TYPE.SALES_FORECAST_BY_SERVICE,
        filters: SALES_FORECAST_BY_SERVICE_LIST_FILTER,
        endPoint: REPORT_SALES_FORECAST_BY_SERVICE_GET_LIST,
        urlExport: URL_REPORT_SALES_FORECAST_BY_SERVICE,
        pageExport: LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST_BY_SERVICE,
        keyGetTitle: SALE_FORECAST_KEY.SERVICE.title,
        keyGetTotal: SALE_FORECAST_KEY.SERVICE.total,
        keyGetGridColumns: SALE_FORECAST_KEY.SERVICE.keyGetColumns,
        getGridColumns
    },
    ITEM: {
        reportType: REPORT_TYPE.SALES_FORECAST_BY_ITEM,
        filters: SALES_FORECAST_BY_ITEM_LIST_FILTER,
        endPoint: REPORT_SALES_FORECAST_BY_ITEM_GET_LIST,
        urlExport: URL_REPORT_SALES_FORECAST_BY_ITEM,
        pageExport: LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST_BY_ITEM,
        keyGetTitle: SALE_FORECAST_KEY.ITEM.title,
        keyGetTotal: SALE_FORECAST_KEY.ITEM.total,
        keyGetGridColumns: SALE_FORECAST_KEY.ITEM.keyGetColumns,
        getGridColumns
    },
    STAFF: {
        reportType: REPORT_TYPE.SALES_FORECAST_BY_STAFF,
        filters: SALES_FORECAST_BY_STAFF_LIST_FILTER,
        endPoint: REPORT_SALES_FORECAST_BY_STAFF_GET_LIST,
        urlExport: URL_REPORT_SALES_FORECAST_BY_STAFF,
        pageExport: LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST_BY_STAFF,
        keyGetTitle: SALE_FORECAST_KEY.STAFF.title,
        keyGetTotal: SALE_FORECAST_KEY.STAFF.total,
        keyGetGridColumns: SALE_FORECAST_KEY.STAFF.keyGetColumns,
        getGridColumns
    },
    SOURCE: {
        reportType: REPORT_TYPE.SALES_FORECAST_BY_SOURCE,
        filters: SALES_FORECAST_BY_SOURCE_LIST_FILTER,
        endPoint: REPORT_SALES_FORECAST_BY_SOURCE_GET_LIST,
        urlExport: URL_REPORT_SALES_FORECAST_BY_SOURCE,
        pageExport: LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST_BY_SOURCE,
        keyGetTitle: SALE_FORECAST_KEY.SOURCE.title,
        keyGetTotal: SALE_FORECAST_KEY.SOURCE.total,
        keyGetGridColumns: SALE_FORECAST_KEY.SOURCE.keyGetColumns,
        getGridColumns
    },
    TAG: {
        reportType: REPORT_TYPE.SALES_FORECAST_BY_TAG,
        filters: SALES_FORECAST_BY_TAG_LIST_FILTER,
        endPoint: REPORT_SALES_FORECAST_BY_TAG_GET_LIST,
        urlExport: URL_REPORT_SALES_FORECAST_BY_TAG,
        pageExport: LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST_BY_TAG,
        keyGetTitle: SALE_FORECAST_KEY.TAG.title,
        keyGetTotal: SALE_FORECAST_KEY.TAG.total,
        keyGetGridColumns: SALE_FORECAST_KEY.TAG.keyGetColumns,
        getGridColumns
    }
};
const DEFAULT_HEADER_SALES_FORECAST = {
    jan: { title: 'jan', style: 'col --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    feb: { title: 'feb', style: 'col --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    mar: { title: 'mar', style: 'col --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    apr: { title: 'apr', style: 'col --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    may: { title: 'may', style: 'col --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    jun: { title: 'jun', style: 'col --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    jul: { title: 'jul', style: 'col col-sm --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    aug: { title: 'aug', style: 'col col-sm --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    sep: { title: 'sep', style: 'col col-sm --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    oct: { title: 'oct', style: 'col col-sm --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    nov: { title: 'nov', style: 'col col-sm --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    dec: { title: 'dec', style: 'col col-sm --label-r', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
    total: {
        title: 'total',
        style: 'col col-md-plus --label-r',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    }
};
const DEFAULT_COLUMNS_SALE_FORECAST = [
    { id: 'jan', title: 'jan', status: true },
    { id: 'feb', title: 'feb', status: true },
    { id: 'mar', title: 'mar', status: true },
    { id: 'apr', title: 'apr', status: true },
    { id: 'may', title: 'may', status: true },
    { id: 'jun', title: 'jun', status: true },
    { id: 'jul', title: 'jul', status: true },
    { id: 'aug', title: 'aug', status: true },
    { id: 'sep', title: 'sep', status: true },
    { id: 'oct', title: 'oct', status: true },
    { id: 'nov', title: 'nov', status: true },
    { id: 'dec', title: 'dec', status: true },
    { id: 'total', title: 'total', status: true }
];
const DEFAULT_CONTENT_SALES_FORECAST = {
    jan: { id: 'jan', style: 'col --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    feb: { id: 'feb', style: 'col --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    mar: { id: 'mar', style: 'col --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    apr: { id: 'apr', style: 'col --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    may: { id: 'may', style: 'col --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    jun: { id: 'jun', style: 'col --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    jul: { id: 'jul', style: 'col col-sm --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    aug: { id: 'aug', style: 'col col-sm --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    sep: { id: 'sep', style: 'col col-sm --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    oct: { id: 'oct', style: 'col col-sm --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    nov: { id: 'nov', style: 'col col-sm --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    dec: { id: 'dec', style: 'col col-sm --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE },
    total: { id: 'total', style: 'col col-md-plus --label-r', type: LIST_TABLE_ITEM_TYPE.BALANCE }
};

export const GRID_COLUMN_SALE_FORECAST = {
    [SALE_FORECAST_KEY.CLIENT.keyGetColumns]: {
        header: {
            account_number: {
                title: 'account_number_#',
                style: 'col col-md',
                actionType: COMMON.ORDER,
                currentValue: COMMON.ASC
            },
            customer: {
                title: 'customer',
                style: 'col col-xl',
                actionType: COMMON.ORDER,
                currentValue: COMMON.ASC
            },
            ...DEFAULT_HEADER_SALES_FORECAST
        },
        columns: [
            { id: 'account_number', title: 'account_number', status: true },
            { id: 'customer', title: 'customer', status: true },
            ...DEFAULT_COLUMNS_SALE_FORECAST
        ],
        contentConfig: {
            account_number: {
                id: 'account_number',
                style: 'col col-md',
                type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
                isAccountNumber: true
            },
            customer: { id: 'customer', style: 'col col-xl', type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO },
            ...DEFAULT_CONTENT_SALES_FORECAST
        }
    },
    [SALE_FORECAST_KEY.SERVICE.keyGetColumns]: {
        header: {
            service_name: {
                title: 'service',
                style: 'col col-xl',
                actionType: COMMON.ORDER,
                currentValue: COMMON.ASC
            },
            ...DEFAULT_HEADER_SALES_FORECAST
        },
        columns: [{ id: 'service_name', title: 'service_name', status: true }, ...DEFAULT_COLUMNS_SALE_FORECAST],
        contentConfig: {
            service_name: { id: 'service_name', style: 'col col-xl' },
            ...DEFAULT_CONTENT_SALES_FORECAST
        }
    },
    [SALE_FORECAST_KEY.ITEM.keyGetColumns]: {
        header: {
            item_name: { title: 'item', style: 'col col-xl', actionType: COMMON.ORDER, currentValue: COMMON.ASC },
            ...DEFAULT_HEADER_SALES_FORECAST
        },
        columns: [{ id: 'item_name', title: 'item', status: true }, ...DEFAULT_COLUMNS_SALE_FORECAST],
        contentConfig: {
            item_name: { id: 'item_name', style: 'col col-xl --total' },
            ...DEFAULT_CONTENT_SALES_FORECAST
        }
    },
    [SALE_FORECAST_KEY.STAFF.keyGetColumns]: {
        header: {
            employee: { title: 'staff', style: 'col col-xl', actionType: COMMON.ORDER, currentValue: COMMON.ASC },
            ...DEFAULT_HEADER_SALES_FORECAST
        },
        columns: [{ id: 'employee', title: 'employee', status: true }, ...DEFAULT_COLUMNS_SALE_FORECAST],
        contentConfig: {
            employee: {
                id: 'employee',
                style: 'col col-xl --total',
                type: LIST_TABLE_ITEM_TYPE.TEXT,
                keyGetValue: 'staff.full_name'
            },
            ...DEFAULT_CONTENT_SALES_FORECAST
        }
    },
    [SALE_FORECAST_KEY.SOURCE.keyGetColumns]: {
        header: {
            source_name: {
                title: 'source',
                style: 'col col-xl',
                actionType: COMMON.ORDER,
                currentValue: COMMON.ASC
            },
            ...DEFAULT_HEADER_SALES_FORECAST
        },
        columns: [{ id: 'source_name', title: 'source_name', status: true }, ...DEFAULT_COLUMNS_SALE_FORECAST],
        contentConfig: {
            source_name: { id: 'source_name', style: 'col col-xl --total' },
            ...DEFAULT_CONTENT_SALES_FORECAST
        }
    },
    [SALE_FORECAST_KEY.TAG.keyGetColumns]: {
        header: {
            tag_name: { title: 'tag', style: 'col col-xl', actionType: COMMON.ORDER, currentValue: COMMON.ASC },
            ...DEFAULT_HEADER_SALES_FORECAST
        },
        columns: [{ id: 'tag_name', title: 'tag_name', status: true }, ...DEFAULT_COLUMNS_SALE_FORECAST],
        contentConfig: {
            tag_name: { id: 'tag_name', style: 'col col-xl --total' },
            ...DEFAULT_CONTENT_SALES_FORECAST
        }
    }
};
