import loadable from '@loadable/component';
import React from 'react';

const ListCard = loadable(() => import('./card'));
const ListInvoices = loadable(() => import('./list'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

export default function Invoices() {
    return (
        <>
            <MainHeaderSettings isShowTutorial={false} />
            <div className="wrapper-columns">
                <div className="container-print contents-pages maintables-page">
                    <div id="wrapper_account_invoices" className="wrapper-account scrolls">
                        <ListCard />
                        <ListInvoices />
                    </div>
                </div>
            </div>
        </>
    );
}
