import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { MATERIAL_EDIT_ROWS } from '../consts';
import DropdownWithPreset from './DropdownWithPreset';
import InputWithPreset from './InputWithPreset';

const MaterialEditRow = forwardRef((props, ref) => {
    const {
        type = '',
        name = '',
        isEdit = false,
        data = [],
        presetData = null,
        presetValues = null,
        selected = { id: '', value: '' },
        onSelect = null,
        forceUpdate
    } = props;
    const configuredType = MATERIAL_EDIT_ROWS[type] || {};
    const isHaveValue = !!configuredType['keyValue'];
    const isHaveData = !!configuredType['keyValueId'];
    const refInput = useRef(null);
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        getData: () => {
            const result = {};
            // For dropdown with preset
            if (configuredType['keyValueId'])
                result[configuredType['keyValueId']] = refDropdown.current?.getData() || '';
            // For input with preset
            if (configuredType['keyValue']) result[configuredType['keyValue']] = refInput.current?.getData() || '';
            return result;
        }
    }));

    const handleSelect = (itemId) => {
        if (onSelect) onSelect(itemId);
    };

    return (
        <div className="rows --sm">
            <div className="txt">
                {configuredType['icon']}
                <span className="flex-1 txt-ellipsis pr-1" title={name}>
                    {name}
                </span>
            </div>
            <div className="details has-input field-every">
                {isHaveValue ? (
                    <InputWithPreset
                        ref={refInput}
                        type={type}
                        value={selected?.value || ''}
                        presetValues={presetValues}
                        forceUpdate={forceUpdate}
                        name={name}
                    />
                ) : null}
                {isHaveData ? (
                    <DropdownWithPreset
                        ref={refDropdown}
                        typePin={configuredType['typePin']}
                        forceUpdate={forceUpdate}
                        isEdit={isEdit}
                        data={data || []}
                        presetData={presetData}
                        selected={selected?.id || ''}
                        isHaveValue={isHaveValue}
                        name={name}
                        onSelect={handleSelect}
                    />
                ) : null}
            </div>
        </div>
    );
});
export default MaterialEditRow;
