import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';

export const DATE_RANGE = {
    SET_OPEN: 'set_open',
    CANCEL: 'cancel',
    APPLY: 'apply',
    SELECT: 'select',
    TO_DAY: 'to_day',
    YESTERDAY: 'yesterday',
    LAST_7_DAYS: 'last_7_days',
    LAST_WEEK: 'last_week',
    MONTH_TO_DATE: 'month_to_date',
    PREVIOUS_MONTH: 'previous_month',
    YEAR_TO_DATE: 'year_to_date'
};

export const LIST_OPTIONS_SELECT_DATE_DEFAULT = [
    { id: 1, name: 'to_day_date_picker', value: 'to_day' },
    { id: 1, name: 'yesterday_date_picker', value: 'yesterday' },
    { id: 1, name: 'last_7_days_date_picker', value: 'last_7_days' },
    { id: 1, name: 'last_week_date_picker', value: 'last_week' },
    { id: 1, name: 'month_to_date_date_picker', value: 'month_to_date' },
    { id: 1, name: 'previous_month_date_picker', value: 'previous_month' },
    { id: 1, name: 'year_to_date_date_picker', value: 'year_to_date' }
];

export const REPORT_JOBS_STATUS = {
    0: 'unconfirmed',
    1: 'confirmed',
    2: 'completed',
    3: 'reschedule',
    4: 'pending_confirm',
    5: 'canceled',
    6: 'recurrence',
    7: 'pending-booking'
};

export const LIST_EXPORT = {
    EXPORT_CUSTOMER: 1,
    EXPORT_REPORT: 2,
    EXPORT_REPORT_SERVICE_LOOKUP: 3,
    EXPORT_REPORT_CUSTOMER_WO_ACTIVE: 4,
    EXPORT_REPORT_LOCATION_WO_ACTIVE: 5,
    EXPORT_REPORT_ACCOUNT_AGING: 6,
    EXPORT_REPORT_PAYMENT_COLLECTED: 7,
    EXPORT_REPORT_TOTAL_SALES: 8,
    EXPORT_REPORT_SALES_TAXES: 10,
    EXPORT_REPORT_TAXES_RATE: 11,
    EXPORT_REPORT_REVENUE_CLIENT: 12,
    EXPORT_REPORT_REVENUE_SERVICE: 13,
    EXPORT_REPORT_REVENUE_ITEM: 14,
    EXPORT_REPORT_REVENUE_STAFF: 15,
    EXPORT_REPORT_SOURCE: 16,
    EXPORT_REPORT_TAGS: 17,
    EXPORT_REPORT_MATERIAL_USE: 18,
    EXPORT_REPORT_EMAIL_HISTORY: 19,
    EXPORT_REPORT_RECURRING_INVOICE: 20,
    EXPORT_REPORT_COMMISSION: 22,
    EXPORT_REPORT_LEAD: 23,
    EXPORT_REPORT_REVENUE_CLIENT_DETAIL: 24,
    EXPORT_REPORT_REVENUE_SERVICE_DETAIL: 25,
    EXPORT_REPORT_REVENUE_ITEMS: 26,
    EXPORT_REPORT_REVENUE_ITEMS_DETAIL: 27,
    EXPORT_REPORT_NEW_CUSTOMERS: 28,
    EXPORT_REPORT_ESTIMATE: 29,
    EXPORT_REPORT_DOCUMENT: 30,
    EXPORT_REPORT_CREDIT: 31,
    EXPORT_REPORT_INVOICES: 32,
    REVIEW_SERVICE: 33,
    REVIEW_LIKELY: 34,
    EXPORT_REPORT_PAYMENTS: 35,
    EXPORT_REPORT_MATERIAL_USE_DETAIL: 36,
    EXPORT_REPORT_REVENUE_STAFF_DETAIL: 37,
    EXPORT_REPORT_TAGS_DETAIL: 38,
    EXPORT_REPORT_SOURCE_DETAIL: 39,
    EXPORT_LOCATIONS_CUSTOMER: 40,
    EXPORT_REPORT_LEAD_ONLINE_BOOKING: 41,
    EXPORT_ACCOUNT_AGING_BATCH_ACTION: 42,
    EXPORT_CUSTOMER_STATEMENT_BATCH_ACTION: 43,
    EXPORT_REPORT_SUBSCRIPTION: { [PAYMENT_CARD_CUSTOMER.STRIPE]: 21, [PAYMENT_CARD_CUSTOMER.SQUARE]: 44 },
    EXPORT_ACTIVITY_COMPARISON: 45,
    EXPORT_STATUS_CHANGES: 46,
    EXPORT_WORK_LOG: 47,
    EXPORT_NEW_JOB: 48,
    EXPORT_CALL_ACTIVITY: 49,
    EXPORT_DEVICE_TRACKING: 50,
    EXPORT_SENTRICON: 51,
    EXPORT_REPORT_SALES_FORECAST_BY_CLIENT: 9,
    EXPORT_REPORT_SALES_FORECAST_BY_SERVICE: 52,
    EXPORT_REPORT_SALES_FORECAST_BY_ITEM: 53,
    EXPORT_REPORT_SALES_FORECAST_BY_STAFF: 54,
    EXPORT_REPORT_SALES_FORECAST_BY_SOURCE: 55,
    EXPORT_REPORT_SALES_FORECAST_BY_TAG: 56
};
