import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_MATERIAL_PRESET_ADD, ADDONS_MATERIAL_USAGE } from 'app/config/routes';
import { MATERIAL_PRESET } from 'app/const/api/V2';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import ListMaterial from './components/ListMaterial';
import { LoadingListPresets } from './components/LoadingPreset';

const MaterialPreset = () => {
    const { t } = useTranslation('addons');
    const materialPresetActivate = useSelector(({ auth }) => auth.user.settings.addons.material.material_preset);
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { data, isLoading } = state;

    useEffect(() => {
        const handleSuccess = ({ data }) => dispatchState({ data: data, isLoading: false });
        const handleFail = () => dispatchState({ isLoading: false });
        clientQuery(MATERIAL_PRESET, {}, handleSuccess, handleFail);
    }, []);

    const handleDelete = (item) => {
        const handleSuccess = () => {
            dispatchState((prevState) => ({
                ...prevState,
                data: prevState.data.filter((i) => i.id !== item.id)
            }));
        };
        const handleFail = () => dispatchState({ isLoading: false });
        clientQuery(MATERIAL_PRESET + '/' + item.id, { method: 'DELETE' }, handleSuccess, handleFail);
    };

    if (!materialPresetActivate) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="addons-wrap__container --material-preset">
            <div className="header flex-betweenitems">
                <Link to={addBranchPath(ADDONS_MATERIAL_USAGE)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('addons:material_usage')}
                </Link>
                <Link to={addBranchPath(ADDONS_MATERIAL_PRESET_ADD)} className="v2-btn-main has-icon svg-plus white">
                    <IconPlus />
                    {t('addons:add_new_preset')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? <LoadingListPresets /> : <ListMaterial data={data} onDelete={handleDelete} />}
                </div>
            </div>
        </div>
    );
};
export default MaterialPreset;
