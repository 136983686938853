import { URL_EXPORT_INVOICE } from 'app/const/api/Export';
import {
    QUICK_BOOK_CUSTOMER_INVOICE,
    REPORT_INVOICE_SEND_MAIL,
    REPORT_INVOICE_GET_LIST,
    REPORT_INVOICE_MARK
} from 'app/const/Api';
import { ACCESS_TOKEN, DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { getGridColumns } from 'app/const/report/Invoices';
import { INVOICE_LIST_FILTER } from 'app/const/report/ReportFilter';
import {
    getListButtonInvoice,
    MODE_VIEW_REPORT,
    REPORT_BATCH_ACTION_ITEMS,
    REPORT_LIST_BATCH_ACTIONS,
    REPORT_TYPE
} from 'app/const/report/ReportTypeContent';
import { archiveInvoicesRequest, deleteInvoicesRequest } from 'common/redux/actions/reports/invoiceAction';
import { getLocalStorage, getLocalStorageValue, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { URL_REPORT_INVOICE_PDF, URL_REPORT_INVOICE_PRINT } from 'app/const/api/Export';
import { EXPORT_INVOICE } from 'app/const/api/V2';
import { LIST_EXPORT } from 'app/const/report/Common';
import { REPORT_BATCH_LIMIT, REPORT_STATUS_STYLE, SIGNATURE_STATUS } from 'app/const/Reports';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { useHistory } from 'react-router-dom';
import { ADDONS_QUICKBOOKS_SYNC_LOG } from 'app/config/routes';
import { QB_SYNC_STATUS, QUICK_BOOK_CUS_INVOICE } from 'app/const/quickbook';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import classNames from 'classnames';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { REPORT_LIMIT, REPORT_LIMIT_LOADMORE } from 'app/const/Reports';
import { convertParamFields, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { addBranchPath, getBranchId } from 'app/const/Branch';
import { handleAbortController } from 'app/modules/customer/utils';

const GdButton = loadable(() => import('app/components/button'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const CustomerInvoiceConfirmDelete = loadable(
    () => import('app/modules/customer/detail/invoices/components/CustomerInvoiceConfirmDelete')
);
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const InvoicesPDFs = loadable(() => import('app/modules/report/invoices/components/InvoicesPDFs'));
const CalendarDropdown = loadable(() => import('app/modules/calendar/components/CalendarDropdown'));
const GDModalWarning = loadable(() => import('app/components/modal/ModalWarning'));
const ButtonSave = loadable(() => import('app/components/button/ButtonSave'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));
const SearchQBModal = loadable(() => import('app/components/quickbooks/SearchQBModal'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));
const RealtimeInvoiceList = loadable(() => import('./RealtimeInvoiceList'));
const GdConfirm = loadable(() => import('app/components/confirm'));

function ReportInvoice() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const history = useHistory();
    const refQBModal = useRef(null);
    const isActiveQuickBook = useSelector((state) => state?.auth?.user?.settings?.addons?.quickbooks || false);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refesh: false,
        dataProgressBar: false,
        checkedItems: { is_check_all: false, ids: [] },
        data: [],
        isLoading: true,
        total: 0,
        refreshScreen: 0,
        outStanding: 0,
        isLoadmore: false,
        reloadFilter: 0,
        actionSuccess: 0
    });
    const refConfirmDelete = useRef(null);
    const refConfirm = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);
    const refConfirmSendMail = useRef(null);
    const refInvoiceMarkAs = useRef(null);
    const refTypeInvoiceMarkAs = useRef(null);
    const refButtonSaveMarkAs = useRef(null);
    const refDuplicateInvoiceQB = useRef(true);
    const abortController = useRef(null);
    const refConfirmLimit = useRef(null);

    const {
        checkedItems: finalCheckedItems,
        refreshScreen,
        isLoadmore: finalIsLoadmore,
        data: finalData,
        total: finalTotal,
        isLoading: finalIsLoading,
        actionSuccess
    } = dataReport;
    const ids = finalCheckedItems.ids;
    const numberCurrentData = finalData.length;

    const keyLocalInvoices = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.INVOICE);
    const paramsReport = getLocalParamsReport(keyLocalInvoices, REPORT_TYPE.INVOICE);

    useEffect(() => {
        _reloadScreen();

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    /**
     * with case check after action delete,... then number data current < total
     */
    useEffect(() => {
        if (
            actionSuccess &&
            numberCurrentData < finalTotal &&
            numberCurrentData < REPORT_LIMIT_LOADMORE &&
            !finalIsLoading &&
            !finalIsLoadmore
        ) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    isLoadmore: true
                };
            });
        }
    }, [actionSuccess]);

    useEffect(() => {
        if (finalIsLoadmore) {
            setTimeout(() => {
                getListReport(
                    false,
                    { ...paramsReport, offset: finalData.length, reloadFilter: dataReport.reloadFilter },
                    true
                );
            }, 200);
        }
    }, [finalIsLoadmore]);

    const _handleRealTimeStatus = (responseStatus = {}) => {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === responseStatus.id.toString()) return { ...item, status: responseStatus.status };
                    return item;
                })
            };
        });
    };

    const _reloadScreen = () => {
        getListReport(true, paramsReport);
    };

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        params = getNewAPIRequest(params);
        const checkRefresh = dataReport.reloadFilter;
        const checkItemChecked = !ids.length;
        const checkShouldLoading = !notShowLoading && !finalIsLoading;

        if (checkItemChecked || checkShouldLoading) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading,
                    checkedItems: checkShouldLoading ? { is_check_all: false, ids: [] } : prev.checkedItems
                };
            });
        }

        delete params?.columns;
        delete params?.filterTab;
        delete params?.currentPage;
        delete params?.reloadFilter;

        const dataQuery = convertParamFields({
            params: { ...params, limit: REPORT_LIMIT },
            isEmptyFields: finalIsLoadmore
        });

        clientQuery(
            REPORT_INVOICE_GET_LIST,
            { data: dataQuery, method: 'GET', abortController: abortController.current },
            (res) => getListSuccess({ ...res, isReset }, checkRefresh),
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        const paramSignStatus = params.sign_status || DEFAULT_ALL;
        if (Array.isArray(paramSignStatus)) {
            const indexStatus = paramSignStatus.findIndex((status) => status === SIGNATURE_STATUS);

            if (indexStatus !== -1) {
                paramSignStatus[indexStatus] = REPORT_STATUS_STYLE.draft;
            }
        }
        params.sign_status = paramSignStatus.toString();
        params.status = params.status.toString();
        params.service = params.service.toString();
        params.invoice_tags = params.invoice_tags.toString();

        if (!params.total) {
            params.total = 1;
        }

        return params;
    };

    const getListSuccess = ({ data, total, outstanding, isReset }, tabQuery) => {
        dispatchActionReport((prev) => {
            const { checkedItems } = prev;
            const checkDiffrentTabs = !!tabQuery && prev.reloadFilter !== tabQuery;
            const newData = isReset ? data : checkDiffrentTabs ? prev.data : [...prev.data, ...data];

            return {
                ...prev,
                isLoading: false,
                isLoadmore: false,
                data: newData,
                total: total ?? prev.total ?? 0,
                outStanding: outstanding ?? prev.outStanding ?? 0,
                refreshScreen: refreshScreen + 1,
                checkedItems: {
                    ...checkedItems,
                    ids: _handleLimitCheckedItems(
                        checkedItems.is_check_all ? newData.map((e) => e.id) : checkedItems.ids
                    )
                }
            };
        });
    };

    function getListFailed({ isAborted = false }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadmore: false }));
    }

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') dispatchActionReport({ refesh: !dataReport.refesh });
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        getListReport(true, getLocalStorage(keyLocalInvoices));
    };

    const _updateReportByFilter = () => {
        const params = getLocalStorage(keyLocalInvoices);
        params.offset = 0;
        params.currentPage = 1;
        setLocalStorage(keyLocalInvoices, params);
        getListReport(true, params);
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchActionReport((prev) => ({
            ...prev,
            checkedItems: { ...stateCheckedItems, ids: _handleLimitCheckedItems(stateCheckedItems?.ids) }
        }));
    };

    const _handleUpdateData = (key) => {
        dispatchActionReport((prevState) => ({
            ...prevState,
            data: [...prevState.data].map((item) => {
                if (ids.includes(item.id)) item[key] = true;
                return item;
            })
        }));
    };

    const handleClickButton = (value) => {
        // Get current total before update data for hide items in list.
        const currentTotal = dataReport.total;
        _handleUpdateData(value !== TYPE_BUTTON_ACTIONS.DELETE ? 'manualHide' : 'withDelete');

        switch (value) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                dispatch(
                    deleteInvoicesRequest({ ids: ids }, (response) =>
                        clickButtonSuccess(response, 'Delete success', ids)
                    )
                );
                break;
            case TYPE_BUTTON_ACTIONS.ARCHIVE:
            case TYPE_BUTTON_ACTIONS.ACTIVE:
            case TYPE_BUTTON_ACTIONS.UNDELETE:
                dispatch(
                    archiveInvoicesRequest({ ids: ids, type: value }, (response) =>
                        clickButtonSuccess(response, 'success', ids, currentTotal)
                    )
                );
                break;
            default:
                break;
        }
    };

    const clickButtonSuccess = (response, msgSuccess, idsParam, currentTotal) => {
        // If `is_payments` key is true open modal confirm `Convert to credit` for `Delete Permanently`.
        if (response.is_payments) return refConfirmDelete.current._open({ data_payment: response.data_payment, ids });

        const errorResponse = response.error;
        const idsFailed = errorResponse?.items || [];
        const numberIdsFailed = idsFailed.length;

        if (!!errorResponse || !!numberIdsFailed) {
            refAlert.current.handeAddStatus({
                id: `${Date.now()}_error`,
                message: errorResponse.message,
                type: LIST_STATUS.ERROR
            });
        }

        if (!!response.message.length) {
            refAlert.current.handeAddStatus({
                id: `${Date.now()}_success`,
                message: response.message || t('report:successfully'),
                type: LIST_STATUS.SUCCESS
            });
        }

        dispatchActionReport((prev) => {
            const prevData = [...prev.data];
            const { ids: prevCheckedIds, is_check_all } = prev.checkedItems;
            const newIds = prevCheckedIds.filter((item) => idsFailed.includes(item));
            const idsAction = numberIdsFailed - idsParam.length;

            return {
                ...prev,
                checkedItems: {
                    is_check_all: newIds.length === currentTotal + idsAction && !!is_check_all,
                    ids: newIds
                },
                data: prevData
                    .map((item) => {
                        if (idsFailed.includes(item.id)) item.manualHide = false;
                        return item;
                    })
                    .filter((itemRemove) => !itemRemove.manualHide && !itemRemove.withDelete),
                total: prev.total + idsAction,
                actionSuccess: Date.now()
            };
        });
    };

    function handleOnClickExport(actionPrint, actionMail) {
        const token = getLocalStorageValue(ACCESS_TOKEN);
        const form = document.createElement('form');

        form.action = actionPrint ? URL_REPORT_INVOICE_PRINT : URL_REPORT_INVOICE_PDF;
        form.method = 'POST';
        form.style.display = 'none';
        form.className = 'export-invoice-history';

        form.innerHTML = `
            <input name="token" value="${token}" >
            <input name="ids" value="${ids}" >
            <input name="action" value="${actionMail ? 1 : 2}" >
            <input name="gd-branch-id" value="${getBranchId()}">
        `;

        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();

        const elements = document.getElementsByClassName('export-invoice-history');
        elements.length > 0 && elements[0].parentNode.removeChild(elements[0]);
        return false;
    }

    function _handleSuccessPDFs() {
        refAlert.current.handeAddStatus({
            id: LIST_STATUS.SUCCESS,
            message: t('report:generate_pdf_success'),
            type: LIST_STATUS.SUCCESS
        });
        const dataReducer = {};

        dataReducer.dataProgressBar = false;
        dataReducer.checkedItems = { is_check_all: false, ids: [] };
        dispatchActionReport(dataReducer);
    }

    function _handleUpdateInvoice({ data }) {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                data: [...prev.data].map((item) => {
                    if (item.id === data.id) {
                        return {
                            ...item,
                            invoice_number: data.number,
                            frequency: {
                                frequency: '',
                                repeat_text: data.recurrence.summary
                            },
                            billing_email: '',
                            date: data.date.format,
                            total_format: data.subtotal.format,
                            total: data.subtotal.value,
                            status: data.invoice_status_id,
                            signature_status: data.sign_status,
                            invoice_discount: {
                                value: Math.abs(data.discount.total.value),
                                format: data.discount.total.format.replace(/[()]/g, '')
                            }
                        };
                    }
                    return item;
                })
            };
        });
    }

    function _handleClickInvoice({ row }) {
        dispatch(
            actionOpenInvoice({
                id: row.id,
                status: row.status,
                total: row.total_format,
                isRecurring: false,
                onTriggerUpdated: _handleUpdateInvoice
            })
        );
    }

    function _handleQASyncIds() {
        clientQuery(
            QUICK_BOOK_CUSTOMER_INVOICE,
            { data: { ids: ids }, method: 'POST' },
            null,
            null,
            _redirectQuickbookLogs
        );
    }

    const _redirectQuickbookLogs = () => {
        history.push(addBranchPath(ADDONS_QUICKBOOKS_SYNC_LOG));
    };

    function _renderProgressBar() {
        if (!dataReport.dataProgressBar) {
            return false;
        }

        return <InvoicesPDFs handleSuccess={_handleSuccessPDFs} />;
    }

    const renderListActionButton = () => {
        if (!paramsReport) {
            return false;
        }

        let finalMode = MODE_VIEW_REPORT.ACTIVE;

        switch (paramsReport.deleted - paramsReport.archived) {
            case 1:
                finalMode = MODE_VIEW_REPORT.DELETE;
                break;
            case 0:
                finalMode = MODE_VIEW_REPORT.ACTIVE;
                break;
            default:
                finalMode = MODE_VIEW_REPORT.ARCHIVE;
                break;
        }

        return getListButtonInvoice(finalMode).map((item) => {
            return (
                <GdButton
                    key={item.id}
                    title={t(`report:${item.label}`)}
                    onClick={() => {
                        handleClickButton(item.value);
                    }}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };

    function _renderHeaderRight() {
        return (
            <Fragment>
                <Export
                    title={t('report:records', { count: dataReport.total })}
                    activePrint
                    url={URL_EXPORT_INVOICE}
                    params={paramsReport}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_INVOICES}
                    isDisable={dataReport.isLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.INVOICE}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
                <div className="header-items line-outstanding">
                    <p className="txt-ellipsis fw-600">{transformToCurrency(dataReport.outStanding, currency)}</p>
                    <p className="line-outstanding__label grey-middle-text fs-11">{t('report:outstanding')}</p>
                </div>
            </Fragment>
        );
    }

    const _handleCloseConfirm = () => refConfirm.current._close();
    const _handleCloseConfirmMail = () => refConfirmSendMail.current._close();

    const _handleBatchActions = (value, name) => {
        const actionPrint = value === REPORT_BATCH_ACTION_ITEMS.INVOICES_PRINT;

        if (!ids.length) {
            return false;
        }

        switch (value) {
            case REPORT_BATCH_ACTION_ITEMS.INVOICES_EMAIL:
                refConfirm.current._open({
                    title: t('report:send_invoice_emails'),
                    description:
                        ids.length === 1
                            ? t('report:confirm_send_report_invoice', { total: ids.length })
                            : t('report:confirm_send_report_invoices', { total: ids.length })
                });
                break;
            case REPORT_BATCH_ACTION_ITEMS.INVOICES_PRINT:
                handleOnClickExport(actionPrint);
                break;
            case REPORT_BATCH_ACTION_ITEMS.INVOICES_GENERATE_PDF:
                handleOnClickExport(actionPrint);
                break;
            case REPORT_BATCH_ACTION_ITEMS.INVOICES_GENERATE_PDFS:
                refConfirmSendMail.current._open();
                dispatchActionReport({ dataProgressBar: true });
                _handlePrintInvoice();
                break;
            case REPORT_BATCH_ACTION_ITEMS.INVOICES_SYNC_TO_QB:
                _handleQASyncIds();
                break;
            case REPORT_BATCH_ACTION_ITEMS.MARK_AS_SENT:
                _handleOnClickMarkInvoices({
                    type: REPORT_STATUS_STYLE.sent,
                    name
                });
                break;
            case REPORT_BATCH_ACTION_ITEMS.MARK_AS_DRAFT:
                _handleOnClickMarkInvoices({
                    type: REPORT_STATUS_STYLE.draft,
                    name
                });
                break;
            default:
                break;
        }
    };

    const _handleSendMailInvoice = () => {
        clientQuery(
            REPORT_INVOICE_SEND_MAIL,
            {
                data: { ids },
                method: 'POST'
            },
            _handleSendInvoiceSuccess,
            _handleSendInvoiceFail,
            _handleFinally
        );
    };

    const _handleSendInvoiceSuccess = ({ message }) => {
        refAlert.current.handeAddStatus({
            id: LIST_STATUS.SUCCESS,
            message: message || t('report:send_success'),
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleSendInvoiceFail = ({ message, typeError }) => {
        const isTimeOut = typeError === TypeError.name;
        const newMsg = isTimeOut ? t('report:send_email_background') : message || t('report:send_fail');

        refAlert.current.handeAddStatus({
            id: LIST_STATUS.ERROR,
            message: newMsg,
            type: isTimeOut ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
        _handleCloseConfirm();
    };

    const _handleFinally = () => {
        _handleCloseConfirm();
        dispatchActionReport({ checkedItems: { is_check_all: false, ids: [] } });
    };

    const _handlePrintInvoice = () => {
        clientQuery(EXPORT_INVOICE, { data: { ids: ids, from: 1 }, method: 'POST', toFormData: false });
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType: REPORT_TYPE.INVOICE,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _handleSearchQuickBooks = (item) => {
        refQBModal.current._open(null, null, item?.id, item?.invoice_number);
    };

    const _handleLoadmore = () => {
        const numberData = finalData.length;
        if (!!numberData && numberData < finalTotal && !finalIsLoadmore) {
            dispatchActionReport((prev) => ({ ...prev, isLoadmore: true, reloadFilter: Date.now() }));
        }
    };

    const _renderListBatch = () => {
        let reportListBatchOptions = REPORT_LIST_BATCH_ACTIONS.INVOICE;
        if (!isActiveQuickBook) {
            reportListBatchOptions = reportListBatchOptions.filter(
                (action) => action.id !== REPORT_BATCH_ACTION_ITEMS.INVOICES_SYNC_TO_QB
            );
        }
        return (
            <ul>
                {reportListBatchOptions.map(({ id, name }) => (
                    <li key={id} className="items " onClick={() => _handleBatchActions(id, t(`report:${name}`))}>
                        <div className="txt-ellipsis">{t(`report:${name}`)}</div>
                    </li>
                ))}
            </ul>
        );
    };

    function _renderHeaderBootom() {
        const idsSelected = ids?.length || 0;

        return (
            <Fragment>
                {_renderProgressBar()}
                <div className={classNames('header --filter', { 'is-disable': !idsSelected })}>
                    <CheckBoxHeader isShowTotal total={finalTotal} checkedItems={finalCheckedItems} />

                    {renderListActionButton()}

                    <CalendarDropdown
                        id="billing_to_location"
                        keyGetValue="id"
                        keyGetName="name"
                        keyGetKey="id"
                        wrapperListClass="v2-dropdown__menu content-full scrolls"
                        wrapperClassName="header-items"
                        customDropButton={() => (
                            <Fragment>
                                <span className="txt-ellipsis">{t('batch_actions')}</span>
                                <IconArrowDown />
                            </Fragment>
                        )}
                    >
                        {_renderListBatch()}
                    </CalendarDropdown>
                </div>
            </Fragment>
        );
    }

    const _handleOnClickMarkInvoices = ({ type, name }) => {
        refTypeInvoiceMarkAs.current = { type };
        refInvoiceMarkAs.current._open({
            title: name,
            description: t('report:confirm_mark_as_invoices', {
                type: t(`report:${type}`).toLowerCase(),
                total: ids.length
            })
        });
    };

    const _handleCloseConfirmMarkInvoices = () => refInvoiceMarkAs.current._close();

    const _showStatusBar = ({ message, success = false }) => {
        refAlert.current?.showStatusBar('mark_invoices', message, !!success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR);
    };

    const _handleMarkInvoices = () => {
        const _handleSuccess = ({ message, success }) => {
            _showStatusBar({ message: message || t('report:update_successfully'), success });
            refTypeInvoiceMarkAs.current = null;
            _handleCloseConfirmMarkInvoices();
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    checkedItems: { is_check_all: false, ids: [] }
                };
            });
        };

        const _handleFailed = ({ message, success }) => {
            _showStatusBar({ message: message || t('report:action_failure'), success });
        };

        clientQuery(
            REPORT_INVOICE_MARK,
            { data: { ids, type: refTypeInvoiceMarkAs.current?.type }, toFormData: false, method: 'POST' },
            _handleSuccess,
            _handleFailed
        );
    };

    const _handleDeleteInvoiceSuccessQB = (itemChecked, isErrorDuplicate) => {
        refDuplicateInvoiceQB.current = isErrorDuplicate;
        dispatchActionReport((prevState) => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.number === itemChecked.number) item.quickbooks.status = QB_SYNC_STATUS.FAIL;
                return item;
            })
        }));
    };

    const _handleLimitCheckedItems = (ids = []) => {
        if (ids.length <= REPORT_BATCH_LIMIT) return ids;

        refConfirmLimit.current?.open();
        return ids.slice(0, REPORT_BATCH_LIMIT);
    };

    return (
        <Fragment>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.INVOICE}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <GDStatusBar ref={refAlert} />
                    <HeaderBottom
                        typeReport={REPORT_TYPE.INVOICE}
                        filters={INVOICE_LIST_FILTER}
                        isLoading={finalIsLoading}
                        handleChangeFilter={handleChangeFilter}
                        handleUpdate={_handleUpdate}
                        updateReportByFilter={_updateReportByFilter}
                    />

                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBootom()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="has-checkbox scrolls-x table-multi-column has-text-ellipsis"
                            classTableContent=""
                            content={finalData.filter((item) => !item.manualHide)}
                            showCheckBox
                            fileTranslation={'report'}
                            handleClick={(value) => {
                                _handleClickInvoice(value);
                            }}
                            handleClickHeader={_handleActionHeader}
                            checkedItems={dataReport.checkedItems}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            {...getGridColumns(paramsReport?.columns, paramsReport?.order)}
                            quickbookActive={isActiveQuickBook && !paramsReport?.deleted}
                            typeQuickBook={QUICK_BOOK_CUS_INVOICE}
                            isDuplicateInvoiceQB={refDuplicateInvoiceQB.current}
                            styleQBSync={'col col-xs'}
                            onSearchQB={_handleSearchQuickBooks}
                            isScroll
                            isLoadmore={finalIsLoadmore}
                            isShowToolTip
                            onScrollToEnd={_handleLoadmore}
                        />
                    </div>

                    <CustomerInvoiceConfirmDelete ref={refConfirmDelete} onDeleteSuccess={clickButtonSuccess} />

                    <GDModalWarning
                        ref={refConfirm}
                        footer={
                            <div className="footer-modal btn-close justify-end">
                                <div className="v2-btn-default --transparent" onClick={_handleCloseConfirm}>
                                    {t('report:cancel_date_picker')}
                                </div>
                                <ButtonSave
                                    title={t('report:send')}
                                    ref={refButtonSave}
                                    onSave={_handleSendMailInvoice}
                                />
                            </div>
                        }
                    />

                    <GDModalWarning
                        ref={refConfirmSendMail}
                        title={t('report:export')}
                        description={t('report:confirm_send_mail_invoice')}
                        footer={
                            <div className="footer-modal btn-close justify-end" onClick={_handleCloseConfirmMail}>
                                <div className="v2-btn-main">{t('common:confirm')}</div>
                            </div>
                        }
                    />

                    <GDModalWarning
                        ref={refInvoiceMarkAs}
                        footer={
                            <div className="footer-modal btn-close justify-end">
                                <div className="v2-btn-default --transparent" onClick={_handleCloseConfirmMarkInvoices}>
                                    {t('report:cancel')}
                                </div>
                                <ButtonSave
                                    title={t('common:confirm')}
                                    ref={refButtonSaveMarkAs}
                                    onSave={_handleMarkInvoices}
                                />
                            </div>
                        }
                    />
                    <GdConfirm
                        ref={refConfirmLimit}
                        title={t('reminder')}
                        message={t('confirm_limit')}
                        listButton={{ confirm: true, cancel: true }}
                        titleConfirm={t('common:confirm')}
                    />
                    {isActiveQuickBook && (
                        <SearchQBModal ref={refQBModal} onDeleteSuccess={_handleDeleteInvoiceSuccessQB} />
                    )}
                    <RealtimeInvoiceList onUpdate={_handleRealTimeStatus} />
                </div>
            </div>
        </Fragment>
    );
}

export default ReportInvoice;
