import React from 'react';

const LoadingPreset = () => {
    return (
        <div className="boxs-wrapper__preset h-100 wrap-loading">
            <div className="preset-header flex-column gap-4">
                <div className="loading --animation --line --onefifth" />
                <div className="v2-btn-default loading w-100 --grey">
                    <div className="loading --animation --line --twothird" />
                </div>
            </div>
            <div className="preset-container flextop">
                <div className="preset-unit flextop h-100 flex-1">
                    <div className="preset-column flex-column h-100 flex-1">
                        <div className="preset-column__header flex-betweenitems gap-4">
                            <div className="flex-1">
                                <div className="loading --animation --line --half" />
                            </div>
                            <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                        </div>
                        <div className="preset-column__content flex-1 flex-column gap-8 mt-1">
                            <div className="flex-betweenitems gap-4">
                                <div className="loading --animation --line --half" />
                                <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                            </div>
                            <div className="flex-betweenitems gap-4">
                                <div className="loading --animation --line --half" />
                                <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                            </div>
                            <div className="flex-betweenitems gap-4">
                                <div className="loading --animation --line --half" />
                                <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                            </div>
                        </div>
                    </div>
                    <div className="preset-column flex-column h-100 flex-1">
                        <div className="preset-column__header">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="preset-column__content flex-1 flex-column gap-8 mt-1">
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                        </div>
                    </div>
                </div>
                <div className="preset-column flex-column h-100 flex-1">
                    <div className="preset-column__header">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="preset-column__content flex-1 flex-column gap-8 mt-1">
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                    </div>
                </div>
                <div className="preset-column flex-column h-100 flex-1">
                    <div className="preset-column__header flex-betweenitems gap-4">
                        <div className="flex-1">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                    </div>
                    <div className="preset-column__content flex-1 flex-column gap-8 mt-1">
                        <div className="flex-betweenitems gap-4">
                            <div className="loading --animation --line --half" />
                            <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                        </div>
                        <div className="flex-betweenitems gap-4">
                            <div className="loading --animation --line --half" />
                            <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                        </div>
                        <div className="flex-betweenitems gap-4">
                            <div className="loading --animation --line --half" />
                            <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
                        </div>
                    </div>
                </div>
                <div className="preset-column flex-column h-100 flex-1">
                    <div className="preset-column__header">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="preset-column__content flex-1 flex-column gap-8 mt-1">
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                    </div>
                </div>
            </div>
            <div className="preset-footer flexcenter justify-end gap-8">
                <div className="v2-btn-default loading btn-x-sm">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="v2-btn-default loading btn-x-sm --grey">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </div>
    );
};
export const LoadingListPresets = () => (
    <div className="preset-list wrap-loading">
        <div className="preset-items flex-column gap-8">
            <div className="flexcenter gap-10">
                <div className="flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
            </div>
            <div className="flexcenter flex-wrap row-gap-4">
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
            </div>
            <div className="flexcenter flex-wrap row-gap-4">
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
            </div>
        </div>
        <div className="preset-items flex-column gap-8">
            <div className="flexcenter gap-10">
                <div className="flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
            </div>
            <div className="flexcenter flex-wrap row-gap-4">
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
            </div>
            <div className="flexcenter flex-wrap row-gap-4">
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
            </div>
        </div>
        <div className="preset-items flex-column gap-8">
            <div className="flexcenter gap-10">
                <div className="flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="v2-btn-default --transparent loading --animation --icon-lg bg-white-cultured" />
            </div>
            <div className="flexcenter flex-wrap row-gap-4">
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
            </div>
            <div className="flexcenter flex-wrap row-gap-4">
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
                <div className="tag-label --large bg-white-cultured loading --animation --onefifth" />
            </div>
        </div>
    </div>
);

export default LoadingPreset;
