import loadable from '@loadable/component';
import { React, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SETTINGS_SERVICE_ADD, SETTINGS_SERVICE_EDIT } from 'app/config/routes';
import { GET_LIST_SERVICE_SETTING, SETTING_SERVICE_DELETE, SETTING_SERVICE_TRASH } from 'app/const/Api';
import { COMMON, LIST_STATUS, TYPE_DELETE_SERVICES_SETTING } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { SERVICE } from 'app/const/setting/SettingPageName';
import { getTabParams } from 'app/const/setting/SettingParams';
import {
    getGridColumnsSettingService,
    LIST_BUTTON_MESSAGES,
    LIST_BUTTONS,
    TAB
} from 'app/const/setting/SettingServiceTable';
import { LIST_TOOLTIP } from 'app/const/Settings';
import { customersService } from 'app/modules/settings/utils';
import { getLocalStorageSettingPage, updateLocalStorageSettingPage } from 'app/modules/settings/utils/localStorage';
import { resetServiceList } from 'common/redux/actions/settings/serviceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const SettingPagination = loadable(() => import('app/modules/settings/components/SettingPagination'));
const SettingTabsFilter = loadable(() => import('app/modules/settings/components/SettingTabsFilter'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const BoxTipsSetting = loadable(() => import('app/modules/settings/components/BoxTipsSetting'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const AlertCustomer = loadable(() => import('app/modules/customer/components/AlertCustomer'));
const ActionHeader = loadable(() => import('./components/ActionHeader'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

const SettingsService = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['setting']);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        checkedItems: {
            is_check_all: false,
            ids: []
        },
        listTab: TAB.LIST,
        currentTab: getLocalStorageSettingPage({
            namePage: SERVICE,
            key: COMMON.CURRENT_TAB,
            defaultCurrentTab: TAB.DEFAULT_SELECTED.ACTIVE
        }),
        total: 0,
        tutorial: {}
    });

    const totalSelected = state.checkedItems.ids.length || 0;
    const { data: services, currentTab, tutorial, isLoading } = state;

    const refConfirm = useRef(null);
    const refAlert = useRef(true);
    const history = useHistory();
    const paramsSetting = getLocalStorageSettingPage({ namePage: SERVICE, key: COMMON.PARAMS });
    const isCheckAll = services.length === totalSelected;

    useEffect(() => {
        _getListServiceSetting(paramsSetting);
    }, []);

    const _handleChangeTab = (tabId) => {
        const newTabParams = getTabParams(tabId);
        const newParams = { ...paramsSetting, ...newTabParams, offset: 0 };
        _getListServiceSetting(newParams, tabId);
    };

    const _getListServiceSetting = (params, tabId = currentTab) => {
        updateLocalStorageSettingPage({
            namePage: SERVICE,
            value: { params: params, current_tab: tabId }
        });

        dispatchState({ isLoading: true, currentTab: tabId });

        const _success = ({ data, total, tutorial }) => {
            dispatchState({
                data: customersService(data),
                isLoading: false,
                currentTab: getLocalStorageSettingPage({ namePage: SERVICE, key: COMMON.CURRENT_TAB }),
                checkedItems: { is_check_all: false, ids: [] },
                total,
                tutorial
            });
        };

        const _failed = (err) => {
            dispatchState({ data: [], isLoading: false });
            checkAccessFail(err, ACCESS_SETTINGS_TAB);
        };
        clientQuery(GET_LIST_SERVICE_SETTING, { data: params, method: 'GET' }, _success, _failed);
    };

    const onChangeStateCheckedItems = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _handleChangePage = (newParams) => {
        _getListServiceSetting({ ...newParams, offset: Math.max(newParams.offset, 0) });
    };

    const handleClickButtonAction = (value) => {
        const listIdChecked = state.checkedItems.ids;
        if (listIdChecked.length) {
            refConfirm.current.open({ ids: listIdChecked, type: value }, t(`common:${LIST_BUTTON_MESSAGES[value]}`));
        }
    };

    const _updateStatusServices = (params) => {
        const servicesUpdated = params.ids;

        const newServices = state.data.filter((service) => {
            if (servicesUpdated.includes(service.id)) return false;
            return true;
        });

        if (params.type === TYPE_DELETE_SERVICES_SETTING) {
            clientQuery(
                SETTING_SERVICE_DELETE,
                { data: params, method: 'DELETE' },
                _updateStatusSuccess,
                _updateStatusFailure
            );
        } else {
            clientQuery(
                SETTING_SERVICE_TRASH,
                { data: params, method: 'PUT' },
                _updateStatusSuccess,
                _updateStatusFailure
            );
        }

        dispatchState({
            data: newServices,
            checkedItems: {
                is_check_all: false,
                ids: []
            }
        });
    };

    const _updateStatusSuccess = (response) => {
        if (response.error) {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: response.error.message,
                type: LIST_STATUS.ERROR
            });

            dispatchState({ data: services });
        } else {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.SUCCESS,
                message: response.message,
                type: LIST_STATUS.SUCCESS
            });
            const { offset: finalOffset, limit: finalLimit } = paramsSetting;

            if (isCheckAll && finalOffset + finalLimit >= state.total) {
                _handleChangePage({
                    ...paramsSetting,
                    offset: finalOffset - finalLimit,
                    limit: finalLimit
                });
            } else {
                _getListServiceSetting(paramsSetting);
            }
            dispatch(resetServiceList());
        }
    };

    const _updateStatusFailure = () => {
        dispatchState({ isLoading: false });
    };

    function _handleAddService() {
        history.push(addBranchPath(SETTINGS_SERVICE_ADD));
    }

    function _handleClickItem(item) {
        history.push(addBranchPath(SETTINGS_SERVICE_EDIT.replace(':id', item.id)));
    }

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType: SERVICE,
            columnsTarget,
            currentValue,
            paramsReport: getLocalStorageSettingPage({ namePage: SERVICE, key: COMMON.PARAMS }),
            callBack: _getListServiceSetting,
            quickResponse: true
        });
    };

    function _renderHeaderRight() {
        return (
            <div onClick={_handleAddService} className="v2-btn-main has-icon svg-white header-items">
                <IconPlus />
                {t('setting:add_services')}
            </div>
        );
    }
    function _renderHeaderLeft() {
        return <SettingTabsFilter list={state.listTab} onChange={_handleChangeTab} tabActive={currentTab} />;
    }

    return (
        <>
            <MainHeaderSettings
                contentRight={_renderHeaderRight}
                contentLeft={_renderHeaderLeft}
                tutorial={tutorial}
                isLoading={isLoading}
            />
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages maintables-page">
                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:confirm')}
                        message={t('setting:are_you_sure_delete_this_item')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_updateStatusServices}
                    />
                    <AlertCustomer ref={refAlert} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.SERVICE_TEMPLATES} onAddNew={_handleAddService} />
                    <ActionHeader
                        totalSelected={totalSelected}
                        listTabAction={LIST_BUTTONS[state.currentTab]}
                        handleClickButtonAction={handleClickButtonAction}
                        checkedItems={state.checkedItems}
                    />
                    <div className="tab-contents box-auto has-footer">
                        <div className="tab-conts tab-content-active">
                            <GdGridView
                                isLoading={state.isLoading}
                                isHasDropdown
                                content={services}
                                onClickItem={_handleClickItem}
                                classTable="scrolls-x has-checkbox --service"
                                fileTranslation={'setting'}
                                checkedItems={state.checkedItems}
                                onChangeStateCheckedItems={onChangeStateCheckedItems}
                                showCheckBox
                                {...getGridColumnsSettingService(paramsSetting?.order, currentTab)}
                                isScroll
                                handleClickHeader={_handleActionHeader}
                            />
                        </div>
                    </div>
                    <SettingPagination totalItem={state.total} namePage={SERVICE} onSelect={_handleChangePage} />
                </div>
            </div>
        </>
    );
};

export default SettingsService;
