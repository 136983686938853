import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CustomValueModal from 'app/components/dropdown/CustomValueModal';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconMinus from 'assets/icon/IconMinus';
import IconPlus from 'assets/icon/IconPlus';
import { MATERIAL_EDIT_FIELD_NAME } from '../consts';
import { calculateNumericValue } from '../utils';

const initializer = ({ value, presetValues }) => {
    const initialState = { presetValues, isShowPreset: !!presetValues?.length };
    initialState['options'] = !!presetValues?.length ? presetValues : [];
    initialState['selected'] = value || presetValues?.[0] || '';
    initialState['defaultValue'] = value;
    return initialState;
};
const InputWithPreset = forwardRef((props, ref) => {
    const { value, type = '', presetValues = null, forceUpdate = 0, name = '' } = props;
    const [state, dispatchState] = useReducer(reducer, { value, presetValues }, initializer);
    const { isShowPreset, defaultValue, selected } = state;
    const isDilution = type === MATERIAL_EDIT_FIELD_NAME.DILUTION;
    const refDataChange = useRef('');

    useImperativeHandle(ref, () => ({ getData: () => selected }));
    useEffect(() => {
        if (forceUpdate)
            dispatchState((prevState) => ({
                ...prevState,
                ...initializer({ value: refDataChange.current || value, presetValues })
            }));
    }, [forceUpdate]);

    const handleChange = (value) => {
        refDataChange.current = value;
        dispatchState((prevState) => ({ ...prevState, selected: value }));
    };

    if (!isShowPreset && isDilution) {
        return (
            <input
                type="text"
                className="field-input w-100"
                defaultValue={defaultValue}
                onChange={(e) => handleChange(e.target.value)}
            />
        );
    }
    if (!isShowPreset) return <InputValue defaultValue={defaultValue} onChange={handleChange} />;
    return (
        <DropdownValues
            isDilution={isDilution}
            data={presetValues || []}
            value={selected}
            name={name}
            onChange={handleChange}
        />
    );
});

const DropdownValues = ({ data, isDilution = false, value = '', name = '', onChange = () => {} }) => {
    const { t } = useTranslation(['common', 'jobDetail']);
    const refDropdown = useRef(null);
    const refCustomValue = useRef(null);

    const handleCustom = () => {
        refDropdown.current._closeDropdown();
        refCustomValue.current.open({});
    };

    return (
        <>
            <CalendarDropdown
                ref={refDropdown}
                wrapperClassName="v2-dropdown flex-1 ml-0"
                wrapperListClass="v2-dropdown__menu content-checked"
                selected={value}
            >
                <div className="scroll">
                    {data.map((item, idx) => (
                        <div className="items" key={item + idx} onClick={() => onChange(item)}>
                            <span className="word-break flex-1">{item}</span>
                        </div>
                    ))}
                    <div className="is-divider --horizontal" />
                    <div className="content-checked__all border-none" onClick={handleCustom}>
                        <div className="items btn-modal">{t('custom')}</div>
                    </div>
                </div>
            </CalendarDropdown>
            <CustomValueModal
                ref={refCustomValue}
                title={t('custom_value')}
                maxLength={8}
                isIntegerNumber={false}
                isCustomText={isDilution}
                placeholder={name}
                onSave={onChange}
            />
        </>
    );
};

const InputValue = ({ defaultValue = 0, onChange = () => {} }) => {
    const refInput = useRef(null);

    const handleClickChange = (e, isIncrease = false) => {
        e.preventDefault();
        const value = refInput.current.value;
        const newValue = calculateNumericValue(isIncrease ? parseFloat(value) + 1 : parseFloat(value) - 1);
        refInput.current.value = newValue < 0 ? 0 : newValue;
        onChange(refInput.current.value);
        refInput.current.focus();
    };

    const _onKeyDownCost = (event) => {
        const finalKeyCode = event.keyCode;
        const finalKey = event.key;
        const value = event.target.value;

        const valueKeyCode =
            event.ctrlKey ||
            event.altKey ||
            (47 < finalKeyCode && finalKeyCode < 58 && !event.shiftKey) ||
            (95 < finalKeyCode && finalKeyCode < 106) ||
            finalKeyCode === 8 ||
            finalKeyCode === 9 ||
            (finalKeyCode > 34 && finalKeyCode < 40) ||
            finalKeyCode === 46;

        const finalValue = valueKeyCode || (finalKey === '.' && !value.concat('.').includes('..'));
        !finalValue && event.preventDefault();
    };

    return (
        <div className="field-input field-number">
            <span onClick={(e) => handleClickChange(e, false)} className="v2-btn-default just-icon" tabIndex="0">
                <IconMinus />
            </span>
            <input
                ref={refInput}
                type="text"
                onKeyDown={_onKeyDownCost}
                onChange={(e) => onChange(calculateNumericValue(e.target.value))}
                defaultValue={defaultValue || 0}
            />
            <span onClick={(e) => handleClickChange(e, true)} className="v2-btn-default just-icon" tabIndex="0">
                <IconPlus />
            </span>
        </div>
    );
};
export default InputWithPreset;
