import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import { ADDONS_MATERIAL_PRESET } from 'app/config/routes';
import { LIST_ITEM_MATERIAL } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { MATERIAL_PRESET } from 'app/const/api/V2';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import LoadingPreset from './components/LoadingPreset';
import TablePreset from './components/TablePreset';
import { orderColPresetByRules, convertDataPreset } from './utils';

const EditMaterialPreset = () => {
    const { t } = useTranslation('addons');
    const history = useHistory();
    const { id } = useParams();
    const materialPresetActivate = useSelector(({ auth }) => auth.user.settings.addons.material.material_preset);
    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        materials: [],
        isLoading: true,
        isLoadingMaterials: true,
        rules: {}
    });
    const { data, materials, isLoading, isLoadingMaterials, rules } = state;

    useEffect(() => {
        dispatchState({ isLoading: true, isLoadingMaterials: true });
        handleGetMaterial();
        handleGetPreset();
    }, [id]);

    const handleGetMaterial = () => {
        const handleSuccess = ({ data, preset = [], rules = {} }) => {
            dispatchState((prevState) => {
                const presetData = new Map(preset.map((i) => [i.material_id, i]));
                const materials = [];
                data['materials'].forEach((i) => {
                    const material = presetData.get(i.id) || null;
                    if (material) materials.push({ ...i, isDefaultSelect: material.id === id, preset_id: material.id });
                });
                return { ...prevState, isLoadingMaterials: false, materials, rules };
            });
        };
        const handleFail = () => dispatchState({ isLoadingMaterials: false });
        clientQuery(LIST_ITEM_MATERIAL, { data: { inc: 'materials,preset', limit: 0 } }, handleSuccess, handleFail);
    };

    const handleGetPreset = () => {
        const handleSuccess = ({ data }) => {
            dispatchState((prevState) => {
                const filteredData = {};
                Object.keys({ ...data }).forEach((key) => {
                    const keyFilter = key.replace(/s$/, '');
                    if (keyFilter && !!data[key]['active']) filteredData[keyFilter] = data[key];
                });
                return { ...prevState, data: filteredData, isLoading: false };
            });
        };
        const handleFail = () => dispatchState({ isLoading: false });
        clientQuery(MATERIAL_PRESET + '/' + id, {}, handleSuccess, handleFail);
    };

    const handleUpdate = (values, cbAlert = () => {}) => {
        const dataSubmit = { data: convertDataPreset(values), method: 'PUT', toFormData: false };
        const handleSuccess = () => history.push(addBranchPath(ADDONS_MATERIAL_PRESET));
        const handleFail = ({ message }) => cbAlert(LIST_STATUS.ERROR, message, LIST_STATUS.ERROR);
        clientQuery(MATERIAL_PRESET + '/' + id, dataSubmit, handleSuccess, handleFail);
    };

    if (!materialPresetActivate) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="addons-wrap__container addons-wrap__preset">
            <div className="header flex-betweenitems">
                <Link to={addBranchPath(ADDONS_MATERIAL_PRESET)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('addons:manage_material_preset')}
                </Link>
            </div>
            {isLoading || isLoadingMaterials ? (
                <LoadingPreset />
            ) : (
                <TablePreset data={orderColPresetByRules(data, rules)} materials={materials} onSubmit={handleUpdate} />
            )}
        </div>
    );
};
export default EditMaterialPreset;
