import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import GDDropdownWithSearch from 'app/components/dropdown/DropdownWithSearch';
import { reducer } from 'app/const/Reducer';
import ServiceSmartDialer from 'app/services/voip/ServiceSmartDialer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { updateIndexDialer } from 'common/redux/actions/voipAction';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { SMART_VIEW_CUSTOM_ACTIONS } from '../../constants';
import { COLUMNS_OPTIONS } from '../../constants/columns';
import { OBJECT_TYPES } from '../../constants/types';
import { useSmartView } from '../../context';
import { UPDATE_SMART_VIEW_MUTATION } from '../../graphql/mutation/filters';
import { getColumnsSmartView } from '../table/utils';
import { filterSortOptions } from '../utils';
import { convertColumnToQuery } from '../utils/columns';
import { sortToQuery } from '../utils/sortQuery';

const FilterColumns = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { columns, permission, handleApplyColumns, sort } = useSmartView();

    const { t } = useTranslation('smartView');
    const settings = useSelector(({ auth }) => auth.user.settings);
    const customFields = useSelector(({ customFields }) => customFields.data);
    const [state, dispatchState] = useReducer(reducer, {}, () => ({ columns: columns || [] }));
    const { columns: columnsLocalState } = state;

    const refDialer = useRef(null);
    const refDataOptions = useRef(COLUMNS_OPTIONS);
    const refDropdown = useRef(null);
    const isActivateVoip = checkIsEnableVoip(settings, false);

    const [updateSmartView] = useMutation(UPDATE_SMART_VIEW_MUTATION, {
        onCompleted: (data) => {
            refDropdown.current?.close();
            const listSmartView = document.getElementById('list-wrapper-smart-view');
            if (listSmartView) {
                const event = new CustomEvent(SMART_VIEW_CUSTOM_ACTIONS.UPDATE_SMART_VIEW_QUERY, {
                    detail: { data: { ...(data || {}), isSaveColumn: true } }
                });
                listSmartView.dispatchEvent(event);
            }
            refDialer.current.onResetDialer(data.updateSmartView);
        }
    });

    useEffect(() => {
        const dataColumns = getColumnsSmartView(columns);
        dispatchState((prevState) => ({ ...prevState, columns: dataColumns }));
        refDataOptions.current = dataColumns;
    }, [columns]);

    const _handleSearch = (value) => {
        dispatchState((prevState) => ({ ...prevState, columns: filterSortOptions(refDataOptions.current, value) }));
    };

    const _handleHideDropdown = () => {
        dispatchState((prevState) => ({ ...prevState, columns: refDataOptions.current }));
    };

    const _handleUpdateSelect = (data, itemUpdate) => {
        const newColumns = data.map((item) => {
            if (item.objectType === itemUpdate.objectType) {
                return {
                    ...item,
                    options: item.options.map((option) => {
                        if (option.value === itemUpdate.fieldId) return { ...option, checked: !option?.checked };
                        return option;
                    })
                };
            }
            return item;
        });

        return newColumns;
    };

    const _handleSelect = (data) => {
        dispatchState((prevState) => {
            const newColumns = _handleUpdateSelect([...prevState.columns], data);
            refDataOptions.current = _handleUpdateSelect([...refDataOptions.current], data);
            return { ...prevState, columns: newColumns };
        });
    };

    const _handleApply = () => {
        const checkedValues = [];
        refDataOptions.current.forEach((item) => {
            item.options.forEach((option) => {
                if (option.checked === true) checkedValues.push({ field_id: option.value, type_id: option.objectType });
            });
        });
        handleApplyColumns(checkedValues);
        if (permission.modify) {
            const data = { sort: sortToQuery(sort), columns: convertColumnToQuery(checkedValues, customFields) };
            updateSmartView({ variables: { id, data } });
            dispatch(updateIndexDialer({ id, index: 0 }));
        }
        refDropdown.current?.close();
    };

    const finalColumns = () => {
        const result = columnsLocalState.filter((item) => {
            if (item.objectType === OBJECT_TYPES.CALL && !isActivateVoip) return false;
            if (item.objectType === OBJECT_TYPES.SMS_MESSAGES && !settings?.addons?.smsplivo) return false;
            return true;
        });
        return result;
    };

    return (
        <>
            <GDDropdownWithSearch
                id="smart_view_columns"
                isCheckbox
                ref={refDropdown}
                placeholder={t('search_placeholder')}
                customDropButton={() => (
                    <>
                        <p className="txt-ellipsis">{t('columns')}</p>
                        <span className="arrow">
                            <IconArrowDown />
                        </span>
                    </>
                )}
                buttonClassName="dropbtn v2-btn-default --sm --icon-r fs-13"
                options={finalColumns()}
                onSearch={_handleSearch}
                onHide={_handleHideDropdown}
                onSelect={_handleSelect}
                onApply={_handleApply}
            />
            <ServiceSmartDialer ref={refDialer} />
        </>
    );
};

FilterColumns.displayName = 'FilterColumnsSmartView';
export default FilterColumns;
