import { MATERIAL_EDIT_FIELD_NAME } from 'app/modules/jobdetail/tabs/materials/consts';

export const convertDataPreset = (values) => {
    const result = { material_id: values.material_id };
    Object.keys(values).forEach((key) => {
        if (key !== 'material_id') {
            const value = values[key];
            if (key === 'unit' || key === 'area') {
                result[key + 's'] = value['data'] || [];
                result[key + '_values'] = value['values'] || [];
            } else if (key === MATERIAL_EDIT_FIELD_NAME.DILUTION) {
                result[key + 's'] = value['values'] || [];
            } else result[key + 's'] = value;
        }
    });
    return result;
};
export const orderColPresetByRules = (data = {}, rules = {}) => {
    return Object.keys(rules)
        .filter((key) => data[key])
        .reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {});
};
