import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';

const WarningSending = forwardRef((props, ref) => {
    const { t } = useTranslation('smartView');
    const [state, dispatchState] = useReducer(reducer, { isDisplay: false, message: 'sending_email' });
    const { isDisplay, message } = state;

    useImperativeHandle(ref, () => ({ display: _handleOpen, hide: _handleClose }));

    useEffect(() => {
        const timer = setTimeout(() => {
            _handleClose();
        }, 3000);
        return () => clearTimeout(timer);
    }, [isDisplay]);

    const _handleClose = () => {
        dispatchState({ isDisplay: false });
    };

    const _handleOpen = ({ message } = {}) => {
        dispatchState({ isDisplay: true, message: message || 'sending_email' });
    };

    if (!isDisplay) return null;
    return (
        <div className="alert --sending-email">
            <p className="alert__description">{t(message)}</p>
            <div className="alert__btn svg-white-stroke">
                <IconClose />
            </div>
        </div>
    );
});
WarningSending.displayName = 'WarningSending';
export default WarningSending;
