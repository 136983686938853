import { gql } from '@apollo/client';

export const SEARCH_CUSTOMERS_QUERY = gql`
    query SearchCustomers(
        $q: SearchQueryInput!
        $sort: [SearchSortFieldInput]
        $offset: Int
        $limit: Int
        $includeCompany: Boolean! = false
        $includePhones: Boolean! = false
        $includeStatus: Boolean! = false
        $includeSource: Boolean! = false
        $includeNextTask: Boolean! = false
        $includeCountSms: Boolean! = false
        $includeBalance: Boolean! = false
        $includeEmail: Boolean! = false
        $includeCustomerTag: Boolean! = false
        $includeLocationTag: Boolean! = false
        $includeLatestSmsMessage: Boolean! = false
        $includeCreatedDate: Boolean! = false
        $includeLatestOutgoingCallDate: Boolean! = false
        $includeLatestIncomingCallDate: Boolean! = false
        $includeTopNote: Boolean! = false
        $includeLocation: Boolean! = false
        $includeCountOpenTask: Boolean! = false
        $includeCountCall: Boolean! = false
        $includeCountOutgoingCall: Boolean! = false
        $includeCountIncomingCall: Boolean! = false
        $includeOpportunityValue: Boolean! = false
        $includeCountActiveOpportunity: Boolean! = false
        $includeCountLostOpportunity: Boolean! = false
        $includeCountWonOpportunity: Boolean! = false
        $includeCountOpportunity: Boolean! = false
        $includeOpportunityNote: Boolean! = false
        $includeCountActiveJob: Boolean! = false
        $includeCountUnconfirmedJob: Boolean! = false
        $includeCountConfirmedJob: Boolean! = false
        $includeCountRescheduleJob: Boolean! = false
        $includeCountPendingJob: Boolean! = false
        $includeCountCompletedJob: Boolean! = false
        $includeCountCanceledJob: Boolean! = false
        $includeCountEstimate: Boolean! = false
        $includeCountDraftEstimate: Boolean! = false
        $includeCountPendingEstimate: Boolean! = false
        $includeCountWonEstimate: Boolean! = false
        $includeCountInvoicedEstimate: Boolean! = false
        $includeCountLostEstimate: Boolean! = false
        $customFieldsIncluded: [String]
        $includeCustomFields: Boolean!
        $customJobStatusIncluded: [String]
        $includeCustomJobStatus: Boolean! = false
    ) {
        searchCustomers(
            offset: $offset
            limit: $limit
            q: $q
            sort: $sort
            customFieldsIncluded: $customFieldsIncluded
            customJobStatusIncluded: $customJobStatusIncluded
        ) {
            data {
                id
                # Customer information
                info {
                    id
                    name
                }
                topNote @include(if: $includeTopNote)
                email @include(if: $includeEmail)
                status @include(if: $includeStatus) {
                    label
                    value
                }
                customerTag @include(if: $includeCustomerTag)
                locationTag @include(if: $includeLocationTag)
                # SMS and Calls
                countSms @include(if: $includeCountSms)
                latestSms @include(if: $includeLatestSmsMessage) {
                    id
                    sender
                    receiver
                    message
                    createdDate
                }
                latestIncomingCall @include(if: $includeLatestIncomingCallDate) {
                    id
                    caller
                    receiver
                    duration
                    createdDate
                }
                latestOutgoingCall @include(if: $includeLatestOutgoingCallDate) {
                    id
                    caller
                    receiver
                    duration
                    createdDate
                }

                accountNumber
                company @include(if: $includeCompany)
                createdDate @include(if: $includeCreatedDate)
                balance @include(if: $includeBalance)
                source @include(if: $includeSource) {
                    id
                    name
                }

                location @include(if: $includeLocation) {
                    id
                    name
                    addressTo
                    addressLine1
                    addressLine2
                    city
                    state
                    zip
                    county
                    billingTo
                    billingAddressLine1
                    billingAddressLine2
                    billingCity
                    billingState
                    billingZip
                    latitude
                    longitude
                    note
                }

                phones @include(if: $includePhones) {
                    id
                    phone
                    contactId
                }

                countOpenTask @include(if: $includeCountOpenTask)
                countCall @include(if: $includeCountCall)
                countOutgoingCall @include(if: $includeCountOutgoingCall)
                countIncomingCall @include(if: $includeCountIncomingCall)
                nextTask @include(if: $includeNextTask) {
                    id
                    description
                    date
                }

                # Opportunity
                opportunityValue @include(if: $includeOpportunityValue) {
                    totalActive
                    totalWon
                    totalLost
                    totalAll
                }
                countActiveOpportunity @include(if: $includeCountActiveOpportunity)
                countLostOpportunity @include(if: $includeCountLostOpportunity)
                countWonOpportunity @include(if: $includeCountWonOpportunity)
                countOpportunity @include(if: $includeCountOpportunity)
                opportunityNote @include(if: $includeOpportunityNote) {
                    active
                    won
                    lost
                    all
                }

                # Job
                countActiveJob @include(if: $includeCountActiveJob)
                countUnconfirmedJob @include(if: $includeCountUnconfirmedJob)
                countConfirmedJob @include(if: $includeCountConfirmedJob)
                countRescheduleJob @include(if: $includeCountRescheduleJob)
                countPendingJob @include(if: $includeCountPendingJob)
                countCompletedJob @include(if: $includeCountCompletedJob)
                countCanceledJob @include(if: $includeCountCanceledJob)

                # Estimate
                countEstimate @include(if: $includeCountEstimate)
                countDraftEstimate @include(if: $includeCountDraftEstimate)
                countPendingEstimate @include(if: $includeCountPendingEstimate)
                countWonEstimate @include(if: $includeCountWonEstimate)
                countInvoicedEstimate @include(if: $includeCountInvoicedEstimate)
                countLostEstimate @include(if: $includeCountLostEstimate)

                # Custom fields
                customFieldValues(fields: $customFieldsIncluded) @include(if: $includeCustomFields) {
                    customField {
                        id
                        label
                    }
                    ... on CustomFieldSingleValue {
                        value {
                            ... on CustomFieldTextValue {
                                text: value
                            }
                            ... on CustomFieldNumberValue {
                                number: value
                            }
                            ... on CustomFieldChoicesValue {
                                choices: value
                            }
                            ... on CustomFieldDateTimeValue {
                                dateTime: value
                            }
                            ... on CustomFieldDateValue {
                                date: value
                            }
                        }
                    }
                    ... on CustomFieldMultipleValues {
                        values {
                            ... on CustomFieldTextValue {
                                text: value
                            }
                            ... on CustomFieldNumberValue {
                                number: value
                            }
                            ... on CustomFieldChoicesValue {
                                choices: value
                            }
                            ... on CustomFieldDateTimeValue {
                                dateTime: value
                            }
                            ... on CustomFieldDateValue {
                                date: value
                            }
                        }
                    }
                }
                # End Custom fields

                # Custom Job Status
                customJobStatusValues(fields: $customJobStatusIncluded) @include(if: $includeCustomJobStatus) {
                    id
                    count
                }
                # End Custom Job Status
            }
            pagination {
                total
            }
        }
    }
`;
