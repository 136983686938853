import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SETTINGS_COMPANY } from 'app/config/routes';
import { SETTINGS_GET_LIST_TIMEZONE } from 'app/const/Api';
import { COMMON } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { MIXPANEL_EVENT_NAME } from 'app/const/Mixpanel';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { LIST_INPUT_COMPANY, LIST_TOOLTIP } from 'app/const/Settings';
import { LIST_STATUS } from 'app/const/Status';
import { ROUTE_OPTIMIZER_WORK_TIME_OPTIONS } from 'app/modules/calendar/const/Header';
import { mixpanelEditCompany } from 'app/modules/mixpanel/MixpanelEditCompany';
import { updateSettings, updateSmsAddonSupport } from 'common/redux/actions/authAction';
import {
    crateAndUpdateCompany,
    getDetailCompany,
    getListIndustries
} from 'common/redux/actions/settings/companyAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertCompanyToWorkTime } from 'common/utils/DateUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { companyCountryCode, companySettingCurrencySymbol } from 'common/utils/ObjectUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const CompanyForm = loadable(() => import('./components/CompanyForm'));
const CompanyInfo = loadable(() => import('./components/CompanyInfo'));
const SkeletonLoading = loadable(() => import('./components/SkeletonLoading'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

const Company = () => {
    const { t, i18n } = useTranslation(['setting', 'auth']);
    const dispatch = useDispatch();
    const refForm = useRef(null);
    const refCompanyForm = useRef(null);
    const refStatusBar = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        data: null,
        listIndustries: [],
        timezones: [],
        isInitFetchApi: true,
        isEdit: false,
        tutorial: {}
    });
    const { isEdit, data, listIndustries, timezones, tutorial } = state;

    useEffect(() => {
        dispatch(getDetailCompany(null, getDetailCompanySuccess, getDetailCompanyFailure));
    }, []);

    const getDetailCompanyFailure = (err) => {
        dispatchState({ ...state, isInitFetchApi: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _getTimeZoneSuccess = (response) => {
        dispatchState({ timezones: response.data });
    };

    const getDetailCompanySuccess = (response) => {
        dispatch(
            getListIndustries(
                response.data,
                (profileCompany, listIndustries) =>
                    getListIndustriesSuccess(profileCompany, listIndustries, response.tutorial),
                getListIndustriesFailure
            )
        );
        clientQuery(SETTINGS_GET_LIST_TIMEZONE, { method: 'GET' }, _getTimeZoneSuccess);
    };

    const getListIndustriesSuccess = (profileCompany, listIndustries, tutorial) => {
        setDataForm(profileCompany, listIndustries, tutorial);
    };

    const getListIndustriesFailure = (profileCompany) => {
        setDataForm(profileCompany);
    };
    const _getMessageUpdateCompanySuccess = () => {
        refStatusBar.current.showStatusBar(
            'show_success',
            t('setting:company_information_saved_successfully'),
            LIST_STATUS.SUCCESS
        );
    };
    const updateCompanySuccess = (params, response) => {
        const companyLanguage = data.language;
        companyCountryCode.countryCode = params.country;
        companySettingCurrencySymbol.settingCurrencySymbol = response.data?.company?.currency_symbol || '$';
        handleTrackingEvent(
            mixpanelEditCompany({
                type: MIXPANEL_EVENT_NAME.EDIT_COMPANY,
                company: {
                    phone: params.phone,
                    name: params.name,
                    industry: listIndustries.find((item) => item.id === params.industry_id)?.name
                }
            })
        );
        refCompanyForm.current._removeLoading();
        if (params.newLogoUrl) params.logo = params.newLogoUrl;
        dispatchState({ data: { ...data, ...params } });
        _offEdit();
        const operatingStart =
            ROUTE_OPTIMIZER_WORK_TIME_OPTIONS.find((item) => item.id === params.operating_start) || {};
        const operatingEnd = ROUTE_OPTIMIZER_WORK_TIME_OPTIONS.find((item) => item.id === params.operating_end) || {};
        const companyReducer = {
            logo: params.logo,
            country: params.country,
            currency: params.currency,
            date_format: params.date_format || 'm/d/Y',
            email: params.email,
            website: params.website,
            service: {
                address: params.address,
                city: params.city,
                state: params.state,
                zip: params.zip
            },
            business_hours: {
                start: convertCompanyToWorkTime(operatingStart.name),
                end: convertCompanyToWorkTime(operatingEnd.name)
            },
            timezone: params.timezone,
            industry: listIndustries.find((item) => item.id === params.industry_id)?.name,
            ...response.data
        };
        dispatch(updateSettings({ email_sender: response?.data?.email_sender || '' }));
        dispatch(updateSmsAddonSupport(companyReducer));
        const language = response.data.company.language;
        if (companyLanguage !== language) {
            setTimeout(() => {
                i18n.changeLanguage(language);
                _getMessageUpdateCompanySuccess();
                window.location.href = addBranchPath(SETTINGS_COMPANY);
            }, 0);
        } else {
            _getMessageUpdateCompanySuccess();
        }
    };
    const updateCompanyFailure = ({ message }) => {
        refCompanyForm.current._removeLoading();
        refStatusBar.current.showStatusBar('show_error', message, LIST_STATUS.ERROR);
    };
    const setDataForm = (profileCompany, listIndustries = [], tutorial = {}) => {
        dispatchState({ data: profileCompany, listIndustries: listIndustries, isInitFetchApi: false, tutorial });
    };

    const handleSubmit = (urlLogo, holiday = {}) => {
        const params = {};

        LIST_INPUT_COMPANY.forEach((item) => {
            const newItem = refForm.current[item.name];
            if (newItem) {
                params[item.name] = newItem.value.trim();
            }
        });
        const object_key = refForm.current[COMMON.OBJECT_KEY].value;
        const object_tag = refForm.current[COMMON.OBJECT_TAB].value;

        if (object_key) params.logo = { object_key, object_tag };

        if (urlLogo) {
            if (urlLogo === data.default_logo) params.logo = null;
            params.newLogoUrl = urlLogo;
        } else {
            params.logo = '';
            params.newLogoUrl = data.logo;
        }

        params.operating_start = Number(params.operating_start);
        params.operating_end = Number(params.operating_end);
        params.holiday = holiday;

        dispatch(
            crateAndUpdateCompany(params, (response) => updateCompanySuccess(params, response), updateCompanyFailure)
        );
    };

    const _onEdit = () => dispatchState({ isEdit: true });
    const _offEdit = () => dispatchState({ isEdit: false });

    return (
        <>
            <MainHeaderSettings tutorial={tutorial} isLoading={state.isInitFetchApi} />
            <div className="wrapper-columns">
                <form
                    className={classNames('contents-pages container-column wrapper-box-edit scrolls', {
                        'is-editing': isEdit
                    })}
                    ref={refForm}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <StatusBar ref={refStatusBar} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.COMPANY_SETTING} onAddNew={_onEdit} />
                    {state.isInitFetchApi ? (
                        <SkeletonLoading />
                    ) : isEdit ? (
                        <CompanyForm
                            ref={refCompanyForm}
                            data={data || {}}
                            listIndustries={listIndustries}
                            timezones={timezones}
                            handleSubmit={handleSubmit}
                            onClose={_offEdit}
                        />
                    ) : (
                        <CompanyInfo
                            company={data || {}}
                            listIndustries={listIndustries}
                            timezones={timezones}
                            onEdit={_onEdit}
                        />
                    )}
                </form>
            </div>
        </>
    );
};

export default Company;
