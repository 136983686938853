import classNames from 'classnames';
import { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';

import { KEY_CODE_ESCAPE } from 'app/const/App';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LIST_SETTING_ACCESS_GROWTH } from 'app/const/Settings';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { getPlanUser, isActiveFeature, isNotPermissionValue } from 'common/utils/PermissionUtils';

const DropdownMenu = ({
    leftOptions = [],
    rightOptions = [],
    title = '',
    fileTranslation = '',
    isDoubleMenu = true,
    listClassname = 'col-half',
    isHaveBgGrey = false,
    permissions = {}
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { id: customerId } = useParams();
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan, isTrial } = getPlanUser(profileData);
    const [state, dispatchState] = useReducer(reducer, {
        visible: false
    });
    const refDropDown = useRef(null);

    const { visible: finalVisible } = state;

    useEffect(() => {
        if (finalVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalVisible]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('dropdown-report-menu');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('dropdown-report-menu');
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        finalVisible && dispatchState({ visible: false });
    };

    const _openDropdown = (e) => {
        e.stopPropagation();

        dispatchState({ visible: !finalVisible });
    };

    const _renderMenu = (list) => {
        if (!list.some(({ value }) => value && !isNotPermissionValue(permissions, value))) return null;
        return (
            <div className={listClassname}>
                {list.map((item, index) => {
                    const { id, value, link, tab, subsLink, type, icon, iconStyle, isBeta, isHidden } = item;
                    if (isHidden) return null;
                    if (LIST_SETTING_ACCESS_GROWTH.includes(value) && !isGrowthPlan && !isTrial) return null;
                    if (!value) {
                        if (isNotPermissionValue(permissions, list[index - 1]?.value)) return null;
                        return <div className="line" key={id}></div>;
                    }
                    if (isNotPermissionValue(permissions, value)) return null;
                    if (type === 'title')
                        return (
                            <div className="subtitle" key={id} title={t(`${fileTranslation}:${value}`)}>
                                {t(`${fileTranslation}:${value}`)}
                            </div>
                        );
                    if (!isActiveFeature(value)) return null;
                    return (
                        <Link
                            className={classNames(
                                'items has-icon',
                                {
                                    active: matchPath(location.pathname, {
                                        path: addPrefixPath(subsLink || link),
                                        exact: true
                                    })
                                },
                                iconStyle
                            )}
                            key={id}
                            to={{
                                pathname: addBranchPath(link.replace(':id', customerId)),
                                state: tab ? { reload: Date.now(), newTab: tab } : null
                            }}
                            onClick={_closeDropdown}
                        >
                            <span>{icon}</span>
                            <p className="txt-ellipsis items-text" title={t(`${fileTranslation}:${value}`)}>
                                {t(`${fileTranslation}:${value}`)}
                            </p>
                            {isBeta ? (
                                <span className="status-btn --beta fs-11 fw-500 ml-1 nowrap">{t('addons:beta')}</span>
                            ) : null}
                        </Link>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={classNames('header-items v2-dropdown list-menu', { active: finalVisible })} ref={refDropDown}>
            <div
                className={classNames('dropbtn items v2-btn-default', { 'has-bg-grey': !isHaveBgGrey })}
                onClick={(e) => _openDropdown(e)}
            >
                <h2 className="txt-ellipsis fs-14">{t(`${fileTranslation}:${title}`)}</h2>
                <div className="arrow">
                    <IconDropUpDown width={16} height={16} />
                </div>
            </div>
            <div className="v2-dropdown__menu scrolls" id="dropdown-report-menu">
                {isDoubleMenu ? (
                    <div className="d-flex">
                        {_renderMenu(leftOptions)}
                        {_renderMenu(rightOptions)}
                    </div>
                ) : (
                    _renderMenu(leftOptions)
                )}
            </div>
        </div>
    );
};
export default DropdownMenu;
