import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { ACCOUNT_ROLE } from 'app/const/App';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { INBOX } from 'app/const/Route';
import { SMART_VIEWS, SMART_VIEWS_ORDER } from 'app/const/api/V2';
import { createIndexDialer, getListIndexDialer } from 'common/redux/actions/voipAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { LIMIT_SMART_VIEW_LIST, SMART_VIEW_CUSTOM_ACTIONS, SMART_VIEW_CUSTOM_EVENTS } from '../../constants';
import { getSmartViewUrl, reorder } from '../utils';
import Header from './Header';
import ListWrapper from './ListWrapper';
import LoadingSmartview from './Loading';
import UpdateModal from './UpdateModal';

const ListSmartView = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const userRole = useSelector(({ auth }) => auth.user.profile.role);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const smartViewAddon = useSelector(({ auth }) => auth.user.settings.addons.smart_views);
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const [state, dispatchState] = useReducer(reducer, {
        isEditing: false,
        isLoading: true,
        textSearch: '',
        isShowAll: false,
        data: []
    });
    const { data, isLoading, textSearch, isShowAll } = state;
    const refSidebar = useRef(null);
    const refModal = useRef(null);
    const refData = useRef([]);
    const isAccessSmartview = (isGrowthPlan || isTrial) && !!smartViewAddon;

    // Add custom event listener
    useEffect(() => {
        if (refSidebar.current) {
            refSidebar.current.addEventListener(
                SMART_VIEW_CUSTOM_ACTIONS.UPDATE_SMART_VIEW_QUERY,
                _handleUpdateSmartView
            );
            refSidebar.current.addEventListener(
                SMART_VIEW_CUSTOM_ACTIONS.CREATE_SMART_VIEW_QUERY,
                _handleAddNewSmartView
            );
        }
        return () => {
            if (refSidebar.current) {
                refSidebar.current.removeEventListener(
                    SMART_VIEW_CUSTOM_ACTIONS.UPDATE_SMART_VIEW_QUERY,
                    _handleUpdateSmartView
                );
                refSidebar.current.removeEventListener(
                    SMART_VIEW_CUSTOM_ACTIONS.CREATE_SMART_VIEW_QUERY,
                    _handleAddNewSmartView
                );
            }
        };
    }, [data]);

    // Fetch list data smart view
    useEffect(() => {
        if (!isAccessSmartview) return;
        _loadListSmartView();
    }, []);

    const _loadListSmartView = () => {
        const _fetchListSuccess = ({ data, notification }) => {
            refData.current = data;
            dispatchState({ data, isLoading: false });
            dispatch(getListIndexDialer(data.map((item) => ({ id: item.id, call_index: item.call_index }))));
            // Display alert if have notification in inbox layout
            if (notification)
                dispatchEvent(new CustomEvent(SMART_VIEW_CUSTOM_EVENTS.DISPLAY_ALERT, { detail: { notification } }));
        };

        const _fetchListFail = () => {
            refData.current = [];
            dispatchState({ isLoading: false });
            history.replace(addBranchPath(INBOX));
        };

        clientQuery(SMART_VIEWS, { data: {}, method: 'GET' }, _fetchListSuccess, _fetchListFail);
    };

    const _handleOpenModal = (dataSmartView) => {
        refModal.current?.open(dataSmartView);
    };

    const _handleEdit = (dataSmartView = {}) => {
        _handleOpenModal({ ...dataSmartView, isEditing: true });
    };

    const _handleUpdateSmartView = ({ detail }) => {
        _handleActionSuccess({ type: 'update', data: { ...detail.data.updateSmartView } });
    };

    const _handleAddNewSmartView = ({ detail }) => {
        _handleActionSuccess({ type: 'create', data: { ...detail.data.createSmartView } });
    };

    const _handleReorder = ({ destinationIndex, sourceIndex }) => {
        const items = reorder(data, sourceIndex, destinationIndex);
        const findPreItem = items[destinationIndex - 1];
        const currentItem = items[destinationIndex];
        clientQuery(SMART_VIEWS_ORDER, {
            method: 'PUT',
            data: { id: currentItem?.id || '', after_id: findPreItem?.id || '' }
        });
        refData.current = items;
        dispatchState({ data: items });
    };

    const _handleUpdate = (array, element, searchText) => {
        const index = array.findIndex((item) => item.id === element.id);
        if (index !== -1) {
            const base = { ...array[index], ...element };
            const elementUpdated = element.isSaveColumn
                ? { ...array[index], definition: { ...base.definition, columns: element.columns } }
                : base;
            if (!!searchText) {
                const isMatchedSearch = element.name.toLowerCase().includes(searchText.toLowerCase());
                isMatchedSearch ? (array[index] = elementUpdated) : array.splice(index, 1);
            } else {
                array[index] = elementUpdated;
            }
        }
        return array;
    };

    const _handleAdd = (array, element, searchText) => {
        if (!!searchText) {
            const isMatchedSearch = element.name.toLowerCase().includes(searchText.toLowerCase());
            if (isMatchedSearch) array.unshift(element);
        } else {
            array.unshift(element);
        }
        return array;
    };

    const _handleActionSuccess = ({ type, data: dataSmartView }) => {
        dispatchState((prevState) => {
            let data = [...prevState.data];
            switch (type) {
                case 'update':
                    data = _handleUpdate(data, dataSmartView, prevState.textSearch);
                    refData.current = _handleUpdate(refData.current, dataSmartView);
                    break;
                case 'create':
                    data = _handleAdd(data, dataSmartView, prevState.textSearch);
                    refData.current = [dataSmartView, ...refData.current];
                    dispatch(createIndexDialer({ id: dataSmartView.id, call_index: 0 }));
                    break;
                case 'delete':
                    data = data.filter((item) => item.id !== dataSmartView.id);
                    refData.current = refData.current.filter((item) => item.id !== dataSmartView.id);

                    const isMatchPath = matchPath(pathname, {
                        path: addPrefixPath(getSmartViewUrl(dataSmartView.id)),
                        exact: true
                    });

                    if (isMatchPath) history.replace({ pathname: addBranchPath(INBOX) });
                    break;
                default:
                    break;
            }
            return { ...prevState, data };
        });
    };

    const _handleSearch = (text) => {
        dispatchState((prevState) => {
            const filteredData = !!text
                ? refData.current.filter((item) => item?.name?.toLowerCase().includes(text?.toLowerCase()))
                : refData.current;
            return { ...prevState, data: filteredData, textSearch: text };
        });
    };

    const _handleShowAll = () => {
        dispatchState((prevState) => {
            return { ...prevState, isShowAll: !prevState.isShowAll };
        });
    };

    if (!isAccessSmartview) return null;
    if (isLoading) return <LoadingSmartview />;
    return (
        <>
            <div
                ref={refSidebar}
                id="list-wrapper-smart-view"
                className="sidebar --smart-views flex-column scrolls border-top-black-verydark"
            >
                <Header isSupperAdmin={isSupperAdmin} onSearch={_handleSearch} onAddNew={_handleOpenModal} />
                {isAccessSmartview ? (
                    <ListWrapper
                        data={isShowAll ? data : data.slice(0, LIMIT_SMART_VIEW_LIST)}
                        dataLength={data.length}
                        isShowAll={isShowAll}
                        isSearching={!!textSearch}
                        onDragEnd={_handleReorder}
                        onShowAll={_handleShowAll}
                        onEdit={_handleEdit}
                    />
                ) : null}
            </div>
            {isAccessSmartview ? <UpdateModal ref={refModal} onActionSuccess={_handleActionSuccess} /> : null}
        </>
    );
};

export default ListSmartView;
