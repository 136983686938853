import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { getNestedValue } from 'common/utils/FunctionUtils';
import { ListItemsUsers } from './CustomFieldsFooter';

const AddonFooterWithManageUser = ({ keyAddon = '', pathManage = '', items = null }) => {
    const { t } = useTranslation('addons');
    const addonList = useSelector(({ auth }) => auth.user.settings.addons);
    const permissionAddon =
        getNestedValue(addonList, keyAddon?.toLocaleLowerCase()) || !!addonList[keyAddon?.toLocaleLowerCase()];

    return (
        <div className="boxs__footer">
            {items && isNaN(items) ? <ListItemsUsers data={items} /> : null}
            <Link
                to={addBranchPath(pathManage)}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': !permissionAddon
                })}
            >
                {t('manage')}
                <IconCogWheel />
            </Link>
        </div>
    );
};

export default AddonFooterWithManageUser;
