import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SMART_VIEW_BULK_ACTIONS, SMART_VIEW_BULK_TYPES } from 'app/modules/smartview/constants';
import AlertBulkEmail from './AlertBulkEmail';

const WarningDisplay = ({ count, emailAccount, typeBulk = SMART_VIEW_BULK_TYPES.EMAIL }) => {
    const { t } = useTranslation('smartView');
    const smsAddon = useSelector(({ auth }) => auth.user.settings.addons.smsplivo);
    const getMailName = (typeEmail) => {
        switch (typeEmail) {
            case 'google':
                return 'Gmail';
            case 'microsoft':
                return 'Outlook';
            case 'imap':
                return 'SMTP/IMAP';
            default:
                return typeEmail;
        }
    };

    const bulkAction = SMART_VIEW_BULK_ACTIONS[typeBulk];
    const isEmailBulk = typeBulk === SMART_VIEW_BULK_TYPES.EMAIL;
    const typeEmail = emailAccount?.type || '';
    const nameEmail = getMailName(typeEmail);

    if (typeBulk === SMART_VIEW_BULK_TYPES.SMS && !smsAddon) {
        return <AlertBulkEmail title={t('alert_bulk_sms_title')} content={t('alert_bulk_sms_content')} />;
    }
    if (!emailAccount && isEmailBulk) {
        return (
            <AlertBulkEmail
                title={t('alert_bulk_email_title_required')}
                content={t('alert_bulk_email_content_required')}
            />
        );
    } else if (!!!emailAccount?.smtp?.status && isEmailBulk) {
        return <AlertBulkEmail title={t('alert_bulk_email_smtp_title')} content={t('alert_bulk_email_smtp_content')} />;
    }
    return (
        <>
            <div className="rows --lead">
                <p className="txt-ellipsis fw-600">
                    {count > 1 ? t('send_email_to_leads', { count }) : t('send_email_to_lead', { count })}
                </p>
                <p className="txt-ellipsis grey-very-dark">{bulkAction['warningDisplayDesc']['configure']}</p>
            </div>
            {count > 500 && isEmailBulk ? (
                <AlertBulkEmail
                    title={t('alert_bulk_email_title_limit', { text: nameEmail })}
                    desc={t('alert_bulk_email_content_limit', { text: nameEmail })}
                />
            ) : null}
        </>
    );
};

export default WarningDisplay;
