import classNames from 'classnames';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { SMART_VIEW_BULK_ACTIONS, SMART_VIEW_BULK_TYPES } from '../../constants';
import { useSmartView } from '../../context';
import BulkModal from '../bulk';
import SetLimitSmartView from '../SetLimit';
import CallButton from './CallButton';
import Loading from './Loading';
import PrintButton from './PrintButton';
import SaveAsButton from './SaveAsButton';

const HeaderSmartView = ({ isLoading = false }) => {
    const { id } = useParams();

    const { data, loading, permission, name, isHaveFilter } = useSmartView();
    const refBulkModal = useRef(null);
    const isDisable = !data.length || loading;

    const handleBulkClick = (type) => {
        if (refBulkModal.current) refBulkModal.current.open({}, type);
    };

    if (isLoading) return <Loading />;
    return (
        <>
            <div className="nav-top header flex-betweenitems --smart-views">
                <div className="header__left flex-1">
                    <div className="header-items flexcenter fw-500 gap-4">
                        <SetLimitSmartView />
                    </div>
                </div>
                <CallButton smartId={id} smartName={name} isDisable={isDisable || !isHaveFilter} />
                <BulkButton type={SMART_VIEW_BULK_TYPES.SMS} isDisable={isDisable} onClick={handleBulkClick} />
                <BulkButton type={SMART_VIEW_BULK_TYPES.EMAIL} isDisable={isDisable} onClick={handleBulkClick} />
                <PrintButton isDisable={isDisable} />
                {permission.modify || permission.add ? <SaveAsButton /> : null}
            </div>
            <BulkModal ref={refBulkModal} />
        </>
    );
};

const BulkButton = ({ type = SMART_VIEW_BULK_TYPES.EMAIL, isDisable = true, onClick = () => {} }) => {
    return (
        <div
            className={classNames('header-items v2-btn-default has-icon tooltip', { 'is-disable': isDisable })}
            onClick={() => onClick(type)}
        >
            {SMART_VIEW_BULK_ACTIONS[type]['icon']}
            {SMART_VIEW_BULK_ACTIONS[type]['text']}
            <span className="tooltiptext top">{SMART_VIEW_BULK_ACTIONS[type]['title']}</span>
        </div>
    );
};

export default HeaderSmartView;
