import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ADDONS_MATERIAL_PRESET } from 'app/config/routes';
import { LIST_ITEM_MATERIAL } from 'app/const/Api';
import { MATERIAL_PRESET } from 'app/const/api/V2';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import LoadingPreset from './components/LoadingPreset';
import TablePreset from './components/TablePreset';
import { orderColPresetByRules, convertDataPreset } from './utils';

const AddMaterialPreset = () => {
    const { t } = useTranslation('addons');
    const history = useHistory();
    const materialPresetActivate = useSelector(({ auth }) => auth.user.settings.addons.material.material_preset);
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, data: {}, materials: [] });
    const { data, materials, isLoading } = state;

    useEffect(() => {
        handleGetMaterial();
    }, []);

    const handleGetMaterial = () => {
        const handleSuccess = ({ data, rules, preset }) => {
            const filteredData = {};
            Object.keys({ ...data }).forEach((key) => {
                const keyFilter = key.replace(/s$/, '');
                if (rules[keyFilter]['status']) {
                    const isDouble = keyFilter === 'unit' || keyFilter === 'area';
                    const name = rules[keyFilter]['name'];
                    if (isDouble) {
                        filteredData[keyFilter] = { name, active: true };
                        filteredData[keyFilter]['data'] = data[key];
                        filteredData[keyFilter]['values'] = [];
                    } else {
                        filteredData[keyFilter] = { data: data[key], name, active: true };
                    }
                }
            });
            const presetData = new Map(preset.map((i) => [i.material_id, i.id]));
            const materials = data['materials'].filter((i) => !presetData.get(i.id));
            if (rules['dilution']['status'])
                filteredData['dilution'] = { name: rules['dilution']['name'], active: true };
            dispatchState({ isLoading: false, data: orderColPresetByRules(filteredData, rules), materials });
        };
        const handleFail = () => {
            dispatchState({ isLoading: false });
        };
        clientQuery(
            LIST_ITEM_MATERIAL,
            { data: { inc: 'materials,methods,locations,targets,units,areas,preset,custom_materials', limit: 0 } },
            handleSuccess,
            handleFail
        );
    };

    const handleAddMaterial = (values, cbAlert = () => {}) => {
        const dataSubmit = { data: convertDataPreset(values), method: 'POST', toFormData: false };
        const handleSuccess = () => history.push(addBranchPath(ADDONS_MATERIAL_PRESET));
        const handleFail = ({ message }) => cbAlert(LIST_STATUS.ERROR, message, LIST_STATUS.ERROR);
        clientQuery(MATERIAL_PRESET, dataSubmit, handleSuccess, handleFail);
    };

    if (!materialPresetActivate) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="addons-wrap__container addons-wrap__preset">
            <div className="header flex-betweenitems">
                <Link to={addBranchPath(ADDONS_MATERIAL_PRESET)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('addons:manage_material_preset')}
                </Link>
            </div>
            {isLoading ? (
                <LoadingPreset />
            ) : (
                <TablePreset data={data} materials={materials} onSubmit={handleAddMaterial} />
            )}
        </div>
    );
};
export default AddMaterialPreset;
