import React from 'react';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { CUSTOMERS_ACCOUNT, CUSTOMERS } from 'app/const/Route';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GdListPhone from 'app/components/phone/ListPhone';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import { addBranchPath } from 'app/const/Branch';

const Customer = ({ customerDetail }) => {
    const { t } = useTranslation('customers');
    const { phones = [], email: finalEmail } = customerDetail || {};

    return (
        <div className="wrap-information">
            <div className="flex-betweentop">
                <Link
                    to={addBranchPath(`${CUSTOMERS}/${customerDetail.id}`)}
                    className="v2-btn-default btn-bg-purple tooltip"
                >
                    <span className="fs-13 fw-600">{customerDetail.full_name}</span>
                    <p className="tooltiptext bottom">{t('customers:open_customer')}</p>
                </Link>
                <Link
                    to={addBranchPath(`${CUSTOMERS_ACCOUNT}/${customerDetail.id}`)}
                    className="v2-btn-default --icon-sm tooltip"
                >
                    <IconCogWheel />
                    <p className="tooltiptext bottom ml-n7">{t('customers:edit_customer')}</p>
                </Link>
            </div>
            {!!finalEmail && (
                <a href={`mailto:${finalEmail}`} className="wrap-information__label word-break">
                    {finalEmail}
                </a>
            )}
            <div className="wrap-information__label">
                <GdListPhone data={phones} typeOpen={TYPE_OPEN_SMS.PREVIEW} customer={customerDetail} />
            </div>
        </div>
    );
};

export default Customer;
