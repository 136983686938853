import { KEY_EXPORT_SOCKET } from './report/TypeReport';

export const WS_OPTIONS = {
    transports: ['websocket', 'polling'],
    forceNew: true
};

export const WS_NAMESPACES = {
    JOB: 'job',
    ADDON: 'addon',
    CUSTOMER: 'customer',
    MAP: 'map',
    MAP_GEO: 'mapgeo',
    QUICKBOOKS: 'quickbooks',
    VOIP: 'voip',
    WORK_LOG: 'worklog',
    SETTING: 'setting'
};

const WS_EVENTS = {
    NOTIFICATION_COUNT_NOTE: 'notification/countNote',
    JOB_LOG_CREATE: 'log/create',
    JOB_CUSTOMER_BALANCE: 'customer/balance',
    CUSTOMER_LOCATION_UPDATE: 'location/update',
    QUICKBOOKS_STATUS: 'status',
    SMS_RECEIVE: 'sms/receive',
    SMS_SEND_MESSAGE: 'sms/sendMessage',
    GROUP_CONVERSATION: 'sms/conversation',
    JOB_STATUS: 'status',
    JOB_DELETE: 'delete',
    JOB_MOVE: 'move',
    JOB_RESIZE: 'resize',
    JOB_CREATE: 'create',
    JOB_UPDATE: 'update',
    JOB_REASSIGN: 'reassign',
    CUSTOMER_IMPORTED: 'imported',
    EMAIL_INBOX: 'inbox/email',
    ESTIMATE_STATUS: 'estimate/status',
    INVOICE_STATUS: 'invoice/status',
    CREATE_TASK: 'task/create',
    UPDATE_TASK: 'task/update',
    DELETE_TASK: 'task/delete',
    STATUS_TASK: 'task/status',
    INBOX: 'inbox/countInbox',
    JOB_CUSTOMER_CREDIT: 'customer/credit',
    CHECKLIST_MOVE: 'todo/move',
    CHECKLIST_ADD: 'todo/create',
    CHECKLIST_UPDATE: 'todo/update',
    CHECKLIST_DELETE: 'todo/delete',
    VERIFY_NUMBER_VOIP: 'voip/verify-number',
    TIME_LINE_CALL: 'call',
    SMS_UNKNOWN: 'sms/unknown',
    WORK_LOG_START: 'timer/start',
    WORK_LOG_STOP: 'timer/stop',
    OVERRIDE_INVOICE: 'override/invoice',
    OVERRIDE_CUSTOMER: 'override',
    CALL_TRANSCRIPTION: 'call/transcription',
    CUSTOMER_DEPOSIT: 'deposit',
    EXPORT: 'export'
};

export const WS_EVENTS_WORK_LOG = [
    { event: WS_EVENTS.WORK_LOG_START, key: 'workLogStart' },
    { event: WS_EVENTS.WORK_LOG_STOP, key: 'workLogStop' }
];

export const WS_EVENTS_ADDON = [
    {
        event: WS_EVENTS.SMS_RECEIVE,
        key: 'smsReceiver'
    },
    {
        event: WS_EVENTS.SMS_SEND_MESSAGE,
        key: 'sendMessage'
    },
    {
        event: WS_EVENTS.GROUP_CONVERSATION,
        key: 'smsConversation'
    },
    {
        event: WS_EVENTS.EMAIL_INBOX,
        key: 'emailInbox'
    },
    {
        event: WS_EVENTS.INBOX,
        key: 'inboxHeader'
    },
    {
        event: WS_EVENTS.NOTIFICATION_COUNT_NOTE,
        key: 'notificationCount'
    },
    {
        event: WS_EVENTS.VERIFY_NUMBER_VOIP,
        key: 'verifyNumber'
    },
    {
        event: WS_EVENTS.SMS_UNKNOWN,
        key: 'smsUnknown'
    }
];

export const WS_EVENTS_JOB = [
    {
        event: WS_EVENTS.JOB_STATUS,
        key: 'jobStatus'
    },
    {
        event: WS_EVENTS.JOB_DELETE,
        key: 'jobDelete'
    },
    {
        event: WS_EVENTS.JOB_MOVE,
        key: 'jobMove'
    },
    {
        event: WS_EVENTS.JOB_RESIZE,
        key: 'jobResize'
    },
    {
        event: WS_EVENTS.JOB_CREATE,
        key: 'jobCreate'
    },
    {
        event: WS_EVENTS.JOB_UPDATE,
        key: 'jobUpdate'
    },
    {
        event: WS_EVENTS.JOB_REASSIGN,
        key: 'jobReassign'
    },
    {
        event: WS_EVENTS.INVOICE_STATUS,
        key: 'invoiceStatus'
    },
    {
        event: WS_EVENTS.ESTIMATE_STATUS,
        key: 'estimateStatus'
    },
    {
        event: WS_EVENTS.JOB_LOG_CREATE,
        key: 'logCreate'
    },
    {
        event: WS_EVENTS.JOB_CUSTOMER_BALANCE,
        key: 'customerBalance'
    },
    {
        event: WS_EVENTS.JOB_CUSTOMER_CREDIT,
        key: 'customerCredit'
    },
    {
        event: WS_EVENTS.CHECKLIST_ADD,
        key: 'checkListAdd'
    },
    {
        event: WS_EVENTS.CHECKLIST_DELETE,
        key: 'checkListDelete'
    },
    {
        event: WS_EVENTS.CHECKLIST_UPDATE,
        key: 'checkListUpdate'
    },
    {
        event: WS_EVENTS.CHECKLIST_MOVE,
        key: 'checkListMove'
    }
];

export const WS_EVENTS_CUSTOMER = [
    {
        event: WS_EVENTS.CREATE_TASK,
        key: 'taskCreate'
    },
    {
        event: WS_EVENTS.UPDATE_TASK,
        key: 'taskUpdate'
    },
    {
        event: WS_EVENTS.DELETE_TASK,
        key: 'taskDelete'
    },
    {
        event: WS_EVENTS.STATUS_TASK,
        key: 'taskStatus'
    },
    {
        event: WS_EVENTS.CUSTOMER_IMPORTED,
        key: 'customerImported'
    },
    {
        event: WS_EVENTS.CUSTOMER_LOCATION_UPDATE,
        key: 'customerLocationUpdate'
    },
    {
        event: WS_EVENTS.TIME_LINE_CALL,
        key: 'timelineCall'
    },
    {
        event: WS_EVENTS.OVERRIDE_CUSTOMER,
        key: 'overrideCustomer'
    },
    {
        event: WS_EVENTS.CALL_TRANSCRIPTION,
        key: 'callTranscription'
    },
    { event: WS_EVENTS.CUSTOMER_DEPOSIT, key: 'customerDeposit' },
    {
        event: WS_EVENTS.EXPORT,
        key: KEY_EXPORT_SOCKET.CUSTOMER
    }
];

export const WS_EVENTS_SETTING = [{ event: WS_EVENTS.OVERRIDE_INVOICE, key: 'override' }];
