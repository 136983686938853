import { MATERIAL_STICKY_TYPES } from 'app/const/Sticky';
import IconArea from 'assets/icon/IconArea';
import IconDilution from 'assets/icon/IconDilution';
import IconMaterial from 'assets/icon/IconMaterial';
import IconMethod from 'assets/icon/IconMethod';
import IconQuantity from 'assets/icon/IconQuantity';

export const MATERIAL_EDIT_FIELD_NAME = {
    MATERIAL: 'material',
    UNIT: 'unit',
    UNITS: 'units',
    METHOD: 'method',
    METHODS: 'methods',
    LOCATION: 'location',
    LOCATIONS: 'locations',
    TARGETS: 'targets',
    TARGET: 'target',
    AREA_ID: 'area_id',
    AREA: 'area',
    AREAS: 'areas',
    DILUTION: 'dilution',
    DILUTIONS: 'dilutions',
    UNIT_SELECT: 'unit_select',
    AREA_SELECT: 'area_select',
    CUSTOM_MATERIAL: 'custom_material',
    CUSTOM_MATERIALS: 'custom_materials'
};
export const MATERIAL_EDIT_ROWS = {
    material: {
        type: MATERIAL_EDIT_FIELD_NAME.MATERIAL,
        icon: <IconMaterial />,
        keyValueId: 'material_id',
        typePin: MATERIAL_STICKY_TYPES['material']
    },
    custom_material: {
        type: MATERIAL_EDIT_FIELD_NAME.CUSTOM_MATERIAL,
        icon: <IconMaterial />,
        keyValueId: 'custom_material_id',
        typePin: MATERIAL_STICKY_TYPES['custom_material']
    },
    unit: {
        type: MATERIAL_EDIT_FIELD_NAME.UNIT,
        icon: <IconQuantity />,
        keyValue: 'quantity',
        keyValueId: 'measurement_unit_id',
        typePin: MATERIAL_STICKY_TYPES['unit']
    },
    area: {
        type: MATERIAL_EDIT_FIELD_NAME.AREA,
        icon: <IconArea />,
        keyValue: 'area_quantity',
        keyValueId: 'area_id',
        typePin: MATERIAL_STICKY_TYPES['area_id']
    },
    method: {
        type: MATERIAL_EDIT_FIELD_NAME.METHOD,
        icon: <IconMethod />,
        keyValueId: 'method_id',
        typePin: MATERIAL_STICKY_TYPES['method']
    },
    dilution: {
        type: MATERIAL_EDIT_FIELD_NAME.DILUTION,
        icon: <IconDilution />,
        keyValue: 'dilution'
    }
};
