import classNames from 'classnames';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { SMART_VIEW_BULK_ACTIONS, SMART_VIEW_BULK_TYPES } from 'app/modules/smartview/constants';
import SmsAttachments from 'app/modules/sms/components/SmsAttachments';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment, formatTime } from 'common/utils/DateUtils';
import { useSmartView } from '../../../context';
import LoadingPreviewEmail from '../../header/LoadingPreviewEmail';
import { convertToQuery } from '../../utils/query';
import { sortToQuery } from '../../utils/sortQuery';

const BulkContent = ({
    count,
    phone = {},
    typeBulk = SMART_VIEW_BULK_TYPES.EMAIL,
    dataEmail = {},
    emailId = '',
    emailName = '',
    onSetLoading = () => {}
}) => {
    const { t } = useTranslation('smartView');

    const { profile, company } = useSelector(({ auth }) => auth.user);
    const formatDisplay = formatTime(new Date(), `${convertPhpFormatToMoment(company?.date_format)}, hh:mm A`);
    const { avatar, first_name } = profile;
    const { filtered, sort, limit } = useSmartView();
    const [state, dispatchState] = useReducer(reducer, {
        currentIndex: 0,
        dataPreview: {},
        isLoading: true
    });
    const { currentIndex, dataPreview, isLoading } = state;
    const isEmailBulk = typeBulk === SMART_VIEW_BULK_TYPES.EMAIL;

    useEffect(() => {
        _handleLoadData();
    }, []);

    const _handleLoadData = (newIndex = 0) => {
        onSetLoading(true);
        const dataSubmit = {
            template_id: emailId,
            index: newIndex,
            query: JSON.stringify({
                q: { bool: { queries: [{ bool: { queries: convertToQuery(filtered) } }] } },
                sort: sortToQuery(sort),
                limit
            })
        };
        clientQuery(
            SMART_VIEW_BULK_ACTIONS[typeBulk]['previewEndpoint'],
            { data: dataSubmit, method: 'POST', toFormData: false },
            _loadDataSuccess,
            _loadDataFail,
            _loadDataFinally
        );
    };

    const _loadDataSuccess = ({ data }) => {
        dispatchState({ dataPreview: data, isLoading: false });
    };
    const _loadDataFail = () => {};
    const _loadDataFinally = () => {
        onSetLoading(false);
    };

    const _handleClick = (type) => {
        dispatchState((prevState) => {
            let newIndex = prevState.currentIndex;
            switch (type) {
                case 'increment':
                    newIndex += 1;
                    break;
                case 'decrement':
                    newIndex -= 1;
                    break;
                default:
                    break;
            }
            _handleLoadData(newIndex);
            return { ...prevState, currentIndex: newIndex, isLoading: true };
        });
    };
    return (
        <div className="body-modal">
            <div className="rows">
                <p className="txt-ellipsis fw-600 flex-1">{emailName}</p>
                <p className="txt-ellipsis grey-faux-granite">
                    {count > 1 ? t('send_email_to_leads', { count }) : t('send_email_to_lead', { count })}
                </p>
                <div className={classNames('col-action', { 'is-disable': isLoading })}>
                    <div
                        className={classNames('v2-btn-default --icon-lg', { 'is-disable': currentIndex <= 0 })}
                        onClick={() => _handleClick('decrement')}
                    >
                        <IconArrow isPrev />
                    </div>
                    <div
                        className={classNames('v2-btn-default --icon-lg', {
                            'is-disable': currentIndex + 1 >= count
                        })}
                        onClick={() => _handleClick('increment')}
                    >
                        <IconArrow />
                    </div>
                </div>
            </div>

            {isLoading ? (
                <LoadingPreviewEmail />
            ) : (
                <>
                    <div className="wrap-form">
                        <div className="form">
                            <div className="form__label">{t('customers:to')}:</div>
                            <div className="form__field">
                                <span className="purple-default fw-600 txt-ellipsis name">
                                    {dataPreview?.to?.name || t('common:not_found_name')}
                                </span>
                                <span className="grey-faux-granite txt-ellipsis txt">
                                    {isEmailBulk ? dataPreview?.to?.email : dataPreview?.to?.phone?.phone || ''}
                                </span>
                            </div>
                        </div>
                        <div className="line" />
                        <div className="form">
                            <div className="form__label">{t('smartView:from')}:</div>
                            <div className="form__field">
                                <span className="purple-default fw-600 txt-ellipsis name">
                                    {isEmailBulk ? dataEmail?.name : profile.name}
                                </span>
                                <span className="grey-faux-granite txt-ellipsis txt">
                                    {isEmailBulk ? dataEmail?.email : phone?.phone || ''}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={classNames('scrolls container-content-email', { 'is-sms': !isEmailBulk })}>
                        <div className="rows border-none to-email">
                            <p className="fs-14 fw-600">{dataPreview.subject}</p>
                        </div>
                        {typeBulk === SMART_VIEW_BULK_TYPES.EMAIL ? (
                            <div
                                className="wrap-content-email"
                                dangerouslySetInnerHTML={{ __html: dataPreview?.content }}
                            />
                        ) : (
                            <div className="wrap-content-email">
                                <div className="sms-message flextop">
                                    <div className="sms-message__info mr-1">
                                        <div
                                            className="message-desc"
                                            dangerouslySetInnerHTML={{ __html: dataPreview?.content }}
                                        />
                                        <SmsAttachments
                                            data={dataPreview?.attachments || []}
                                            boxAttachClass={!!dataPreview?.attachments?.length ? ' has-manyimage' : ''}
                                            excludeWrapper
                                        />

                                        <div className="text-right fs-11">
                                            <span className="mr-1">{first_name}</span>
                                            <span className="granite-gray">{formatDisplay}</span>
                                        </div>
                                    </div>
                                    <div className="avt-img">
                                        <img src={avatar} alt="avatar" className="m-0" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default BulkContent;
