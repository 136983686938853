import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import { GET_SETTINGS_TEMPLATES } from 'app/const/api/V2';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { LIST_TOOLTIP } from 'app/const/Settings';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import MainHeaderSettings from 'app/modules/settings/components/MainHeaderSettings';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import BoxTipsSetting from '../components/BoxTipsSetting';
import BoxContentTemplate from './components/BoxContentTemplate';
import BoxHeader from './components/BoxHeader';
import Loading from './components/Loading';
import ServiceAddressOptions from './components/ServiceAddressOptions';

const SettingsTemplates = () => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, data: {}, tutorial: {} });
    const company = useSelector(({ auth }) => auth.user.company);
    const { data, isLoading, tutorial } = state;

    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const refContent = useRef(null);
    const refOptions = useRef(null);

    useEffect(() => {
        const _handleGetSuccess = ({ data, tutorial }) => dispatchState({ data, isLoading: false, tutorial });
        const _handleGetFailed = (err) => {
            checkAccessFail(err, ACCESS_SETTINGS_TAB);
        };
        clientQuery(GET_SETTINGS_TEMPLATES, { data: {}, method: 'GET' })
            .then(_handleGetSuccess)
            .catch(_handleGetFailed);
    }, []);

    const _handleSave = () => {
        const _handleSaveSuccess = () => {
            refButtonSave.current.removeLoading();
            refAlert.current.showStatusBar({
                id: LIST_STATUS.SUCCESS,
                message: t('common:invoice_estimate_update_success'),
                type: LIST_STATUS.SUCCESS
            });
        };

        clientQuery(GET_SETTINGS_TEMPLATES, {
            data: {
                ...refContent.current._getValue(),
                ...refOptions.current._getValue(),
                company_address: data.company_address
            },
            method: 'PUT'
        }).then(_handleSaveSuccess);
    };

    const _handleEditAddress = (company_address) => {
        let companyAddress = company_address;

        if (!companyAddress.length) {
            const companyService = company.service;
            companyAddress = `${company.name} <br />${companyService.address} <br />${companyService.city}, ${
                companyService.state
            } ${companyService.zip} <br />${formatPhoneNumberVoip(company.phone)}`;
        }

        dispatchState({ data: { ...data, company_address: companyAddress } });
    };

    const _renderHeaderRight = () => {
        return <ButtonSave ref={refButtonSave} className="v2-btn-main header-items" onSave={_handleSave} />;
    };

    return (
        <>
            <MainHeaderSettings contentRight={_renderHeaderRight} tutorial={tutorial} isLoading={isLoading} />
            <div className="wrapper-columns">
                <div className="contents-pages container-column templates">
                    <div className="scrolls box-auto">
                        <AlertCustomer ref={refAlert} />
                        <BoxTipsSetting typeId={LIST_TOOLTIP.TEMPLATES} />
                        <div className="page-wrapper">
                            {isLoading ? (
                                <Loading />
                            ) : (
                                <div className="boxs p-0">
                                    <BoxHeader
                                        companyAddress={data.company_address}
                                        onEditAddress={_handleEditAddress}
                                    />
                                    <BoxContentTemplate
                                        ref={refContent}
                                        invoice={data.invoice}
                                        estimate={data.estimate}
                                        workOrder={data.work_order}
                                        workOrderTitle={data.work_order_title}
                                        invoiceTitle={data.invoice_title}
                                        estimateTitle={data.estimate_title}
                                        belowLogoText={data.below_logo_text}
                                        invoiceFooterText={data.footer_text}
                                        estimateFooterText={data.estimate_footer_text}
                                        estimateCost={!!data.estimate_cost}
                                    />
                                    <ServiceAddressOptions
                                        ref={refOptions}
                                        defaultServiceAddress={data.service_address || 0}
                                        optionsDefaultData={{
                                            weather: data.weather || 0,
                                            payment_stub: data.payment_stub || 0,
                                            payment_method: data.payment_method || 0,
                                            next_service_date: data.next_service_date || 0,
                                            check_in_out: data.check_in_out || 0,
                                            service_date: data.service_date || 0,
                                            address_name: data.address_name || 0,
                                            customer_company_name: data.customer_company_name || 0
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsTemplates;
