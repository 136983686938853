import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GDStatusBar from 'app/components/status/statusbar';
import { SETTINGS_TILES } from 'app/const/Api';
import { COMMON } from 'app/const/App';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { NUMBER_FIELDS } from 'app/const/setting/SettingTiles';
import { LIST_TOOLTIP } from 'app/const/Settings';
import { getListOptionsTiles, updateListSettingsTiles } from 'common/redux/actions/settings/tilesAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { CombinedSkeletonLoading, SkeletonFooter } from './components/SkeletonLoading';
import { JOB_CYCLE } from 'app/const/Job';

const GdButton = loadable(() => import('app/components/button'));
const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const ListOptions = loadable(() => import('./components/ListOptions'));
const PreviewBox = loadable(() => import('./components/PreviewBox'));
const PreviewMarker = loadable(() => import('./components/PreviewMarker'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

export default function SettingsTiles() {
    const { t } = useTranslation(['setting']);
    const companyId = useSelector(({ auth }) => auth.user.company.user_id);
    const dispatch = useDispatch();
    const refAlert = useRef(null);
    const isAccessJobCycle = process.env.REACT_APP_WHITE_LIST_USER.split(',').some(
        (item) => item === companyId.toString()
    );

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isUpdated: false,
        isMapSettings: false,
        data: [],
        options: [],
        isInitCallApi: true,
        tutorial: {}
    });

    const { isInitCallApi, isUpdated, isMapSettings, data, options, tutorial, isLoading } = state;

    useEffect(() => {
        _getList(true);
    }, []);

    const _getListOptions = (initDataList, tutorial) => {
        dispatch(
            getListOptionsTiles(
                (response) => _getListOptionsSuccess(response, tutorial),
                _getListOptionsFailure,
                initDataList
            )
        );
    };

    const _getListOptionsSuccess = (response, tutorial) => {
        const emptyOption = { id: '', name: '' };
        if (!isAccessJobCycle) {
            const jobCycleOptionIndex = response.data.findIndex((item) => item.id === JOB_CYCLE);
            if (jobCycleOptionIndex >= 0) response.data.splice(jobCycleOptionIndex, 1);
        }
        dispatchState({
            options: [emptyOption, ...response.data],
            data: response.initDataList,
            isInitCallApi: false,
            isLoading: false,
            tutorial
        });
    };

    const _getListOptionsFailure = (response) => {
        const emptyOption = { id: '', name: '' };
        dispatchState({
            options: [emptyOption],
            data: response.initDataList,
            isInitCallApi: false,
            isLoading: false
        });
    };

    const _getList = (isFirstTime, type = 1) => {
        !isFirstTime && dispatchState({ isLoading: true });
        clientQuery(SETTINGS_TILES, { data: { type }, method: 'GET' }, _getListSuccess, _getListFailure);
    };

    const _getListSuccess = ({ data, tutorial }) => {
        const newData = _transformResponseToData(data);
        if (state.isInitCallApi) {
            _getListOptions(newData, tutorial);
        } else {
            dispatchState({ isLoading: false, data: newData, isInitCallApi: false, tutorial });
        }
    };

    const _transformResponseToData = (responseData) => {
        const result = [];
        for (let index = 0; index < NUMBER_FIELDS; index += 2) {
            const dataField1 = {
                value: responseData[index]?.value || '',
                id: responseData[index]?.id || index + 1
            };
            const dataField2 = {
                value: responseData[index + 1]?.value || '',
                id: responseData[index + 1]?.id || index + 2
            };
            const row = [dataField1, dataField2];
            result.push(row);
        }
        return result;
    };

    const _transformDataToParams = (data) => {
        const result = [];
        for (let index = 0; index < NUMBER_FIELDS / 2; index++) {
            result.push(data[index][0], data[index][1]);
        }
        return result;
    };

    const _getListFailure = (err) => {
        dispatchState({ isLoading: false, data: [], isInitCallApi: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _updateListSuccess = (response) => {
        const newData = _transformResponseToData(response.data);
        _handleShowStatus({ message: t('setting:update_tiles_success'), type: COMMON.SUCCESS });
        dispatchState({ isLoading: false, data: newData, isUpdated: true });
    };

    const _updateListFailure = ({ message }) => {
        _handleShowStatus({ message: !!message?.length ? message : t('setting:update_tiles_failure') });
        dispatchState({ isLoading: false });
    };

    const _onChange = (newData) => {
        dispatchState({ data: newData, isUpdated: false });
    };

    const _handleSave = () => {
        const params = { options: _transformDataToParams(state.data), type: isMapSettings ? 2 : 1 };
        dispatchState({ isLoading: true });
        dispatch(updateListSettingsTiles(params, _updateListSuccess, _updateListFailure));
    };

    const _handleShowStatus = ({ message, type = COMMON.ERROR }) => {
        refAlert.current?.showStatusBar('status_tiles', message, type);
    };

    const handleSwitch = (isMapSettings) => {
        _getList(false, isMapSettings ? 2 : 1);
        dispatchState({ isMapSettings, isInitCallApi: true });
    };

    return (
        <>
            <MainHeaderSettings tutorial={tutorial} isLoading={isLoading} />
            <div className="wrapper-columns">
                <div className="contents-pages tiles">
                    <div className="wrapper-box-edit">
                        <GDStatusBar ref={refAlert} />
                        <BoxTipsSetting typeId={LIST_TOOLTIP.CALENDAR_TILES} />
                        <div className="scrolls box-auto p-8">
                            <div className="tiles-map mb-3">
                                <GdSwitchCheckbox
                                    textChecked={t('common:map')}
                                    textUnChecked={t('common:map')}
                                    wrapClassName="switch large v2-btn-default btn-mapbox cursor"
                                    onChangeImmediately={handleSwitch}
                                />
                            </div>
                            {isInitCallApi ? (
                                <CombinedSkeletonLoading />
                            ) : (
                                <>
                                    {isMapSettings ? (
                                        <PreviewMarker dataExample={data} />
                                    ) : (
                                        <PreviewBox dataExample={data} />
                                    )}
                                    <ListOptions
                                        onChange={_onChange}
                                        dataExample={data}
                                        dataOptions={options}
                                        isUpdated={isUpdated}
                                    />
                                </>
                            )}
                        </div>
                        {state.isInitCallApi ? (
                            <SkeletonFooter />
                        ) : (
                            <div className="form-footer">
                                <div className="form-footer__action">
                                    <GdButton
                                        className="v2-btn-main"
                                        title={t('setting:save_changes')}
                                        onClick={_handleSave}
                                        isLoading={state.isLoading}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
