import { takeLatest } from 'redux-saga/effects';

// sagas
import {
    getListTags,
    getListSettingTags,
    createSettingTag,
    updateSettingTag,
    deleteSettingTags,
    getListConversationTags
} from './tags';
import { getListService, getListServicePlan } from './service';
import {
    getListSimplePaymentMethods,
    getListPaymentMethods,
    createPaymentMethod,
    updatePaymentMethod,
    updateStatusPaymentMethods
} from './paymentMethods';
import { getListSchedules, reassignSchedules, addAndUpdateSchedules, deleteSchedules } from './schedules';
import { getListUsersSettings, createUserSetting, updateUserSetting, deleteUserSettings, getUserDetail } from './users';
import { getListLineItems, createLineItem, updateLineItem, updateStatusLineItems } from './lineItems';
import { getListTaxes, createTax, updateTax, updateStatusTaxes } from './taxes';
import { getListSources, createSource, updateSource, updateStatusSources } from './sources';
import {
    getListServiceTemplate,
    getDetailServiceTemplate,
    updateDetailServiceTemplate,
    previewServiceTemplate,
    getListTemplateReminder,
    getListTemplateConfirmation,
    getListTemplateBroadcast,
    getListTemplateEmailInbox
} from './templates';
import {
    getDetailCompany,
    crateAndUpdateCompany,
    uploadLogo,
    deleteLogo,
    getListTimeZone,
    getListIndustries
} from './company';
import { getListCustoms, createCustom, updateCustom, updateStatusCustoms, getCustomDetail } from './customs';
import {
    getListBroadcast,
    createBroadcast,
    updateBroadcast,
    updateStatusBroadcast,
    getBroadcastDetail
} from './broadcast';
import { updateListSettingTiles, getOptionsSettingTiles } from './tiles';

import { getListSystems, updateSystems, updateStatusSystems, getSystemsDetail } from './systems';

import {
    createSettingEmailInbox,
    getEmailInboxDetail,
    getListEmailInbox,
    updateSettingEmailInbox,
    updateStatusSettingEmailInbox
} from './emailInbox';

import { getListEmailVariable, sendTestEmail } from './email';

// TYPE
import { TYPE_SETTING_PAYMENT_METHOD } from 'common/redux/actions/settings/paymentMethodsAction';
import { TYPE_SETTING_TAGS } from 'common/redux/actions/settings/tagsAction';
import { TYPE_SETTING_SCHEDULES } from 'common/redux/actions/settings/schedulesAction';
import { TYPE_SETTING_USER } from 'common/redux/actions/settings/usersAction';
import { TYPE_SETTING_LINE_ITEM } from 'common/redux/actions/settings/lineItemsAction';
import { TYPE_SETTING_TAX } from 'common/redux/actions/settings/taxesAction';
import { TYPE_SETTING_SOURCE } from 'common/redux/actions/settings/sourcesAction';
import { TYPE_SETTING_TEMPLATE } from 'common/redux/actions/settings/templateAction';
import { TYPE_SETTING_COMPANY } from 'common/redux/actions/settings/companyAction';
import { TYPE_SETTING_CUSTOM } from 'common/redux/actions/settings/customsAction';
import { TYPE_SETTING_SERVICE } from 'common/redux/actions/settings/serviceAction';
import { TYPE_SETTING_BROADCAST } from 'common/redux/actions/settings/broadcastAction';
import { TYPE_SETTING_SYSTEMS } from 'common/redux/actions/settings/systemsAction';
import { TYPE_SETTING_EMAIL_INBOX } from 'common/redux/actions/settings/emailInboxAction';
import { TYPE_EMAIL_SETTING } from 'common/redux/actions/settings/emailAction';
import { TYPE_SETTING_TILES } from 'common/redux/actions/settings/tilesAction';
import { createPipelines, deletePipelines, getListPipelines, updatePipelines } from './pipelines';
import { TYPE_SETTING_PIPELINES } from 'common/redux/actions/settings/pipelinesAction';

export function* settingsGetListServiceWatcher() {
    yield takeLatest(TYPE_SETTING_SERVICE.GET_LIST, getListService);
}

export function* settingsGetListServicePlanWatcher() {
    yield takeLatest(TYPE_SETTING_SERVICE.GET_LIST_PLAN, getListServicePlan);
}

// Payment Methods
export function* settingsGetListSimplePaymentMethodsWatcher() {
    yield takeLatest(TYPE_SETTING_PAYMENT_METHOD.GET_LIST_SIMPLE, getListSimplePaymentMethods);
}

export function* settingsGetListPaymentMethodsWatcher() {
    yield takeLatest(TYPE_SETTING_PAYMENT_METHOD.GET_LIST, getListPaymentMethods);
}

export function* settingsCreatePaymentMethodsWatcher() {
    yield takeLatest(TYPE_SETTING_PAYMENT_METHOD.CREATE, createPaymentMethod);
}

export function* settingsUpdatePaymentMethodsWatcher() {
    yield takeLatest(TYPE_SETTING_PAYMENT_METHOD.UPDATE, updatePaymentMethod);
}

export function* settingsUpdateStatusPaymentMethodsWatcher() {
    yield takeLatest(TYPE_SETTING_PAYMENT_METHOD.UPDATE_STATUS, updateStatusPaymentMethods);
}

//// Tags
export function* settingsGetListSimpleTagsWatcher() {
    yield takeLatest(TYPE_SETTING_TAGS.GET_LIST_SIMPLE, getListTags);
}
export function* settingsGetListConversationTagsWatcher() {
    yield takeLatest(TYPE_SETTING_TAGS.GET_LIST_CONVERSATION_TAGS, getListConversationTags);
}
export function* settingsGetListSettingWatcher() {
    yield takeLatest(TYPE_SETTING_TAGS.GET_LIST, getListSettingTags);
}
export function* settingsCreateTagsWatcher() {
    yield takeLatest(TYPE_SETTING_TAGS.CREATE, createSettingTag);
}
export function* settingsUpdateTagWatcher() {
    yield takeLatest(TYPE_SETTING_TAGS.UPDATE, updateSettingTag);
}
export function* settingsDeleteTagsWatcher() {
    yield takeLatest(TYPE_SETTING_TAGS.DELETE, deleteSettingTags);
}

//// Tiles

export function* settingsGetOptionsTilesWatcher() {
    yield takeLatest(TYPE_SETTING_TILES.GET_LIST_OPTIONS, getOptionsSettingTiles);
}
export function* settingsUpdateListTilesWatcher() {
    yield takeLatest(TYPE_SETTING_TILES.UPDATE_LIST_SETTING, updateListSettingTiles);
}

//// Schedules
export function* settingsGetListSchedulesWatcher() {
    yield takeLatest(TYPE_SETTING_SCHEDULES.GET_LIST, getListSchedules);
}
export function* settingsReassignSchedulesWatcher() {
    yield takeLatest(TYPE_SETTING_SCHEDULES.REASSIGN, reassignSchedules);
}
export function* settingsAddAndUpdateSchedulesWatcher() {
    yield takeLatest(TYPE_SETTING_SCHEDULES.ADD_AND_UPDATE, addAndUpdateSchedules);
}
export function* settingsDeleteSchedulesWatcher() {
    yield takeLatest(TYPE_SETTING_SCHEDULES.DELETE, deleteSchedules);
}

//// Users

export function* getListUsersSettingsWatcher() {
    yield takeLatest(TYPE_SETTING_USER.GET_LIST, getListUsersSettings);
}

export function* getUserDetailWatcher() {
    yield takeLatest(TYPE_SETTING_USER.GET_DETAIL, getUserDetail);
}

export function* settingsCreateUserWatcher() {
    yield takeLatest(TYPE_SETTING_USER.CREATE_USER, createUserSetting);
}

export function* settingsUpdateUserWatcher() {
    yield takeLatest(TYPE_SETTING_USER.UPDATE_USER, updateUserSetting);
}

export function* deleteUserSettingsWatcher() {
    yield takeLatest(TYPE_SETTING_USER.DELETE, deleteUserSettings);
}

// Line Items
export function* settingsGetListLineItemsWatcher() {
    yield takeLatest(TYPE_SETTING_LINE_ITEM.GET_LIST, getListLineItems);
}

export function* settingCreateLineItemWatcher() {
    yield takeLatest(TYPE_SETTING_LINE_ITEM.CREATE, createLineItem);
}

export function* settingUpdateLineItemWatcher() {
    yield takeLatest(TYPE_SETTING_LINE_ITEM.UPDATE, updateLineItem);
}

export function* settingsUpdateStatusLineItemsWatcher() {
    yield takeLatest(TYPE_SETTING_LINE_ITEM.UPDATE_STATUS, updateStatusLineItems);
}

// Taxes
export function* settingsGetListTaxesWatcher() {
    yield takeLatest(TYPE_SETTING_TAX.GET_LIST, getListTaxes);
}

export function* settingsCreateTaxWatcher() {
    yield takeLatest(TYPE_SETTING_TAX.CREATE, createTax);
}

export function* settingsUpdateTaxWatcher() {
    yield takeLatest(TYPE_SETTING_TAX.UPDATE, updateTax);
}

export function* settingsUpdateStatusTaxesWatcher() {
    yield takeLatest(TYPE_SETTING_TAX.UPDATE_STATUS, updateStatusTaxes);
}

// Template Invoice - Estimate - Work Order
export function* settingsGetDetailServiceTemplateWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.GET_LIST, getListServiceTemplate);
}
export function* settingsUpdateDetailServiceTemplateWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.GET_DETAIL, getDetailServiceTemplate);
}
export function* settingsUpdateServiceTemplateWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.CREATE_AND_UPDATE, updateDetailServiceTemplate);
}
export function* settingsPreviewServiceTemplateWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.PREVIEW, previewServiceTemplate);
}
export function* getListTemplateReminderWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_REMINDER_REQUEST, getListTemplateReminder);
}
export function* getListTemplateConfirmationWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_CONFIRMATION_REQUEST, getListTemplateConfirmation);
}

export function* getListTemplateBroadcastWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_BROADCAST_REQUEST, getListTemplateBroadcast);
}
export function* getListTemplateEmailInboxWatcher() {
    yield takeLatest(TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_EMAIL_INBOX_REQUEST, getListTemplateEmailInbox);
}

// Sources
export function* settingsGetListSourcesWatcher() {
    yield takeLatest(TYPE_SETTING_SOURCE.GET_LIST, getListSources);
}

export function* settingsCreateSourceWatcher() {
    yield takeLatest(TYPE_SETTING_SOURCE.CREATE, createSource);
}

export function* settingsUpdateSourceWatcher() {
    yield takeLatest(TYPE_SETTING_SOURCE.UPDATE, updateSource);
}

export function* settingsUpdateStatusSourcesWatcher() {
    yield takeLatest(TYPE_SETTING_SOURCE.UPDATE_STATUS, updateStatusSources);
}

// Company
export function* settingsGetDetailCompanyWatcher() {
    yield takeLatest(TYPE_SETTING_COMPANY.GET_DETAIL, getDetailCompany);
}

export function* settingsGetListIndustry() {
    yield takeLatest(TYPE_SETTING_COMPANY.GET_LIST_INDUSTRIES, getListIndustries);
}

export function* settingsCreateAndUpdateCompanyWatcher() {
    yield takeLatest(TYPE_SETTING_COMPANY.CREATE_AND_UPDATE, crateAndUpdateCompany);
}

export function* uploadLogoWatcher() {
    yield takeLatest(TYPE_SETTING_COMPANY.UPLOAD_LOGO, uploadLogo);
}

export function* deleteLogoWatcher() {
    yield takeLatest(TYPE_SETTING_COMPANY.DELETE_LOGO, deleteLogo);
}

// Customs
export function* settingsGetListCustomsWatcher() {
    yield takeLatest(TYPE_SETTING_CUSTOM.GET_LIST, getListCustoms);
}

export function* settingCreateCustomWatcher() {
    yield takeLatest(TYPE_SETTING_CUSTOM.CREATE, createCustom);
}

export function* settingUpdateCustomWatcher() {
    yield takeLatest(TYPE_SETTING_CUSTOM.UPDATE, updateCustom);
}

export function* settingsUpdateStatusCustomsWatcher() {
    yield takeLatest(TYPE_SETTING_CUSTOM.UPDATE_STATUS, updateStatusCustoms);
}

export function* settingsDetailCustomWatcher() {
    yield takeLatest(TYPE_SETTING_CUSTOM.DETAIL, getCustomDetail);
}

// Broadcast
export function* settingsGetListBroadcastWatcher() {
    yield takeLatest(TYPE_SETTING_BROADCAST.GET_LIST, getListBroadcast);
}

export function* settingCreateBroadcastWatcher() {
    yield takeLatest(TYPE_SETTING_BROADCAST.CREATE, createBroadcast);
}

export function* settingUpdateBroadcastWatcher() {
    yield takeLatest(TYPE_SETTING_BROADCAST.UPDATE, updateBroadcast);
}

export function* settingsUpdateStatusBroadcastWatcher() {
    yield takeLatest(TYPE_SETTING_BROADCAST.UPDATE_STATUS, updateStatusBroadcast);
}

export function* settingsDetailBroadcastWatcher() {
    yield takeLatest(TYPE_SETTING_BROADCAST.DETAIL, getBroadcastDetail);
}

// Email Inbox
export function* settingsGetListEmailInboxWatcher() {
    yield takeLatest(TYPE_SETTING_EMAIL_INBOX.GET_LIST, getListEmailInbox);
}

export function* settingUpdateStatusEmailInboxWatcher() {
    yield takeLatest(TYPE_SETTING_EMAIL_INBOX.UPDATE_STATUS, updateStatusSettingEmailInbox);
}

export function* settingCreateEmailInboxWatcher() {
    yield takeLatest(TYPE_SETTING_EMAIL_INBOX.CREATE_EMAIL_INBOX, createSettingEmailInbox);
}

export function* getEmailInboxDetailWatcher() {
    yield takeLatest(TYPE_SETTING_EMAIL_INBOX.GET_DETAIL, getEmailInboxDetail);
}

export function* settingUpdateEmailInboxWatcher() {
    yield takeLatest(TYPE_SETTING_EMAIL_INBOX.UPDATE_EMAIL_INBOX, updateSettingEmailInbox);
}

export function* settingGetListEmailVariableWatcher() {
    yield takeLatest(TYPE_EMAIL_SETTING.GET_LIST_EMAIL_VARIABLE, getListEmailVariable);
}
export function* settingSendTestEmailWatcher() {
    yield takeLatest(TYPE_EMAIL_SETTING.SEND_TEST_EMAIL, sendTestEmail);
}

export function* settingGetListTimeZoneWatcher() {
    yield takeLatest(TYPE_SETTING_COMPANY.GET_LIST_TIMEZONE, getListTimeZone);
}

// Systems
export function* settingsGetListSystemsWatcher() {
    yield takeLatest(TYPE_SETTING_SYSTEMS.GET_LIST, getListSystems);
}

export function* settingUpdateSystemsWatcher() {
    yield takeLatest(TYPE_SETTING_SYSTEMS.UPDATE, updateSystems);
}

export function* settingsUpdateStatusSystemsWatcher() {
    yield takeLatest(TYPE_SETTING_SYSTEMS.UPDATE_STATUS, updateStatusSystems);
}

export function* settingsDetailSystemsWatcher() {
    yield takeLatest(TYPE_SETTING_SYSTEMS.DETAIL, getSystemsDetail);
}

//Pipelines
export function* settingsPipelinesWatcher() {
    yield takeLatest(TYPE_SETTING_PIPELINES.GET_LIST, getListPipelines);
    yield takeLatest(TYPE_SETTING_PIPELINES.CREATE, createPipelines);
    yield takeLatest(TYPE_SETTING_PIPELINES.UPDATE, updatePipelines);
    yield takeLatest(TYPE_SETTING_PIPELINES.DELETE, deletePipelines);
}
