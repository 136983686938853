import React from 'react';
import { useSelector } from 'react-redux';

import { getPlanUser } from 'common/utils/PermissionUtils';
import AddonsOptionsFooterManage from './AddonsOptionsFooterManage';

const AddonsFooterMaterialPreset = ({ pathManage = '', value = 0 }) => {
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isBasicPlan } = getPlanUser(userProfile);
    return (
        <div className="boxs__footer">
            <AddonsOptionsFooterManage path={pathManage} isDisable={!value || isBasicPlan} />
        </div>
    );
};

export default AddonsFooterMaterialPreset;
