import React from 'react';
import { useTranslation } from 'react-i18next';
const BooleanCondition = ({ name, value = true, onChange = () => {} }) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex gap-8 field">
            <div className="check-radio">
                <input id={'yes_' + name} checked={value} type="radio" name={name} onChange={() => onChange(true)} />
                <label htmlFor={'yes_' + name} className="check-radio__label">
                    {t('common:yes')}
                </label>
            </div>
            <div className="check-radio">
                <input id={'no_' + name} checked={!value} type="radio" name={name} onChange={() => onChange(false)} />
                <label htmlFor={'no_' + name} className="check-radio__label">
                    {t('common:no')}
                </label>
            </div>
        </div>
    );
};
export default BooleanCondition;
