import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, matchPath, useParams } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import { LIST_SETTING_ACCESS_GROWTH } from 'app/const/Settings';
import { getPlanUser, isActiveFeature, isNotPermissionValue } from 'common/utils/PermissionUtils';
import { MENUS_PIN_NAME, MENU_DROPDOWN_CONTENT } from '../constants';

const ContentMenu = ({ name, onCloseDropdown = () => {} }) => {
    if (!name) return null;
    const addonsList = useSelector(({ auth }) => auth.user.settings.addons);
    const enabledList = useSelector(({ auth }) => auth.user.permissions.enabled || []);

    const menuDropdown = MENU_DROPDOWN_CONTENT[name.toLowerCase()];
    if (!menuDropdown) return null;

    const permissions = menuDropdown['funcGetPermission'](
        name.toLowerCase() === MENUS_PIN_NAME.SETTINGS ? enabledList : addonsList
    );

    return (
        <div className="d-flex h-100">
            <ListMenu
                data={menuDropdown['left']}
                ns={menuDropdown['ns']}
                permissions={permissions}
                onCloseDropdown={onCloseDropdown}
            />
            <ListMenu
                data={menuDropdown['right']}
                ns={menuDropdown['ns']}
                permissions={permissions}
                onCloseDropdown={onCloseDropdown}
            />
        </div>
    );
};

const ListMenu = ({ data, permissions = [], ns = 'common', onCloseDropdown = () => {} }) => {
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);

    if (!data.some(({ value }) => value && !isNotPermissionValue(permissions, value))) return null;
    const { t } = useTranslation(ns);
    const { id: customerId } = useParams();

    return (
        <div className="col-half">
            {data.map((item, index) => {
                const { id, value, link, tab, subsLink, type, icon, iconStyle, isBeta, isHidden } = item;
                if (isHidden) return null;
                if (LIST_SETTING_ACCESS_GROWTH.includes(value) && !isGrowthPlan && !isTrial) return null;
                if (!value) {
                    if (isNotPermissionValue(permissions, data[index - 1]?.value)) return null;
                    return <div key={id} className="line" />;
                }
                if (isNotPermissionValue(permissions, value)) return null;
                if (type === 'title') {
                    return (
                        <div key={id} className="subtitle" title={t(value)}>
                            {t(value)}
                        </div>
                    );
                }
                if (!isActiveFeature(value)) return null;

                return (
                    <Link
                        key={id}
                        to={{
                            pathname: addBranchPath(link.replace(':id', customerId)),
                            state: tab ? { reload: Date.now(), newTab: tab } : null
                        }}
                        className={classNames(
                            'items has-icon',
                            {
                                active: matchPath(location.pathname, {
                                    path: subsLink || link,
                                    exact: true
                                })
                            },
                            iconStyle
                        )}
                        onClick={onCloseDropdown}
                    >
                        <span>{icon || null}</span>
                        <p className="txt-ellipsis items-text" title={t(value)}>{t(value)}</p>
                        {isBeta ? <span className="status-btn --beta fs-11 fw-500 ml-1 nowrap">{t('addons:beta')}</span> : null}
                    </Link>
                );
            })}
        </div>
    );
};

export default ContentMenu;
