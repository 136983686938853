import loadable from '@loadable/component';
import { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { COMMON } from 'app/const/App';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { TAGS } from 'app/const/setting/SettingPageName';
import { getTabParams } from 'app/const/setting/SettingParams';
import { LIST_BUTTONS, TAB, getColumns } from 'app/const/setting/SettingTags';
import { LIST_STATUS } from 'app/const/Status';
import { createTag, deleteTags, getListSettingTags, updateTag } from 'common/redux/actions/settings/tagsAction';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { getLocalStorageSettingPage, updateLocalStorageSettingPage } from '../utils/localStorage';
import { getNewParamsOrderStatus } from '../utils/statusOrder';

const GdConfirm = loadable(() => import('app/components/confirm'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ListButtonAction = loadable(() => import('app/modules/settings/components/ListButtonAction'));
const SettingTabsFilter = loadable(() => import('app/modules/settings/components/SettingTabsFilter'));
const SettingPagination = loadable(() => import('app/modules/settings/components/SettingPagination'));
const TagForm = loadable(() => import('app/modules/settings/tags/components/TagForm'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function SettingsTags() {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        checkedItems: {
            is_check_all: false,
            ids: []
        },
        listTab: TAB.LIST,
        currentTab: getLocalStorageSettingPage({
            namePage: TAGS,
            key: COMMON.CURRENT_TAB,
            defaultCurrentTab: TAB.DEFAULT_SELECTED
        }),
        total: 1,
        params: getLocalStorageSettingPage({ namePage: TAGS, key: COMMON.PARAMS }),
        tutorial: {}
    });

    const { checkedItems, params: paramsTags, tutorial, isLoading } = state;

    const refModal = useRef(null);
    const refConfirm = useRef(null);
    const refStatusBar = useRef(null);
    useEffect(() => {
        _getListTag(paramsTags);
    }, []);

    // Callback after call API
    const _getListTagSuccess = ({ data, total, tutorial }) => {
        dispatchState({
            ...state,
            isLoading: false,
            data,
            currentTab: getLocalStorageSettingPage({ namePage: TAGS, key: COMMON.CURRENT_TAB }),
            checkedItems: { is_check_all: false, ids: [] },
            total,
            params: getLocalStorageSettingPage({ namePage: TAGS, key: COMMON.PARAMS }),
            tutorial
        });
    };

    const _getListTagFailure = (err) => {
        dispatchState({ ...state, isLoading: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _createTagSuccess = () => {
        refModal.current.hideModal();
        _getListTag(paramsTags);
        refStatusBar.current.showStatusBar('show_success', t('setting:create_tag_success'), LIST_STATUS.SUCCESS);
    };

    const _createTagFailure = () => {
        refModal.current.hideModal();
        dispatchState({ ...state, isLoading: false });
        refStatusBar.current.showStatusBar('show_error', t('setting:create_tag_failure'), LIST_STATUS.ERROR);
    };

    const _updateTagSuccess = (tagUpdated) => {
        refModal.current.hideModal();
        dispatchState({
            ...state,
            isLoading: false,
            data: state.data.map((item) => {
                if (item.id === tagUpdated.id) return { ...item, name: tagUpdated.name };
                return item;
            })
        });
        refStatusBar.current.showStatusBar(
            'show_success',
            t('setting:edit_tag_success', { tag: tagUpdated.name }),
            LIST_STATUS.SUCCESS
        );
        _getListTag(paramsTags);
    };

    const _updateTagFailure = ({ message }) => {
        refModal.current.hideModal();
        dispatchState({ isLoading: false });
        refStatusBar.current.showStatusBar('show_error', message || t('setting:edit_tag_failure'), LIST_STATUS.ERROR);
    };

    const _checkIsInLastPage = ({ total, offset, limit }) => {
        if (offset + limit >= total) return true;
        return false;
    };

    const _checkIsInFirstPage = ({ total, limit }) => {
        if (limit >= total) return true;
        return false;
    };

    const _deleteTagsSuccess = (response) => {
        refModal.current.hideModal();
        refStatusBar.current.showStatusBar('show_success', response.message, LIST_STATUS.SUCCESS);
        const params = getLocalStorageSettingPage({ namePage: TAGS, key: COMMON.PARAMS });
        const isLastPage = _checkIsInLastPage({ total: state.total, offset: params.offset, limit: params.limit });

        //Process went action in last page,
        if (isLastPage) {
            const isRemoveAllItemInPage = response.listIdChecked.length === state.data.length;
            const isInFirstPage = _checkIsInFirstPage({ total: state.total, limit: params.limit });
            if (isRemoveAllItemInPage && !isInFirstPage) {
                const newParams = params;
                newParams.offset = params.offset - params.limit;
                _getListTag(newParams, state.currentTab, false);
            }
        }

        if (!isLastPage) _getListTag(params, state.currentTab, false);
    };

    const _deleteTagsFailure = (response) => {
        refStatusBar.current.showStatusBar('show_error', response.message, LIST_STATUS.ERROR);
        dispatchState({ ...state, isLoading: false });
    };

    // Dispatch call API
    const _getListTag = (params, tabId = state.currentTab, isLoading = true) => {
        updateLocalStorageSettingPage({
            namePage: TAGS,
            value: { params: params, current_tab: tabId }
        });
        dispatchState({ isLoading: isLoading, params: params, currentTab: tabId });
        dispatch(getListSettingTags(params, _getListTagSuccess, _getListTagFailure));
    };

    const _handleSaveOnModal = (params) => {
        params.type = paramsTags.type;
        if (params.id) {
            dispatch(updateTag(params, () => _updateTagSuccess(params), _updateTagFailure));
        } else {
            dispatch(createTag(params, _createTagSuccess, _createTagFailure));
        }
    };

    const _handleChangeTab = (tabId) => _getListTag({ ...paramsTags, ...getTabParams(tabId), offset: 0 }, tabId);
    const _handleChangePage = (newParams) => {
        _getListTag(newParams);
    };

    const _onConfirmDeleteTags = (params) => {
        const tagsUpdated = params.ids;

        const tagsData = [];
        const idsDeleted = [];

        state.data.forEach((tagItem) => {
            if (tagsUpdated.includes(tagItem.id)) {
                idsDeleted.push(tagItem.name);
            } else {
                tagsData.push(tagItem);
            }
        });

        dispatchState({
            ...state,
            data: tagsData,
            checkedItems: { is_check_all: false, ids: [] }
        });
        dispatch(deleteTags({ ...params, idsDeleted, type: paramsTags?.type }, _deleteTagsSuccess, _deleteTagsFailure));
    };

    const _handleClickButtonDelete = (value) => {
        const listIdChecked = checkedItems.ids;
        if (listIdChecked.length) {
            refConfirm.current.open({ ids: listIdChecked, type: value });
        }
    };

    const _onClickHeaderCallBack = (action) =>
        _getListTag(getNewParamsOrderStatus({ params: paramsTags, actionEvent: action }));
    // Dispatch action

    const _handleEditTags = (tagsItem) => {
        refModal.current.showModal(tagsItem.row);
    };

    const _onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState({ ...state, checkedItems: stateCheckedItems });
    };

    const _renderHeaderLeft = () => {
        return (
            <>
                <SettingTabsFilter list={state.listTab} onChange={_handleChangeTab} tabActive={state.currentTab} />
                <div className="modal-setting">
                    <TagForm ref={refModal} handleSave={_handleSaveOnModal} />
                </div>
            </>
        );
    };

    const _renderActionHeader = () => {
        return (
            <div className="header --filter">
                <CheckBoxHeader checkedItems={checkedItems} />
                <ListButtonAction
                    list={LIST_BUTTONS}
                    fileTranslation="report"
                    onSubmit={_handleClickButtonDelete}
                    disabled={!checkedItems.ids.length}
                />
            </div>
        );
    };

    return (
        <>
            <MainHeaderSettings contentLeft={_renderHeaderLeft} tutorial={tutorial} isLoading={isLoading} />
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages maintables-page">
                    <StatusBar ref={refStatusBar} />
                    {_renderActionHeader()}
                    <div className="tab-contents box-auto has-footer">
                        <div className="tab-conts tab-content-active">
                            <GdGridView
                                isLoading={state.isLoading}
                                classTable="scrolls-x has-checkbox"
                                content={state.data}
                                showCheckBox
                                fileTranslation={'setting'}
                                handleClick={_handleEditTags}
                                checkedItems={state.checkedItems}
                                onChangeStateCheckedItems={_onChangeStateCheckedItems}
                                handleClickHeader={(event) => {
                                    _onClickHeaderCallBack(event);
                                }}
                                {...getColumns({ actionStatus: state.params?.order })}
                                isScroll
                            />
                        </div>
                    </div>
                    <SettingPagination totalItem={state.total} namePage={TAGS} onSelect={_handleChangePage} />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('setting:deleting_tags')}
                        message={t('setting:are_you_sure_delete_tag')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_onConfirmDeleteTags}
                        titleConfirm={t('setting:delete')}
                    />
                </div>
            </div>
        </>
    );
}

export default SettingsTags;
