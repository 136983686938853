import { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import { LIST_ITEM_MATERIAL } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LoadingForm } from 'app/modules/jobdetail/tabs/materials/components/Loading';
import IconGoto from 'assets/icon/IconGoto';
import { clientQuery } from 'common/utils/ApiUtils';
import { sortSticky } from 'common/utils/FunctionUtils';
import { MATERIAL_EDIT_FIELD_NAME } from '../consts';
import { applyPresetData, clearDataMaterial, getDefaultDataMaterial } from '../utils';
import DropdownMultiSelectWithPreset from './DropdownMultiSelectWithPreset';
import MaterialEditRow from './MaterialEditRow';

export default function EditForm({ isVisible, id, data, handleSubmit, closeForm, wrapClass, quickAdd = false }) {
    const isEdit = !!id;
    const { t } = useTranslation(['jobDetail']);

    const [state, dispatchState] = useReducer(reducer, {
        locations: [],
        targets: [],
        methods: {},
        units: {},
        areas: {},
        custom_materials: {},
        dilutions: {},

        isLoading: true,
        material: null,
        items: {},
        rules: {},
        preset: []
    });

    const refButtonSave = useRef(null);

    const refMaterial = useRef(null);
    // refs value
    const refUnit = useRef(null);
    const refDilution = useRef(null);
    const refArea = useRef(null);
    const refMethod = useRef(null);
    const refCustomMaterial = useRef(null);
    const refLocations = useRef(null);
    const refTargets = useRef(null);

    const { isLoading, items, rules: finalRules, material } = state;
    const {
        materials: listMaterials,
        custom_materials: customMaterials,
        methods: listMethods,
        units: listUnits,
        areas: listAreas,
        locations: listLocations,
        targets: listTargets
    } = items || {};
    const {
        area: areaRow = {},
        location: locationRow = {},
        method: methodRow = {},
        target: targetRow = {},
        unit: unitRow = {},
        material: materialRow = {},
        dilution: dilutionRow = {},
        custom_material: customMaterialsRow = {}
    } = finalRules;

    useEffect(() => {
        isVisible && _getItemMaterial();
    }, [isVisible]);

    const _handleCloseForm = (e) => {
        e && e.stopPropagation();
        closeForm();
    };

    const _getItemMaterial = () => {
        const _getItemMaterialSuccess = (response) => {
            const { data: responseData, rules, preset = [] } = response;
            const finalResponseData = Object.keys(responseData).reduce((acc, key) => {
                const value = responseData[key];
                acc[key] = sortSticky(Array.isArray(value) ? value : []);
                return acc;
            }, {});
            const { materials: materialItems } = finalResponseData;
            const itemMaterialId = id || materialItems?.[0]?.id || '';
            const material = isEdit
                ? data.find((item) => item.id === itemMaterialId)?.material?.id || ''
                : materialItems.find((item) => item.id === itemMaterialId)?.id || '';
            const materialPreset = preset.find((item) => item.material_id === material) || null;

            dispatchState((prevState) => {
                const newState = { ...prevState, material, isLoading: false, items: finalResponseData, rules, preset };
                if (materialPreset)
                    Object.assign(newState, applyPresetData({ preset: materialPreset, data: finalResponseData }));
                if (id) {
                    const materialData = data.find((item) => item.id === id) || null;
                    if (!materialData) return newState;
                    Object.assign(
                        newState,
                        getDefaultDataMaterial({
                            data: finalResponseData,
                            materialData,
                            materialPreset: materialPreset || {}
                        })
                    );
                }
                return newState;
            });
        };

        const _getListMaterialFailed = () => {
            dispatchState({ isLoading: false });
        };

        clientQuery(
            LIST_ITEM_MATERIAL,
            {
                data: { inc: 'materials,methods,locations,targets,units,areas,preset,custom_materials', limit: 0 },
                method: 'GET'
            },
            _getItemMaterialSuccess,
            _getListMaterialFailed
        );
    };

    const handleSelectMaterial = (materialId) => {
        if (material === materialId) return;
        const materialPreset = state.preset.find((item) => item.material_id === materialId) || null;
        dispatchState((prevState) => {
            const newState = { ...prevState, material: materialId, forceUpdate: new Date().getTime() };
            // Set preset data to material
            materialPreset
                ? Object.assign(newState, applyPresetData({ preset: materialPreset, data: prevState.items }))
                : Object.assign(newState, clearDataMaterial({ items: prevState.items, rules: prevState.rules }));
            return newState;
        });
    };

    const _handleSubmit = (e) => {
        e && e.preventDefault();
        const params = { location_ids: [], target_ids: [] };
        if (refDilution.current) Object.assign(params, refDilution.current.getData());
        if (refUnit.current) Object.assign(params, refUnit.current.getData());
        if (refArea.current) Object.assign(params, refArea.current.getData());
        if (refMethod.current) Object.assign(params, refMethod.current.getData());
        if (refMaterial.current) Object.assign(params, refMaterial.current.getData());
        if (refCustomMaterial.current) Object.assign(params, refCustomMaterial.current.getData());
        if (refLocations.current) Object.assign(params, { location_ids: refLocations.current._getValue() });
        if (refTargets.current) Object.assign(params, { target_ids: refTargets.current._getValue() });
        quickAdd && refButtonSave.current.removeLoading();
        handleSubmit(id, params, items);
    };

    const _renderForm = () => {
        if (isLoading) return <LoadingForm />;
        return (
            <div className="details-job">
                {materialRow.status ? (
                    <MaterialEditRow
                        ref={refMaterial}
                        type={MATERIAL_EDIT_FIELD_NAME.MATERIAL}
                        name={materialRow.name}
                        data={listMaterials}
                        selected={{ id: state.material || '' }}
                        onSelect={handleSelectMaterial}
                    />
                ) : null}
                {unitRow.status ? (
                    <MaterialEditRow
                        ref={refUnit}
                        forceUpdate={state.forceUpdate}
                        type={MATERIAL_EDIT_FIELD_NAME.UNIT}
                        name={unitRow.name}
                        data={listUnits}
                        selected={state.units?.selected}
                        presetData={state.units.presetData}
                        presetValues={state.units.presetValues}
                    />
                ) : null}
                {dilutionRow.status ? (
                    <MaterialEditRow
                        ref={refDilution}
                        forceUpdate={state.forceUpdate}
                        type={MATERIAL_EDIT_FIELD_NAME.DILUTION}
                        name={dilutionRow.name}
                        selected={{
                            value: typeof state.dilutions === 'string' ? state.dilutions : state.dilutions?.value || ''
                        }}
                        presetValues={state.dilutions.presetValues}
                    />
                ) : null}
                {methodRow.status ? (
                    <MaterialEditRow
                        ref={refMethod}
                        isEdit={isEdit}
                        forceUpdate={state.forceUpdate}
                        type={MATERIAL_EDIT_FIELD_NAME.METHOD}
                        name={methodRow.name}
                        data={listMethods}
                        selected={state.methods.selected}
                        presetData={state.methods.presetData}
                    />
                ) : null}
                {customMaterialsRow.status ? (
                    <MaterialEditRow
                        ref={refCustomMaterial}
                        isEdit={isEdit}
                        forceUpdate={state.forceUpdate}
                        type={MATERIAL_EDIT_FIELD_NAME.CUSTOM_MATERIAL}
                        name={customMaterialsRow.name}
                        data={customMaterials}
                        selected={state.custom_materials.selected}
                        presetData={state.custom_materials.presetData}
                    />
                ) : null}
                {(locationRow.status || targetRow.status) && <div className="line" />}
                {locationRow.status ? (
                    <DropdownMultiSelectWithPreset
                        ref={refLocations}
                        title={locationRow.name}
                        type={MATERIAL_EDIT_FIELD_NAME.LOCATIONS}
                        data={listLocations}
                        presetData={state.locations.presetData}
                        selected={state.locations.selected || []}
                        forceUpdate={state.forceUpdate}
                    />
                ) : null}
                {targetRow.status ? (
                    <DropdownMultiSelectWithPreset
                        ref={refTargets}
                        title={targetRow.name}
                        type={MATERIAL_EDIT_FIELD_NAME.TARGETS}
                        data={listTargets}
                        presetData={state.targets.presetData}
                        selected={state.targets.selected || []}
                        forceUpdate={state.forceUpdate}
                    />
                ) : null}
                {areaRow.status && <div className="line" />}
                {areaRow.status ? (
                    <MaterialEditRow
                        ref={refArea}
                        forceUpdate={state.forceUpdate}
                        isEdit={isEdit}
                        type={MATERIAL_EDIT_FIELD_NAME.AREA}
                        name={areaRow.name}
                        data={listAreas}
                        selected={state.areas.selected}
                        presetData={state.areas.presetData}
                        presetValues={state.areas.presetValues}
                    />
                ) : null}
            </div>
        );
    };

    return (
        <div className={wrapClass || 'container-column form-edit-template form-edit-material'}>
            <div className="header-modal">
                <div className="d-flex name-tabs">
                    <IconGoto />
                    <div className="title-h1 ml-2">
                        {!isEdit ? t('jobDetail:add_material') : t('jobDetail:edit_material')}
                    </div>
                </div>
                <div className="d-flex">
                    <div onClick={_handleCloseForm} className="v2-btn-default --transparent mr-3" tabIndex="0">
                        {t('jobDetail:cancel')}
                    </div>
                    <ButtonSave ref={refButtonSave} onSave={_handleSubmit} />
                </div>
            </div>
            {_renderForm()}
        </div>
    );
}
