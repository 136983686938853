import loadable from '@loadable/component';
import { React, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { SETTINGS_PLANS } from 'app/config/routes';
import { GET_LIST_SCHEDULES_SETTING, scheduleSettingDelete } from 'app/const/Api';
import { GET_SCHEDULES_JOB_COUNTS } from 'app/const/api/V2';
import { COMMON, LIST_STATUS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { GOOGLE_PLACES_API_URL } from 'app/const/Keys';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { SCHEDULES } from 'app/const/setting/SettingPageName';
import { getGridColumnsSettingSchedules } from 'app/const/setting/SettingSchedulesTable';
import { LIST_TOOLTIP, SET_JOB_DEFAULT } from 'app/const/Settings';
import { displayLocation } from 'app/modules/customer/detail/locations/utils';
import { mixpanelAddSchedule } from 'app/modules/mixpanel/MixpanelAddSchedule';
import { getLocalStorageSettingPage, updateLocalStorageSettingPage } from 'app/modules/settings/utils/localStorage';
import { updateCalendar } from 'common/redux/actions/calendar';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import { updateSchedules } from 'common/redux/actions/settings/schedulesAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import SettingScheduleProvider from '../context/SettingScheduleContext';

const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const IconUpgrade = loadable(() => import('assets/icon/IconUpgrade'));
const ActionHeader = loadable(() => import('./components/ActionHeader'));
const ReAssignModal = loadable(() => import('./components/ReAssignModal'));
const GDModalWarning = loadable(() => import('app/components/modal/ModalWarning'));
const ButtonSave = loadable(() => import('app/components/button/ButtonSave'));
const AlertCustomer = loadable(() => import('app/modules/customer/components/AlertCustomer'));
const ModalLoading = loadable(() => import('app/components/loading/ModalLoading'));
const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const IconMerge = loadable(() => import('assets/icon/IconMerge'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));
const GdConfirm = loadable(() => import('app/components/confirm'));

const SettingsService = () => {
    const { t } = useTranslation(['setting']);
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        checkedItems: { ids: null },
        total: 1,
        params: getLocalStorageSettingPage({ namePage: SCHEDULES, key: COMMON.PARAMS }),
        isEnableDelete: false,
        loadMap: false,
        tutorial: {}
    });

    const idSelected = state.checkedItems.ids;
    const { data: schedules, params, isLoading, tutorial } = state;
    const scheduleLength = schedules.length;
    const { userData, schedulesStateReducer, schedulesCalendar } = useSelector(({ auth, schedules, calendar }) => ({
        userData: auth.user,
        schedulesStateReducer: schedules,
        schedulesCalendar: calendar.schedules
    }));

    const {
        isFirstTime: scheduleFirstTime,
        data: { schedules: oldSchedules }
    } = schedulesStateReducer;

    const GOOGLE_MAPS_CLIENT_KEY = userData.settings.google.geo_key;
    const scheduleNumber = userData.profile.plan.schedule_number;
    const profileId = userData.profile.id;
    const refConfirm = useRef(null);
    const refConfirmDelete = useRef(null);
    const refButtonSave = useRef(null);
    const isHaveButton = scheduleLength >= scheduleNumber;
    const refAlert = useRef(null);
    const refLoading = useRef(null);
    const refListSchedule = useRef([]);
    const refReAssign = useRef(null);

    useEffect(() => {
        const initParams = params || getLocalStorageSettingPage({ namePage: SCHEDULES, key: COMMON.PARAMS });
        _getListSchedulesSetting(initParams);
    }, []);

    useEffect(() => {
        loadGoogleMapScript(() => {
            dispatchState({ loadMap: true });
        });
    }, [state.loadMap]);

    const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            callback();
        } else {
            const googleMapScript = document.createElement('script');
            googleMapScript.src = GOOGLE_PLACES_API_URL(GOOGLE_MAPS_CLIENT_KEY);
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener('load', callback);
        }
    };

    const _getListSchedulesSetting = (params) => {
        !isLoading && dispatchState({ isLoading: true });

        updateLocalStorageSettingPage({
            namePage: SCHEDULES,
            value: { params: params }
        });

        const _success = ({ data, total, tutorial }) => {
            const scheduleIdsArray = [];
            const tempSchedules = [];
            data.forEach((item) => {
                scheduleIdsArray.push(item.id);
                tempSchedules.push({
                    id: item.id,
                    name: item.name,
                    nickname: item.nickname,
                    assignee: item.assignee,
                    groups: item.groups,
                    start_address: item.start_address,
                    end_address: item.end_address,
                    active_job_counts: t('addons:loading'),
                    recurring_job_counts: 0
                });
            });
            refListSchedule.current = tempSchedules;
            dispatchState({ data: tempSchedules, isLoading: false, checkedItems: { ids: null }, total, tutorial });
            const _handleSuccess = ({ data }) => {
                if (Array.isArray(data) && !!data.length) {
                    const scheduleMap = data.reduce((map, scheduleData) => {
                        map[scheduleData.id] = scheduleData;
                        return map;
                    }, {});
                    const updatedSchedules = tempSchedules.map((schedule) => {
                        const matched = scheduleMap[schedule.id];
                        return matched
                            ? {
                                  ...schedule,
                                  active_job_counts: matched.active_job_counts ?? 0,
                                  recurring_job_counts: matched.recurring_job_counts ?? 0
                              }
                            : schedule;
                    });
                    refListSchedule.current = updatedSchedules;
                    dispatchState({ data: updatedSchedules });
                }
            };

            const _handleFailed = ({ message }) => {
                refListSchedule.current = refListSchedule.current.map((schedule) => ({
                    ...schedule,
                    active_job_counts: 0,
                    recurring_job_counts: 0
                }));
                dispatchState({ data: refListSchedule.current });
                _showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
            };
            clientQuery(
                GET_SCHEDULES_JOB_COUNTS,
                { data: { ids: scheduleIdsArray.join(',') }, method: 'GET' },
                _handleSuccess,
                _handleFailed
            );
        };
        const _failed = (err) => {
            dispatchState({ data: [], isLoading: false });
            checkAccessFail(err, ACCESS_SETTINGS_TAB);
        };
        clientQuery(GET_LIST_SCHEDULES_SETTING, { data: params, method: 'GET' }, _success, _failed);
    };

    const _handleAddSchedule = () => {
        clientQuery(GET_LIST_SCHEDULES_SETTING, { method: 'POST' }, _handleAddSuccess, _handleAddFail);
    };

    const _handleAddSuccess = ({ data }) => {
        handleTrackingEvent(mixpanelAddSchedule({ id: profileId }));
        const { name: scheduleName, start_point, end_point } = data;
        const { address, city, state, zip } = userData.company.service;
        const addressDefault = displayLocation({ street1: address, city, state, zip, placeInputSchedule: true });

        _showStatusBar({ id: LIST_STATUS.SUCCESS, message: t('setting:added_schedule_success', { scheduleName }) });

        refListSchedule.current = [
            ...refListSchedule.current,
            {
                ...data,
                end_address: end_point?.location || addressDefault,
                start_address: start_point?.location || addressDefault,
                active_job_counts: 0,
                groups: []
            }
        ];

        dispatchState({
            data: refListSchedule.current
        });

        if (!scheduleFirstTime)
            dispatch(updateSchedules({ data: { ...schedulesStateReducer.data, schedules: [...oldSchedules, data] } }));
        if (!schedulesCalendar.length) dispatch(updateCalendar({ schedules: [data] }));
    };

    const _handleMaxSchedule = () => {
        _showStatusBar({
            id: LIST_STATUS.ERROR,
            message: {
                textAlert: t('setting:message_add_schedule_fail'),
                textNotice: t('setting:to_add_additional_schedules_please_upgrade_your_account'),
                linkedLetters: t('setting:upgrade').toLowerCase(),
                isLink: true
            },
            type: LIST_STATUS.ERROR
        });
    };

    const _handleAddFail = ({ message }) => {
        _showStatusBar({
            id: LIST_STATUS.ERROR,
            message: !!message?.length ? message : t('setting:message_add_schedule_fail'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleReAssign = () => {
        refLoading.current._open();
    };

    const _handleAssignSuccess = ({ message }, item) => {
        refListSchedule.current = [...refListSchedule.current].map((element) => {
            if (element.id === item[0].id) {
                return {
                    ...element,
                    active_job_counts: SET_JOB_DEFAULT
                };
            }

            if (element.id === item[1].id) {
                return {
                    ...element,
                    active_job_counts: element.active_job_counts + item[0].active_job_counts,
                    recurring_job_counts: element.recurring_job_counts + item[0].recurring_job_counts
                };
            }
            return element;
        });

        refLoading.current._close();
        _showStatusBar({ id: LIST_STATUS.SUCCESS, message });
        dispatchState({ data: refListSchedule.current });
    };

    const _handleAssignFail = ({ message }) => {
        refLoading.current._close();
        _showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
    };
    const onChangeStateCheckedItems = (ids) => {
        dispatchState({
            data: refListSchedule.current,
            checkedItems: { ids: ids === state.checkedItems['ids'] ? null : ids },
            isEnableDelete: true
        });
    };

    const _handleDeleteSchedule = () => {
        clientQuery(
            scheduleSettingDelete(idSelected),
            { method: 'DELETE' },
            (response) => _handleDeleteSuccess(response, idSelected),
            _handleDeleteFail,
            _handleDeleteFinally
        );
    };

    const _handleDeleteSuccess = ({ message }, ids) => {
        _showStatusBar({ id: LIST_STATUS.SUCCESS, message: t('setting:deleted_schedule_success') });

        if (!!ids || message.length) {
            const data = [];
            [...state.data].forEach((item) => {
                if (ids !== item.id) {
                    data.push(item);
                }
            });

            refListSchedule.current = data;
            dispatchState({
                data: data
            });
            dispatch(actionGetListSchedule({}));
            let newSchedules = [];
            const schedulesFilter = schedulesCalendar.filter((item) => ids !== item.id);
            if (schedulesCalendar.length === 1 && !schedulesFilter.length && !!data.length) {
                newSchedules.push(data[0]);
            } else {
                newSchedules = schedulesFilter;
            }

            handleMapScheduleCalendar(newSchedules);
        }
        refConfirm.current._open();
    };

    const _handleDeleteFail = ({ message }) => {
        dispatchState({
            checkedItems: {
                ids: null
            }
        });
        _showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
    };

    const _handleDeleteFinally = () => {
        dispatchState({ checkedItems: { ids: null }, isEnableDelete: false });
    };

    const _handleUpdateSchedule = () => {
        clientQuery(
            GET_LIST_SCHEDULES_SETTING,
            { data: { schedules: refListSchedule.current }, method: 'PUT' },
            _handleSuccess,
            _handleAddFail,
            _handleFinally
        );
    };

    const _handleSuccess = () => {
        _showStatusBar({ id: LIST_STATUS.SUCCESS, message: t('setting:updated_schedule') });
        dispatchState((prev) => ({ ...prev, data: refListSchedule.current }));
        dispatch(actionGetListSchedule({}));
        handleMapScheduleCalendar(schedulesCalendar);
    };

    const handleMapScheduleCalendar = (schedules) => {
        const newData = [...schedules].map((item) => {
            const { id: itemId, start_point: startPointItem, end_point: endPointItem } = item;
            const dataItem = refListSchedule.current.find((schedule) => schedule.id === itemId);
            if (!dataItem) return item;
            const { name, nickname, assignee, start_point, end_point } = dataItem;
            return {
                ...item,
                name: nickname || name,
                nickname,
                user_id: assignee,
                start_point: start_point || startPointItem,
                end_point: end_point || endPointItem
            };
        });
        dispatch(updateCalendar({ schedules: newData }));
    };

    const _handleFinally = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleChangeInput = (e, item) => {
        refListSchedule.current = [...refListSchedule.current].map((element) => {
            if (element.id === item.id) {
                return {
                    ...element,
                    nickname: e.target.value
                };
            }
            return element;
        });
    };

    const _handleSelectAssign = (newAssignId, item) => {
        refListSchedule.current = [...refListSchedule.current].map((element) => {
            if (element.id === item.id) {
                return {
                    ...element,
                    assignee: newAssignId
                };
            }
            return element;
        });
    };

    const _handleSelectAddress = (name, value, item) => {
        refListSchedule.current = [...refListSchedule.current].map((element) => {
            if (element.id === item.id) {
                const keyPoint = name.replace('_address', '_point');
                const { name: nameAddres } = value;
                return {
                    ...element,
                    [name]: nameAddres,
                    [keyPoint]: { ...value, location: nameAddres }
                };
            }
            return element;
        });
    };

    const _handleActionGroups = (id, value, isDeleteGroup = false) => {
        refListSchedule.current = [...refListSchedule.current].map((element) => {
            if (element.id === id) {
                return {
                    ...element,
                    groups: isDeleteGroup
                        ? [...element.groups].filter((item) => item !== value)
                        : [...element.groups, value]
                };
            }
            return element;
        });
    };

    const _handleOpenConfirm = () => {
        refConfirmDelete.current.open(null, t('setting:confirm_delete_schedule'));
    };

    const _handleCloseConfirm = () => {
        refConfirm.current._close();
    };

    const _handleOpenReAssign = () => {
        if (!schedules.some((schedule) => !!schedule.active_job_counts)) {
            _showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('setting:error_reassgin_schedule'),
                type: LIST_STATUS.ERROR
            });
            return;
        }
        refReAssign.current._showModal();
    };

    function _renderHeaderRight() {
        return <ButtonSave title={t('setting:save')} ref={refButtonSave} onSave={_handleUpdateSchedule} />;
    }

    const _redirectToPlans = () => {
        history.push({
            pathname: addBranchPath(SETTINGS_PLANS),
            state: { isDeleteSchedule: true, message: t('setting:deleted_schedule_success') }
        });
    };

    const _showStatusBar = ({ id = '', message = '', type = LIST_STATUS.SUCCESS }) => {
        refAlert.current?.showStatusBar({ id, message, type });
    };

    return (
        <>
            <MainHeaderSettings contentRight={_renderHeaderRight} tutorial={tutorial} isLoading={isLoading} />
            <div className="wrapper-columns">
                <div className="container-print contents-pages maintables-page setting-schedules">
                    <AlertCustomer ref={refAlert} />
                    <BoxTipsSetting
                        typeId={LIST_TOOLTIP.SCHEDULES}
                        onAddNew={isHaveButton ? _handleMaxSchedule : _handleAddSchedule}
                    />
                    <div className="schedule-upgrade">
                        <div className="schedule-upgrade__label">
                            {scheduleLength} {t('setting:schedules_used')}
                            <Link
                                to={addBranchPath(SETTINGS_PLANS)}
                                className="v2-btn-default has-icon svg-purple --purple ml-2"
                            >
                                <IconUpgrade />
                                <span className="violet-name">{t('setting:upgrade_plan')}</span>
                            </Link>
                        </div>
                        <div onClick={_handleOpenReAssign} className="v2-btn-default --grey has-icon btn-modal">
                            <IconMerge isActive />
                            {t('setting:re_assign_schedule')}
                        </div>
                        <div
                            className="v2-btn-default --grey has-icon mr-0"
                            onClick={isHaveButton ? _handleMaxSchedule : _handleAddSchedule}
                        >
                            <IconPlus /> {t('setting:add_schedule')}
                        </div>
                    </div>
                    <ActionHeader
                        onHandleDeleteSchedule={_handleOpenConfirm}
                        idSelected={idSelected && state.isEnableDelete}
                    />
                    <SettingScheduleProvider startFetch={!isLoading}>
                        <GdGridView
                            isLoading={isLoading}
                            isHasDropdown
                            content={schedules}
                            classTable="tables-visible has-checkbox scrolls-x"
                            fileTranslation={'setting'}
                            checkedItems={state.checkedItems}
                            onChangeStateCheckedOnlyItems={onChangeStateCheckedItems}
                            onChangeInput={_handleChangeInput}
                            onSelectAssign={_handleSelectAssign}
                            onSelectAddress={_handleSelectAddress}
                            onHandleActionGroups={_handleActionGroups}
                            showCheckboxSingle
                            {...getGridColumnsSettingSchedules()}
                            isScroll
                        />
                    </SettingScheduleProvider>
                    {schedules && schedules.length > 0 && (
                        <ReAssignModal
                            ref={refReAssign}
                            listSchedule={schedules}
                            handleReAssign={_handleReAssign}
                            reAssignSuccess={_handleAssignSuccess}
                            reAssignFail={_handleAssignFail}
                            isLoading={isLoading}
                        />
                    )}
                    <ModalLoading ref={refLoading} />
                    <GDModalWarning
                        ref={refConfirm}
                        title={t('setting:title_confirm_delete_schedule')}
                        description={t('setting:notice_confirm_delete_schedule')}
                        footer={
                            <div className="footer-modal">
                                <ButtonSave
                                    title={t('setting:keep_my_current_plan')}
                                    ref={refButtonSave}
                                    onSave={_handleCloseConfirm}
                                />
                                <div className="ml-1 v2-btn-default --transparent" onClick={_redirectToPlans}>
                                    {t('setting:downgrade_my_plan')}
                                </div>
                            </div>
                        }
                    />
                    <GdConfirm
                        ref={refConfirmDelete}
                        title={t('setting:title_confirm_delete_schedule')}
                        listButton={{ confirm: true, cancel: true }}
                        isLargeContent
                        onConfirm={_handleDeleteSchedule}
                    />
                </div>
            </div>
        </>
    );
};

export default SettingsService;
