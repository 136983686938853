import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_MATERIAL_PRESET, ADDONS_MATERIAL_PRESET_EDIT } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { MATERIAL_EDIT_FIELD_NAME } from 'app/modules/jobdetail/tabs/materials/consts';
import { PRESET_COLUMN_TYPES } from '../constants';
import ColumnPreset from './ColumnPreset';

const ListColumns = ({ data }) => {
    return Object.entries(data).map(([key, value]) => {
        if (key === 'id' || key === 'material_id' || key === 'material' || key === 'material_name') return null;
        let type = PRESET_COLUMN_TYPES.CHECKBOX;
        if (Object.hasOwn(value, 'data') && Object.hasOwn(value, 'values')) type = PRESET_COLUMN_TYPES.DOUBLE;
        else if (Object.hasOwn(value, 'data') && typeof value['data'][0] === 'string') type = PRESET_COLUMN_TYPES.VALUE;
        if (key === MATERIAL_EDIT_FIELD_NAME.DILUTION) {
            return (
                <ColumnPreset
                    key={key}
                    {...value}
                    data={value.values}
                    typeValue={key}
                    type={PRESET_COLUMN_TYPES.VALUE}
                />
            );
        }
        return <ColumnPreset key={key} {...value} typeValue={key} type={type} />;
    });
};

const TablePreset = ({ data = {}, materials: materialsProp = [], onSubmit = () => {} }) => {
    const { t } = useTranslation(['common', 'addons']);
    const history = useHistory();
    const materialData = materialsProp || [];

    const [state, dispatchState] = useReducer(reducer, {
        materials: materialData || [],
        activeItem: materialData.find((i) => !!i.isDefaultSelect) || materialData[0] || null
    });
    const { materials, activeItem } = state;
    const refForm = useRef(null);
    const refButtonSave = useRef(null);
    const refStatus = useRef(null);
    const refDropdown = useRef(null);

    const handleSelect = (idSelect) => {
        const activeItem = materials.find((i) => i.id === idSelect);
        if (activeItem?.preset_id)
            history.push(addBranchPath(ADDONS_MATERIAL_PRESET_EDIT.replace(':id', activeItem?.preset_id)));
        dispatchState((prevState) => ({ ...prevState, activeItem }));
    };

    const cbAlert = (...props) => {
        refButtonSave.current?.setLoading(false);
        refStatus.current?.showStatusBar(...props);
    };

    const handleSave = () => {
        if (!refForm.current) return;
        const result = {};
        const doubleCols = ['unit', 'area'];
        Array.from(refForm.current.elements).forEach((element) => {
            if (element.type === 'checkbox' && element.checked) {
                const matches = element.name.match(/\[(.*?)\]\[(.*?)\]/);
                if (matches) {
                    // eslint-disable-next-line no-unused-vars
                    const [_, key, value] = matches;
                    if (doubleCols.includes(key)) {
                        if (!result[key]) {
                            result[key] = { data: [value] };
                        } else if (!result[key]['data']) {
                            result[key]['data'] = [value];
                        } else {
                            result[key]['data'].push(value);
                        }
                    } else {
                        if (!result[key]) result[key] = [value];
                        else result[key].push(value);
                    }
                }
            }
            if (element.type === 'number' || element.type === 'text') {
                const inputName = element.name;
                if (!result[inputName]) result[inputName] = { values: [element.value] };
                else result[inputName]['values'].push(element.value);
            }
        });
        if (refStatus.current) refStatus.current.clearAllStatusBar();
        onSubmit({ ...result, material_id: activeItem.id }, cbAlert);
    };

    return (
        <>
            <GDStatusBar ref={refStatus} />
            <div className="scrolls">
                <form ref={refForm} className="boxs-wrapper h-100" onSubmit={(e) => e.preventDefault()}>
                    <div className="boxs-wrapper__preset h-100">
                        <div className="preset-header flex-column gap-4">
                            <p className="fw-500">{t('addons:materials')}</p>
                            <CalendarDropdown
                                ref={refDropdown}
                                options={materials}
                                keyGetValue="id"
                                keyGetKey="id"
                                selectedOption={activeItem}
                                selected={activeItem?.name}
                                onSelect={handleSelect}
                            />
                        </div>
                        {!Object.keys(data).length ? (
                            <GridEmpty isFlat />
                        ) : (
                            <>
                                <div className="preset-container flextop">
                                    <ListColumns data={data} />
                                </div>
                                <div className="preset-footer flexcenter justify-end gap-8">
                                    <Link
                                        to={addBranchPath(ADDONS_MATERIAL_PRESET)}
                                        className="v2-btn-default has-icon --grey"
                                    >
                                        {t('common:cancel')}
                                    </Link>
                                    <ButtonSave ref={refButtonSave} text={t('common:save')} onSave={handleSave} />
                                </div>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default TablePreset;
