import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDInputNumber from 'app/components/input/InputNumber';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { KEY_CODE_ENTER } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { MATERIAL_EDIT_FIELD_NAME } from 'app/modules/jobdetail/tabs/materials/consts';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import IconPlus from 'assets/icon/IconPlus';
import IconTrash from 'assets/icon/IconTrash';

const RowValue = ({ id, value = '', typeValue, onDelete = () => {} }) => {
    return (
        <div className="flexcenter gap-4">
            <p className="flex-1 txt-ellipsis cursor">{value}</p>
            <input
                type={typeValue === MATERIAL_EDIT_FIELD_NAME.DILUTION ? 'text' : 'number'}
                name={typeValue}
                value={value}
                hidden
                readOnly
            />
            <button className="v2-btn-default --icon-lg has-bg-red btn-modal" onClick={() => onDelete(id)}>
                <IconTrash />
            </button>
        </div>
    );
};

const RowAddValue = ({ id, typeValue, placeholder = '', onSubmit = () => {}, onDelete = () => {} }) => {
    const refInput = useRef(null);
    const refButton = useRef(null);
    const handleChange = (e) => {
        refButton.current.classList.toggle('is-disable', !e.target.value.trim());
    };
    const handleSubmit = () => {
        onSubmit(refInput.current.value.trim());
    };
    return (
        <div className="unit-items flexcenter gap-4 relative">
            {typeValue === MATERIAL_EDIT_FIELD_NAME.DILUTION ? (
                <input
                    ref={refInput}
                    type="text"
                    autoFocus
                    defaultValue=""
                    placeholder={placeholder}
                    className="field-input no-effect border-none"
                    onChange={handleChange}
                    onKeyDown={(e) => e.keyCode === KEY_CODE_ENTER && handleSubmit()}
                />
            ) : (
                <GDInputNumber
                    ref={refInput}
                    type="number"
                    className="field-input no-effect border-none"
                    autoFocus
                    defaultValue=""
                    placeholder={placeholder}
                    onChangImmediately={handleChange}
                    onEnter={handleSubmit}
                />
            )}
            <div className="unit-btn flexcenter gap-2">
                <button
                    ref={refButton}
                    className="v2-btn-main --icon-sm svg-white-stroke is-disable"
                    onClick={handleSubmit}
                >
                    <IconCheck />
                </button>
                <button className="v2-btn-default --icon-sm" onClick={() => onDelete(id)}>
                    <IconClose />
                </button>
            </div>
        </div>
    );
};

const ValueColPreset = ({ name = '', data = [], typeValue }) => {
    const { t } = useTranslation(['common', 'addons']);
    const [state, dispatchState] = useReducer(reducer, {
        rowsAddValue: [],
        rowsValue: data.map((item, idx) => ({ value: item, id: item + idx }))
    });
    const { rowsAddValue = [], rowsValue = [] } = state;
    const refConfirm = useRef(null);
    const namePlaceholder =
        typeValue === MATERIAL_EDIT_FIELD_NAME.DILUTION ? name : t('addons:material_value_placeholder', { name });

    const handleSubmitAdd = (value) => {
        if (!value) return;
        dispatchState((prevState) => ({
            ...prevState,
            rowsValue: [{ value, id: new Date().getTime() }, ...prevState.rowsValue],
            rowsAddValue: []
        }));
    };

    const handleAdd = () => {
        if (rowsAddValue.length) return;
        dispatchState((prevState) => ({
            ...prevState,
            rowsAddValue: [...prevState.rowsAddValue, { id: new Date().getTime() }]
        }));
    };

    const handleDelete = (id) => {
        dispatchState((prevState) => ({
            ...prevState,
            rowsAddValue: prevState.rowsAddValue.filter((row) => row.id !== id)
        }));
    };

    const handleConfirm = (id, isRowAdd = false) => {
        refConfirm.current?._open({ dataConfirm: { id, isRowAdd } });
    };

    const handleConfirmDelete = (id, dataConfirm = {}) => {
        refConfirm.current?._close();
        dispatchState((prevState) => ({
            ...prevState,
            rowsValue: prevState.rowsValue.filter((row) => row.id !== dataConfirm.id)
        }));
    };

    return (
        <>
            <div className="preset-column flex-column h-100 flex-1">
                <div className="preset-column__header flexcenter gap-4">
                    <p className="fw-500 txt-ellipsis flex-1">{namePlaceholder}</p>
                    <div className="v2-btn-default --icon-lg" onClick={handleAdd}>
                        <IconPlus />
                    </div>
                </div>
                <div className="preset-column__content flex-1 flex-column gap-8 mt-1">
                    {rowsAddValue.length
                        ? rowsAddValue.map((row) => (
                              <RowAddValue
                                  {...row}
                                  key={row.id}
                                  typeValue={typeValue}
                                  placeholder={namePlaceholder}
                                  onSubmit={handleSubmitAdd}
                                  onDelete={handleDelete}
                              />
                          ))
                        : null}
                    {rowsValue.length
                        ? rowsValue.map((row, idx) => (
                              <RowValue key={row + idx} {...row} typeValue={typeValue} onDelete={handleConfirm} />
                          ))
                        : null}
                </div>
            </div>
            <GDModalWarning
                buttonConfirm
                ref={refConfirm}
                title={t('common:notification')}
                description={t('customers:delete_custom_field_confirm')}
                modalClassName="modal container-modal modal-confirm-delete open"
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};
export default ValueColPreset;
