import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import { ADDONS_MATERIAL_PRESET_EDIT } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import GDModalWarning from 'app/components/modal/ModalWarning';

const TagsList = ({ items = [] }) => {
    if (!items.length) return null;
    return (
        <div className="flexcenter flex-wrap gap-4">
            {items.map((item) => (
                <div key={item.id} className="tag-label --large">
                    {item.value}
                </div>
            ))}
        </div>
    );
};
const getCombineString = (data = [], value = '', keyGet = 'value') =>
    data.map((unit) => `${value}${keyGet ? unit[keyGet] : unit}`).join(', ');

const ValueUnits = ({ dataValue = [], dataUnit = [], keyGet = 'value' }) => {
    if (!dataUnit.length || !dataValue.length) return null;
    const string = getCombineString(dataUnit, dataValue[0], keyGet);
    return <div className="col txt-ellipsis">{string}</div>;
};

const PresetItem = ({ item, onDelete }) => {
    const { t } = useTranslation('addons');
    const refDropdown = useRef(null);
    const handleAction = (callback) => {
        refDropdown.current?._closeDropdown();
        callback(item);
    };

    return (
        <div className="preset-items flex-column gap-8">
            <div className="flexcenter gap-10">
                <div className="col-material flex-1 fw-700 txt-ellipsis">{item?.material_name}</div>
                {item.units?.active ? (
                    <ValueUnits dataValue={item.units.values || []} dataUnit={item.units.data} />
                ) : null}
                {item.areas?.active ? (
                    <ValueUnits dataValue={item.areas.values || []} dataUnit={item.areas.data} />
                ) : null}
                {item.dilutions?.active ? (
                    <div className="col txt-ellipsis">{getCombineString(item.dilutions.values, '', '')}</div>
                ) : null}
                {item.custom_materials?.active ? (
                    <div className="col txt-ellipsis">{item.custom_materials.data.map((i) => i.value).join(', ')}</div>
                ) : null}
                <CalendarDropdown
                    ref={refDropdown}
                    wrapperClassName="v2-dropdown"
                    buttonClassName="dropbtn v2-btn-default --icon-lg"
                    wrapperListClass="v2-dropdown__menu --right content-full"
                    customDropButton={IconThreeDots}
                >
                    <ul>
                        <Link to={addBranchPath(ADDONS_MATERIAL_PRESET_EDIT.replace(':id', item.id))}>
                            <li className="items has-icon">
                                <IconEdit />
                                <p className="txt-ellipsis">{t('addons:edit_material_preset')}</p>
                            </li>
                        </Link>
                        <li className="items has-icon red-default btn-modal" onClick={() => handleAction(onDelete)}>
                            <IconTrash />
                            <p className="txt-ellipsis">{t('addons:delete_material_preset')}</p>
                        </li>
                    </ul>
                </CalendarDropdown>
            </div>
            {item.locations.active ? <TagsList items={item.locations.data} /> : null}
            {item.targets.active ? <TagsList items={item.targets.data} /> : null}
        </div>
    );
};

const ListMaterial = ({ data = [], onDelete = () => {} }) => {
    const { t } = useTranslation('addons');
    const refConfirm = useRef(null);

    const handleDelete = (item) => refConfirm.current?._open({ dataConfirm: item });
    const handleConfirm = (_, item) => {
        refConfirm.current?._close();
        onDelete(item);
    };

    if (!data.length) return <GridEmpty />;
    return (
        <>
            <div className="preset-list">
                {data.map((item) => (
                    <PresetItem key={item.id} item={item || {}} onDelete={() => handleDelete(item)} />
                ))}
            </div>
            <GDModalWarning
                ref={refConfirm}
                buttonConfirm
                title={t('common:notification')}
                description={t('customers:delete_custom_field_confirm')}
                modalClassName="modal container-modal modal-confirm-delete open"
                onConfirm={handleConfirm}
            />
        </>
    );
};

export default ListMaterial;
