import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { SETTINGS_EMAIL_INBOX, SETTINGS_TEMPLATES_SMS } from 'app/config/routes';
import { GET_TEMPLATES_SMS } from 'app/const/api/V2';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { SETTING_TEMPLATE_TYPES } from 'app/modules/settings/emailinbox/const';
import { DEFAULT_SEND_BULK_EMAIL_OPTIONS, SMART_VIEW_BULK_TYPES } from 'app/modules/smartview/constants';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCogWheel from 'assets/icon/IconCogWheel';
import IconLoading from 'assets/icon/IconLoading';
import { getListTemplateEmailInbox } from 'common/redux/actions/settings/templateAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';

export const convertData = (item) => {
    if (!item) return null;
    return { id: item.id, value: item.id, label: item.name, phone: item.phone || '' };
};

const RowWithDropdown = ({
    title = '',
    defaultSelect = null,
    options: defaultOptions,
    typeBulk = SMART_VIEW_BULK_TYPES.EMAIL,
    isDeactivate = false,
    isTemplate = false,
    isPhoneSelect = false,
    onSelect = () => {}
}) => {
    const dispatch = useDispatch();
    const LIMIT = 20;

    const refDropdown = useRef(null);
    const refOpened = useRef(false);
    const { isFirstTime, data: dataTemplate } = useSelector(
        ({ templates }) => templates[SETTING_TEMPLATE_TYPES.EMAIL_INBOX]
    );

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: isFirstTime && isTemplate,
        selected: !!!defaultSelect
            ? DEFAULT_SEND_BULK_EMAIL_OPTIONS
            : defaultSelect || convertData(dataTemplate) || defaultOptions?.[0] || {},
        options: defaultOptions || dataTemplate?.map((item) => convertData(item)) || [],
        offset: 0,
        total: 0,
        hasMore: true,
        isLoadingMore: false
    });
    const { options, selected, isLoading, offset, hasMore, isLoadingMore } = state;

    useEffect(() => {
        if (isPhoneSelect) {
            dispatchState((prevState) => ({
                ...prevState,
                selected: defaultSelect || DEFAULT_SEND_BULK_EMAIL_OPTIONS,
                options: defaultOptions || []
            }));
        }
    }, [defaultSelect]);

    const _handleGetSuccess = ({ data, total }) => {
        dispatchState((prevState) => ({
            ...prevState,
            isLoading: false,
            options: data.map((item) => convertData(item)),
            hasMore: total > prevState.options.length + data.length
        }));
    };

    const _handleLoadMoreSuccess = ({ data }) => {
        dispatchState((prevState) => ({
            ...prevState,
            isLoadingMore: false,
            options: [...prevState.options, ...data.map((item) => convertData(item))],
            hasMore: prevState.total > prevState.options.length + data.length
        }));
    };

    const _handleVisible = () => {
        if (typeBulk === SMART_VIEW_BULK_TYPES.SMS) {
            if (isTemplate && !refOpened.current) {
                refOpened.current = true;
                dispatchState((prevState) => ({ ...prevState, isLoading: true }));
                clientQuery(
                    GET_TEMPLATES_SMS,
                    { method: 'GET', data: { type: 6, limit: LIMIT, total: 1 } },
                    _handleGetSuccess
                );
            }
        } else {
            if (isFirstTime && isTemplate) dispatch(getListTemplateEmailInbox({ limit: LIMIT }, _handleGetSuccess));
            if (!isFirstTime && isTemplate) {
                dispatchState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    options: dataTemplate?.map((item) => convertData(item)) || []
                }));
            }
        }
    };

    const _loadMore = () => {
        if (!isTemplate || !hasMore || isLoadingMore) return;
        dispatchState((prevState) => ({ ...prevState, offset: prevState.offset + LIMIT, isLoadingMore: true }));
        clientQuery(
            GET_TEMPLATES_SMS,
            { method: 'GET', data: { type: 6, offset: offset + LIMIT, limit: LIMIT } },
            _handleLoadMoreSuccess
        );
    };

    const _handleSelect = (id) => {
        dispatchState((prevState) => {
            const selected = prevState.options.find((item) => item.id === id);
            onSelect(selected);
            return { ...prevState, selected };
        });
        refDropdown.current?._close();
    };

    const handleScroll = (e) => {
        if (isScrollToEndBottom(e.target) && typeBulk === SMART_VIEW_BULK_TYPES.SMS) _loadMore();
    };

    return (
        <div className={classNames('rows mt-0', { 'is-disable': isDeactivate })}>
            {title ? <div className="txt">{title}</div> : null}
            <DropdownPopper
                id={title}
                ref={refDropdown}
                isCalculateWidth
                isUseToggle
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn items method-select"
                customButton={
                    <ButtonCustom
                        isPhoneSelect={isPhoneSelect}
                        objSelected={selected}
                        selected={selected?.label || ''}
                    />
                }
                onScroll={handleScroll}
                onOpen={_handleVisible}
                onSelect={_handleSelect}
            >
                <ListData
                    data={options}
                    selected={selected}
                    isLoading={isLoading}
                    isPhoneSelect={isPhoneSelect}
                    isTemplate={isTemplate}
                    typeBulk={typeBulk}
                    onSelect={_handleSelect}
                    onLoadMore={_loadMore}
                    isLoadingMore={isLoadingMore}
                    hasMore={hasMore}
                />
            </DropdownPopper>
        </div>
    );
};

const ListData = ({
    data = [],
    selected = {},
    isLoading = true,
    isPhoneSelect = false,
    isTemplate = false,
    typeBulk = SMART_VIEW_BULK_TYPES.EMAIL,
    onSelect = () => {},

    isLoadingMore = false
}) => {
    const { t } = useTranslation('smartView');
    const isEmailBulk = typeBulk === SMART_VIEW_BULK_TYPES.EMAIL;
    const listRef = useRef(null);

    if (isLoading) {
        return (
            <div className="items justify-center">
                <div className="loading -ajaxbar">
                    <IconLoading />
                </div>
            </div>
        );
    }
    return (
        <ul ref={listRef}>
            {data.map((item) => (
                <li
                    key={item.id}
                    className={classNames('items', { active: selected.id === item.id })}
                    onClick={() => onSelect(item.id)}
                >
                    <span className={classNames('txt-ellipsis', { 'fw-500': isPhoneSelect })}>{item.label}</span>
                    {isPhoneSelect ? <span className="ml-1">{item.phone}</span> : null}
                </li>
            ))}
            {isLoadingMore && (
                <li className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </li>
            )}
            {isTemplate && !data.length ? (
                <Link to={addBranchPath(isEmailBulk ? SETTINGS_EMAIL_INBOX : SETTINGS_TEMPLATES_SMS)} className="items">
                    <div className="mx-1">
                        <IconCogWheel />
                    </div>
                    <span className="txt-ellipsis">
                        {t('manage_templates', { name: typeBulk === SMART_VIEW_BULK_TYPES.EMAIL ? 'Email' : 'SMS' })}
                    </span>
                </Link>
            ) : null}
        </ul>
    );
};

const ButtonCustom = ({ isPhoneSelect = false, objSelected = {}, selected = '' }) => {
    return (
        <>
            <span className="txt-ellipsis">
                {isPhoneSelect ? objSelected.label + ' ' + objSelected?.phone : selected}
            </span>
            <IconArrowDown />
        </>
    );
};

export default RowWithDropdown;
