import moment from 'moment';
import { call } from 'redux-saga/effects';

import {
    LIST_ITEM_MATERIAL,
    REPORT_MATERIAL_USE_GET_LIST,
    REPORT_MATERIAL_USE_GET_LIST_JOBS_WO_COUNTY,
    REPORT_MATERIAL_USE_W_O_COUNTY
} from 'app/const/Api';
import {
    COMMON,
    DATE_FORMAT,
    END_DATE_OF_MONTH,
    KEY_REPORT_LOCAL_STORAGE,
    MATERIAL_NY_PRL,
    MATERIAL_USE_SELECT_OPTIONS,
    START_DATE_OF_MONTH
} from 'app/const/App';
import { REPORT_TYPE } from 'app/const/Reports';
import i18n from 'assets/i18n';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';

export function* getListReportMaterialUse({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_MATERIAL_USE_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCountyMaterialUseFilter({ params = {}, actionSuccess, actionFailed }) {
    try {
        const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.MATERIAL_USE);
        const paramsReport = getLocalStorage(keyLocalStorage);
        const response = yield call(fetchWithToken, REPORT_MATERIAL_USE_GET_LIST_JOBS_WO_COUNTY, {
            ...params,
            ...(params?.state || { state: paramsReport[COMMON.STATE] }),
            start: paramsReport[COMMON.START] || moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: paramsReport[COMMON.END] || moment(END_DATE_OF_MONTH).format(DATE_FORMAT)
        });
        if (response && response.success) {
            response.data.unshift({ id: 'all-by-county', name: i18n.t('report:all_by_county_usa') });
            response.data.push(
                { id: MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED, name: i18n.t('report:all_detailed') },
                { id: MATERIAL_USE_SELECT_OPTIONS.NY_PRL, name: MATERIAL_NY_PRL }
            );
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCompletedJobWoCounty({ params, actionSuccess, actionFailed }) {
    try {
        const responseCompletedJob = yield call(fetchWithToken, REPORT_MATERIAL_USE_W_O_COUNTY, {
            clients: params?.clients,
            materials: params?.materials,
            state: params?.[COMMON.STATE],
            start: params?.[COMMON.START],
            end: params?.[COMMON.END]
        });
        const responseMaterial = yield call(fetchWithToken, REPORT_MATERIAL_USE_GET_LIST, params);

        const response = {
            completedJob: responseCompletedJob?.data?.map((item) => {
                const listName = [item.date];
                const listService = [item.service_name];
                const listApplication = [''];
                item.material.forEach((value) => {
                    listName.push(value.name);
                    listService.push(`${value.qty || ''} ${value.unit_name || ''}`);
                    listApplication.push(value.applications);
                });
                return {
                    ...item,
                    list_material_name: listName,
                    service_name: listService,
                    list_application: listApplication
                };
            }),
            material: responseMaterial?.data.map((item) => {
                return { ...item, qty: `${item.qty || ''} ${item.unit_name || ''}` };
            }),
            success: responseCompletedJob.success && responseMaterial.success
        };
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportMaterialSimplify({ params = {}, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, LIST_ITEM_MATERIAL, { limit: 0, inc: 'materials', ...params });
        if (response && response.success) {
            actionSuccess({ data: response.data?.materials || [] });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
