import loadable from '@loadable/component';
import { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { COMMON } from 'app/const/App';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { PAYMENT_METHODS } from 'app/const/setting/SettingPageName';
import { getTabParams } from 'app/const/setting/SettingParams';
import { LIST_BUTTONS, LIST_BUTTON_MESSAGES, TAB, getColumns } from 'app/const/setting/SettingPaymentMethods';
import { mixpanelAddPaymentMethod } from 'app/modules/mixpanel/MixpanelAddPaymentMethod';
import { addItemToListStatus } from 'common/redux/actions/common/statusbarAction';
import {
    createPaymentMethod,
    getListSettingPaymentMethods,
    updatePaymentMethod,
    updateStatusSettingPaymentMethods
} from 'common/redux/actions/settings/paymentMethodsAction';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import {
    getLocalStorageSettingPage,
    removeLocalStorageSettingPage,
    updateLocalStorageSettingPage
} from '../utils/localStorage';
import { getNewParamsOrderStatus } from '../utils/statusOrder';

const GdButton = loadable(() => import('app/components/button'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ListStatusBar = loadable(() => import('app/components/status/ListStatusBar'));
const ListButtonAction = loadable(() => import('app/modules/settings/components/ListButtonAction'));
const SettingTabsFilter = loadable(() => import('app/modules/settings/components/SettingTabsFilter'));
const SettingPagination = loadable(() => import('app/modules/settings/components/SettingPagination'));
const PaymentMethodsForm = loadable(() => import('app/modules/settings/paymentmethods/components/PaymentMethodsForm'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function SettingsPaymentMethods() {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        checkedItems: {
            is_check_all: false,
            ids: []
        },
        listTab: TAB.LIST,
        currentTab: getLocalStorageSettingPage({
            namePage: PAYMENT_METHODS,
            key: COMMON.CURRENT_TAB,
            defaultCurrentTab: TAB.DEFAULT_SELECTED
        }),
        total: 1,
        params: getLocalStorageSettingPage({ namePage: PAYMENT_METHODS, key: COMMON.PARAMS }),
        tutorial: {}
    });

    const refModal = useRef(null);
    const refConfirm = useRef(null);
    const refCreatePaymentMethod = useRef(true);

    useEffect(() => {
        _getListPaymentMethod(state.params);
        return () => {
            removeLocalStorageSettingPage({ namePage: PAYMENT_METHODS });
        };
    }, []);

    // Callback after call API
    const _getListPaymentMethodSuccess = ({ data, total, tutorial }) => {
        dispatchState({
            ...state,
            isLoading: false,
            data,
            currentTab: getLocalStorageSettingPage({ namePage: PAYMENT_METHODS, key: COMMON.CURRENT_TAB }),
            checkedItems: { is_check_all: false, ids: [] },
            total,
            params: getLocalStorageSettingPage({ namePage: PAYMENT_METHODS, key: COMMON.PARAMS }),
            tutorial
        });
    };

    const _getListPaymentMethodFailure = (err) => {
        dispatchState({ ...state, isLoading: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _createPaymentMethodSuccess = (response) => {
        handleTrackingEvent(mixpanelAddPaymentMethod({ id: profileId }));
        refModal.current.hideModal();
        dispatch(
            addItemToListStatus({
                message: response.message || t('setting:create_method_success'),
                type: COMMON.SUCCESS
            })
        );
        _getListPaymentMethod(state.params);
    };

    const _createPaymentMethodFailure = ({ message }) => {
        refModal.current.hideModal();
        dispatchState({ ...state, isLoading: false });
        dispatch(addItemToListStatus({ message: message || t('setting:create_method_failure'), type: COMMON.ERROR }));
    };

    const _updateData = (arrayData, dataUpdated) => {
        const result = [...arrayData];
        for (var i in result) {
            if (result[i].id === dataUpdated.id) {
                result[i].name = dataUpdated.name;
                break; //Stop this loop, we found it!
            }
        }
        return result;
    };

    const _updatePaymentMethodSuccess = (response) => {
        refModal.current.hideModal();
        const methodUpdated = response.data;
        const newDataPaymentMethods = _updateData(state.data, methodUpdated);
        dispatchState({ ...state, isLoading: false, data: newDataPaymentMethods });
        dispatch(
            addItemToListStatus({ message: response.message || t('setting:edit_method_success'), type: COMMON.SUCCESS })
        );
    };

    const _updatePaymentMethodFailure = ({ message }) => {
        refModal.current.hideModal();
        dispatchState({ ...state, isLoading: false });
        dispatch(addItemToListStatus({ message: message || t('setting:edit_method_failure'), type: COMMON.ERROR }));
    };

    const _checkIsInLastPage = ({ total, offset, limit }) => {
        if (offset + limit >= total) return true;
        return false;
    };

    const _checkIsInFirstPage = ({ total, limit }) => {
        if (limit >= total) return true;
        return false;
    };

    const _updateStatusPaymentMethodsSuccess = (response) => {
        refModal.current.hideModal();
        dispatch(addItemToListStatus({ message: response.message, type: COMMON.SUCCESS }));
        const params = getLocalStorageSettingPage({ namePage: PAYMENT_METHODS, key: COMMON.PARAMS });
        const isLastPage = _checkIsInLastPage({ total: state.total, offset: params.offset, limit: params.limit });
        //Process went action in last page,
        if (isLastPage) {
            const isRemoveAllItemInPage = response.listIdChecked.length === state.data.length;
            const isInFirstPage = _checkIsInFirstPage({ total: state.total, limit: params.limit });
            if (isRemoveAllItemInPage && !isInFirstPage) {
                const newParams = params;
                newParams.offset = params.offset - params.limit;
                _getListPaymentMethod(newParams, state.currentTab, false);
            }
        }
        if (!isLastPage) _getListPaymentMethod(params, state.currentTab, false);
    };

    const _updateStatusPaymentMethodsFailure = (response) => {
        dispatch(addItemToListStatus({ message: response.message, type: COMMON.ERROR }));
        dispatchState({ ...state, isLoading: false });
    };

    // Dispatch call API
    const _getListPaymentMethod = (params, tabId = state.currentTab, isLoading = true) => {
        updateLocalStorageSettingPage({
            namePage: PAYMENT_METHODS,
            value: { params: params, current_tab: tabId }
        });
        dispatchState({ isLoading: isLoading, params: params, currentTab: tabId });
        dispatch(getListSettingPaymentMethods(params, _getListPaymentMethodSuccess, _getListPaymentMethodFailure));
    };

    const _handleSaveOnModal = (params) => {
        if (params.id) {
            dispatch(updatePaymentMethod(params, _updatePaymentMethodSuccess, _updatePaymentMethodFailure));
        } else {
            dispatch(createPaymentMethod(params, _createPaymentMethodSuccess, _createPaymentMethodFailure));
        }
    };

    const _handleChangeTab = (tabId) => {
        const newTabParams = getTabParams(tabId);
        const newParams = { ...state.params, ...newTabParams, offset: 0 };
        _getListPaymentMethod(newParams, tabId);
    };

    const _handleChangePage = (newParams) => {
        _getListPaymentMethod(newParams);
    };

    const _onConfirmUpdateStatusPaymentMethods = (params) => {
        const methodsUpdated = params.ids;
        const newDataMethods = state.data.filter((method) => {
            if (methodsUpdated.includes(method.id)) return false;
            return true;
        });
        dispatchState({
            ...state,
            data: newDataMethods,
            checkedItems: {
                is_check_all: false,
                ids: []
            }
        });
        dispatch(
            updateStatusSettingPaymentMethods(
                params,
                _updateStatusPaymentMethodsSuccess,
                _updateStatusPaymentMethodsFailure
            )
        );
    };

    const _handleClickButtonAction = (value) => {
        const listIdChecked = state.checkedItems.ids;
        if (listIdChecked.length) {
            refConfirm.current.open({ ids: listIdChecked, type: value }, t(`common:${LIST_BUTTON_MESSAGES[value]}`));
        }
    };

    const _onClickHeaderCallBack = (action) => {
        const newParams = getNewParamsOrderStatus({ params: state.params, actionEvent: action });
        _getListPaymentMethod(newParams);
    };

    // Dispatch action

    const _handleEditPaymentMethods = (paymentMethodsItem) => {
        const methodSelected = { ...state.data.find((method) => method.id === paymentMethodsItem.row.id) };
        refModal.current.showModal(methodSelected);
    };

    const _handleAddItem = () => {
        refModal.current.showModal();
        refCreatePaymentMethod.current = true;
    };

    const _onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState({ ...state, checkedItems: stateCheckedItems });
    };

    const _renderHeaderRight = () => {
        return (
            <div className="header-items modal-setting w-button">
                <GdButton
                    className="v2-btn-main has-icon svg-white"
                    title={t('setting:add_method')}
                    iconSvg={<IconPlus />}
                    onClick={_handleAddItem}
                />
                <PaymentMethodsForm ref={refModal} handleSave={_handleSaveOnModal} />
            </div>
        );
    };

    const _renderHeaderLeft = () => {
        return <SettingTabsFilter list={state.listTab} onChange={_handleChangeTab} tabActive={state.currentTab} />;
    };

    const _renderActionHeader = () => {
        return (
            <div className="header --filter">
                <CheckBoxHeader checkedItems={state.checkedItems} />
                <ListButtonAction
                    list={LIST_BUTTONS[state.currentTab]}
                    fileTranslation="report"
                    onSubmit={_handleClickButtonAction}
                    disabled={!state.checkedItems.ids.length}
                />
            </div>
        );
    };

    return (
        <>
            <MainHeaderSettings
                contentRight={_renderHeaderRight}
                contentLeft={_renderHeaderLeft}
                tutorial={state.tutorial}
            />
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages maintables-page">
                    <ListStatusBar />
                    {_renderActionHeader()}
                    <div className="tab-contents box-auto has-footer">
                        <div className="tab-conts tab-content-active">
                            <GdGridView
                                isLoading={state.isLoading}
                                classTable="scrolls-x has-checkbox"
                                content={state.data}
                                showCheckBox
                                shouldCheckDefaultRow
                                fileTranslation={'setting'}
                                handleClick={_handleEditPaymentMethods}
                                checkedItems={state.checkedItems}
                                onChangeStateCheckedItems={_onChangeStateCheckedItems}
                                handleClickHeader={(event) => {
                                    _onClickHeaderCallBack(event);
                                }}
                                {...getColumns({
                                    actionStatus: state.params?.order,
                                    currentTab: state.currentTab
                                })}
                                isScroll
                            />
                        </div>
                    </div>
                    <SettingPagination
                        totalItem={state.total}
                        namePage={PAYMENT_METHODS}
                        onSelect={_handleChangePage}
                    />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:confirm')}
                        message={t('setting:are_you_sure_delete_this_item')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_onConfirmUpdateStatusPaymentMethods}
                        onCancel={() => {}}
                    />
                </div>
            </div>
        </>
    );
}

export default SettingsPaymentMethods;
