import { useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import GDTutorial from 'app/components/tutorial';
import { addPrefixPath } from 'app/const/Branch';
import {
    SETTING_DROPDOWN_LEFT_OPTIONS,
    SETTING_DROPDOWN_RIGHT_OPTIONS,
    SETTING_MENU_OPTIONS
} from 'app/const/StaticLinks';
import DropdownMenu from 'app/modules/report/components/DropdownMenu';
import IconSideBar from 'assets/icon/IconSideBar';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { getPermissionSetings } from 'common/utils/PermissionUtils';
import { handleToggleSideBar } from 'common/utils/ReportUtils';

const MainHeaderSettings = ({
    isShowTutorial = true,
    tutorial = {},
    isLoading = false,
    contentLeft = () => {},
    contentRight = () => {}
}) => {
    const location = useLocation();
    const [toggle, setToggle] = useState(getToggleLocal('toggledSettings'));

    const title =
        SETTING_MENU_OPTIONS.find((item) =>
            matchPath(location.pathname, {
                path: item.subsLink?.map((pathSub) => addPrefixPath(pathSub)) || addPrefixPath(item.link),
                exact: true
            })
        )?.value || '';

    const permissions = useSelector(({ auth }) => auth.user.permissions.enabled || []);

    const _toggleSideBar = () => {
        setToggle(!toggle);
        setLocalStorage('toggledSettings', !toggle);
        handleToggleSideBar(!toggle);
    };

    return (
        <div className="header --main">
            <div className="header__left flex-1">
                <div
                    className="header-items btn-bg-grey v2-btn-default --icon-lg --transparent"
                    onClick={_toggleSideBar}
                >
                    <IconSideBar isActive={toggle} />
                </div>
                <DropdownMenu
                    leftOptions={SETTING_DROPDOWN_LEFT_OPTIONS}
                    rightOptions={SETTING_DROPDOWN_RIGHT_OPTIONS}
                    title={title}
                    fileTranslation="setting"
                    permissions={getPermissionSetings(permissions)}
                />
                {contentLeft()}
            </div>
            {contentRight()}
            {isShowTutorial && <GDTutorial tutorial={tutorial} isLoading={isLoading} />}
        </div>
    );
};
export default MainHeaderSettings;
