import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { COMMON, LIST_STATUS } from 'app/const/App';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { getColumns, LIST_BUTTON_MESSAGES, LIST_BUTTONS } from 'app/const/setting/SettingBroadcast';
import { BROADCAST } from 'app/const/setting/SettingPageName';
import { LIST_TOOLTIP, TYPE_TEMPLATE, TYPE_TEMPLATE_ID } from 'app/const/Settings';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { addItemToListStatus } from 'common/redux/actions/common/statusbarAction';
import {
    getListSettingBroadcastTemplate,
    updateStatusSettingBroadcast
} from 'common/redux/actions/settings/broadcastAction';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import SettingsTemplate from '../templates';
import { getLocalStorageSettingPage } from '../utils/localStorage';

const GdConfirm = loadable(() => import('app/components/confirm'));
const HeaderBroadCast = loadable(() => import('./components/HeaderBroadCast'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ModalLoading = loadable(() => import('app/components/loading/ModalLoading'));
const DropDownFromEmail = loadable(() => import('app/modules/settings/components/DropDownFromEmail'));
const ListButtonAction = loadable(() => import('app/modules/settings/components/ListButtonAction'));
const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function SettingsBroadcast() {
    const { PARAMS, SUCCESS, ERROR } = COMMON;

    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isActive: false,
        isLoading: true,
        checkedItems: {
            is_check_all: false,
            ids: []
        },
        params: getLocalStorageSettingPage({ namePage: BROADCAST, key: PARAMS }),
        isInitFetchApi: true,
        tutorial: {}
    });
    const { data, tutorial, isLoading } = state;
    const refConfirm = useRef(null);
    const refAlert = useRef(null);
    const refLoading = useRef(null);
    const refTemplate = useRef(null);

    const checkedItems = state.checkedItems;

    useEffect(() => {
        _getListTemplate(state.params);
    }, []);

    // Callback after call API
    const _getListTemplateSuccess = ({ data, tutorial }) => {
        dispatchState({
            ...state,
            isLoading: false,
            data,
            checkedItems: { is_check_all: false, ids: [] },
            isInitFetchApi: false,
            tutorial
        });
    };

    const _getListTemplateFailure = (err) => {
        dispatchState({ ...state, isLoading: false, isInitFetchApi: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _deleteTemplateSuccess = (response) => {
        dispatch(addItemToListStatus({ message: response.message, type: SUCCESS }));
        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: response.message,
            type: LIST_STATUS.SUCCESS
        });
    };

    const _deleteTemplateFailure = (response) => {
        dispatch(addItemToListStatus({ message: response.message, type: ERROR }));
        dispatchState({ ...state, isLoading: false });
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message: response.message,
            type: LIST_STATUS.ERROR
        });
    };

    const _deleteTemplateFinally = () => refLoading.current._close();

    // Dispatch call API
    const _getListTemplate = (params, isLoading = true) => {
        !state.isInitFetchApi && dispatchState({ isLoading: isLoading, params: params });
        dispatch(getListSettingBroadcastTemplate(params, _getListTemplateSuccess, _getListTemplateFailure));
    };

    const _onConfirmDeleteTemplate = (params) => {
        refLoading.current._open();
        const templatesUpdated = params.ids;
        const newDataTemplate = state.data.filter((template) => {
            if (templatesUpdated.includes(template.id)) return false;
            return true;
        });
        dispatchState({
            ...state,
            data: newDataTemplate,
            checkedItems: {
                is_check_all: false,
                ids: []
            }
        });
        dispatch(
            updateStatusSettingBroadcast(
                { ids: templatesUpdated },
                _deleteTemplateSuccess,
                _deleteTemplateFailure,
                _deleteTemplateFinally
            )
        );
    };

    const _handleClickButtonAction = (value) => {
        const listIdChecked = checkedItems.ids;
        if (listIdChecked.length) {
            refConfirm.current.open({ ids: listIdChecked }, t(`common:${LIST_BUTTON_MESSAGES[value]}`));
        }
    };

    // Dispatch action

    const _onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState({ ...state, checkedItems: stateCheckedItems });
    };

    const _renderActionHeader = () => {
        return (
            <>
                <DropDownFromEmail />
                <div className="header --filter">
                    <CheckBoxHeader checkedItems={checkedItems} />
                    <ListButtonAction
                        list={LIST_BUTTONS}
                        fileTranslation="report"
                        onSubmit={_handleClickButtonAction}
                        disabled={!checkedItems.ids.length}
                    />
                </div>
            </>
        );
    };

    const _renderContentConfirm = () => <p>{t('setting:warning_delete_template_broadcast')}</p>;

    const _handleOpenTemplate = ({ row }) => {
        const idTemplate = row?.id || null;
        refTemplate.current.displayTemplate(idTemplate, null, !idTemplate, data);
    };

    const _handleCloseTemplate = (newTemplate, isCreate, message) => {
        if (newTemplate) {
            if (isCreate) {
                dispatchState({ data: [...data, newTemplate] });
                refAlert.current &&
                    refAlert.current.showStatusBar({
                        id: LIST_STATUS.SUCCESS,
                        message,
                        type: LIST_STATUS.SUCCESS
                    });
            } else {
                dispatchState({
                    data: data.map((item) => {
                        if (item.id === newTemplate.id) return { ...item, name: newTemplate.name };
                        return item;
                    })
                });
            }
        }
    };

    return (
        <>
            <MainHeaderSettings
                contentRight={() => <HeaderBroadCast onAddTemplate={_handleOpenTemplate} />}
                tutorial={tutorial}
                isLoading={isLoading}
            />
            <div className="wrapper-columns setting-custom">
                <div className="container-print contents-pages">
                    <AlertCustomer ref={refAlert} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.AUTOMATED_EMAILS} />
                    {_renderActionHeader()}
                    <GdGridView
                        isLoading={state.isLoading}
                        classTable="scrolls-x has-checkbox --email"
                        content={state.data}
                        showCheckBox
                        fileTranslation={'setting'}
                        handleClick={_handleOpenTemplate}
                        checkedItems={checkedItems}
                        onChangeStateCheckedItems={_onChangeStateCheckedItems}
                        msgEmpty={t('setting:create_your_first_template')}
                        {...getColumns()}
                    />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:confirm')}
                        listButton={{ cancel: true, confirm: true }}
                        renderContent={_renderContentConfirm()}
                        onConfirm={_onConfirmDeleteTemplate}
                        onCancel={() => {}}
                    />
                    <ModalLoading ref={refLoading} />
                </div>
                <SettingsTemplate
                    ref={refTemplate}
                    textEmpty={t('setting:add_your_first_broadcast_template')}
                    type={TYPE_TEMPLATE.BROADCAST}
                    templateType={TYPE_TEMPLATE_ID.BROADCAST}
                    onClose={_handleCloseTemplate}
                />
            </div>
        </>
    );
}

export default SettingsBroadcast;
