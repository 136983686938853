import loadable from '@loadable/component';
import { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { COMMON } from 'app/const/App';
import { ACCESS_SETTINGS_TAB, PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { TAXES } from 'app/const/setting/SettingPageName';
import { getTabParams } from 'app/const/setting/SettingParams';
import { LIST_BUTTONS, LIST_BUTTON_MESSAGES, TAB, getColumns } from 'app/const/setting/SettingTaxes';
import { LIST_TOOLTIP, MAP_VALUE_BUTTON } from 'app/const/Settings.js';
import { LIST_STATUS } from 'app/const/Status';
import { mixpanelAddTax } from 'app/modules/mixpanel/MixpanelAddTax';
import IconSync from 'assets/icon/IconSync';
import {
    createTax,
    getListSettingTaxes,
    updateStatusSettingTaxes,
    updateTax
} from 'common/redux/actions/settings/taxesAction';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { checkAccessFail, checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import SettingsGlobalOverride from '../globalOverride';
import { getLocalStorageSettingPage, updateLocalStorageSettingPage } from '../utils/localStorage';
import { getNewParamsOrderStatus } from '../utils/statusOrder';

const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const GdButton = loadable(() => import('app/components/button'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ListButtonAction = loadable(() => import('app/modules/settings/components/ListButtonAction'));
const SettingTabsFilter = loadable(() => import('app/modules/settings/components/SettingTabsFilter'));
const SettingPagination = loadable(() => import('app/modules/settings/components/SettingPagination'));
const TaxForm = loadable(() => import('app/modules/settings/taxes/components/TaxForm'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function SettingsTaxes({ routes = [] }) {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const { profile, permissions } = useSelector(({ auth }) => auth.user);
    const { isBasicPlan } = getPlanUser(profile);
    const isHavePermissionOverride =
        !isBasicPlan && checkPermission(permissions.enabled, PERMISSIONS.accessSettingsTab);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        checkedItems: {
            is_check_all: false,
            ids: []
        },
        listTab: TAB.LIST,
        currentTab: getLocalStorageSettingPage({
            namePage: TAXES,
            key: COMMON.CURRENT_TAB,
            defaultCurrentTab: TAB.DEFAULT_SELECTED
        }),
        total: 1,
        params: getLocalStorageSettingPage({ namePage: TAXES, key: COMMON.PARAMS }),
        isOverride: false,
        tutorial: {}
    });

    const { checkedItems, params: paramsTaxes, isOverride, tutorial, isLoading } = state;

    const refModal = useRef(null);
    const refConfirm = useRef(null);
    const refCreateTax = useRef(true);
    const refStatusBar = useRef(null);

    useEffect(() => {
        _getListTax(paramsTaxes);
    }, []);

    // Callback after call API
    const _getListTaxSuccess = ({ data, total, tutorial }) => {
        dispatchState((prev) => ({
            ...prev,
            isLoading: false,
            data,
            currentTab: getLocalStorageSettingPage({ namePage: TAXES, key: COMMON.CURRENT_TAB }),
            checkedItems: { is_check_all: false, ids: [] },
            total,
            params: getLocalStorageSettingPage({ namePage: TAXES, key: COMMON.PARAMS }),
            tutorial
        }));
    };

    const _getListTaxFailure = (err) => {
        dispatchState({ isLoading: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _createTaxSuccess = ({ message }) => {
        handleTrackingEvent(mixpanelAddTax({ id: profile.id }));
        refModal.current.hideModal();
        if (state.currentTab === state.listTab[0].value) {
            _getListTax(paramsTaxes);
        }
        refStatusBar.current.showStatusBar('show_success', message, LIST_STATUS.SUCCESS);
    };

    const _createTaxFailure = () => {
        refModal.current.hideModal();
        dispatchState({ isLoading: false });
        refStatusBar.current.showStatusBar('show_error', t('setting:create_tax_failure'), LIST_STATUS.ERROR);
    };

    const _updateTaxSuccess = ({ message }, taxUpdated) => {
        refModal.current.hideModal();

        dispatchState((prev) => ({
            ...prev,
            isLoading: false,
            data: state.data.map((item) => {
                if (item.id === taxUpdated.id) return taxUpdated;
                return item;
            })
        }));
        refStatusBar.current.showStatusBar('show_success', message, LIST_STATUS.SUCCESS);
    };

    const _updateTaxFailure = () => {
        refModal.current.hideModal();
        dispatchState({ isLoading: false });
        refStatusBar.current.showStatusBar('show_error', t('setting:edit_tax_failure'), LIST_STATUS.ERROR);
    };

    const _checkIsInLastPage = ({ total, offset, limit }) => {
        if (offset + limit >= total) return true;
        return false;
    };

    const _checkIsInFirstPage = ({ total, limit }) => {
        if (limit >= total) return true;
        return false;
    };

    const _updateStatusTaxesSuccess = (response) => {
        refModal.current.hideModal();
        refStatusBar.current.showStatusBar('show_success', response.message, LIST_STATUS.SUCCESS);
        const params = getLocalStorageSettingPage({ namePage: TAXES, key: COMMON.PARAMS });
        const isLastPage = _checkIsInLastPage({ total: state.total, offset: params.offset, limit: params.limit });

        //Process went action in last page,
        if (isLastPage) {
            const isRemoveAllItemInPage = response.listIdChecked.length === state.data.length;
            const isInFirstPage = _checkIsInFirstPage({ total: state.total, limit: params.limit });
            //If newDataTaxes empty after update and we not in 1stPage, re call API with nearest Page
            if (isRemoveAllItemInPage && !isInFirstPage) {
                const newParams = params;
                newParams.offset = params.offset - params.limit;
                _getListTax(newParams, state.currentTab, false);
            }
        }
        if (!isLastPage) _getListTax(params, state.currentTab, false);
    };

    const _updateStatusTaxesFailure = (response) => {
        refStatusBar.current.showStatusBar('show_error', response.message, LIST_STATUS.ERROR);
        dispatchState({ isLoading: false });
    };

    // Dispatch call API
    const _getListTax = (params, tabId = state.currentTab, isLoading = true) => {
        updateLocalStorageSettingPage({
            namePage: TAXES,
            value: { params: params, current_tab: tabId }
        });
        dispatchState({ isLoading: isLoading, params: params, currentTab: tabId });
        dispatch(getListSettingTaxes(params, _getListTaxSuccess, _getListTaxFailure));
    };

    const _handleSaveOnModal = (params) => {
        if (params.id) {
            dispatch(updateTax(params, (response) => _updateTaxSuccess(response, params), _updateTaxFailure));
        } else {
            dispatch(createTax(params, _createTaxSuccess, _createTaxFailure));
        }
    };

    const _handleChangeTab = (tabId) => _getListTax({ ...paramsTaxes, ...getTabParams(tabId), offset: 0 }, tabId);
    const _handleChangePage = (newParams) => {
        _getListTax(newParams);
    };

    const _onConfirmUpdateStatusTaxes = (params) => {
        const taxesUpdated = params.ids;
        const newDataTaxes = state.data.filter((taxItem) => {
            if (taxesUpdated.includes(taxItem.id)) return false;
            return true;
        });
        dispatchState((prev) => ({
            ...prev,
            data: newDataTaxes,
            checkedItems: {
                is_check_all: false,
                ids: []
            }
        }));
        dispatch(updateStatusSettingTaxes(params, _updateStatusTaxesSuccess, _updateStatusTaxesFailure));
    };

    const _handleClickButtonAction = (value) => {
        const listIdChecked = checkedItems.ids;
        if (listIdChecked.length) {
            const taxStoreController = {
                listTaxSelected: [],
                isAddTaxesInStore: false,
                isRemoveTaxesInStore: false
            };
            if (value === MAP_VALUE_BUTTON.ACTIVE || value === MAP_VALUE_BUTTON.UN_DELETE) {
                taxStoreController.listTaxSelected = state.data.filter((tax) => listIdChecked.includes(tax.id));
                taxStoreController.isAddTaxesInStore = true;
            }
            if (state.currentTab === TAB.DEFAULT_SELECTED) {
                taxStoreController.isRemoveTaxesInStore = true;
            }
            refConfirm.current.open(
                { ids: listIdChecked, type: value, taxStoreController: taxStoreController },
                t(`common:${LIST_BUTTON_MESSAGES[value]}`)
            );
        }
    };

    const _onClickHeaderCallBack = (action) =>
        _getListTax(getNewParamsOrderStatus({ params: paramsTaxes, actionEvent: action }));
    // Dispatch action

    const _handleEditTaxes = (taxesItem) => {
        const taxSelected = { ...state.data.find((tax) => tax.id === taxesItem.row.id) };
        refModal.current.showModal(taxSelected);
    };

    const _handleAddItem = () => {
        refModal.current.showModal();
        refCreateTax.current = true;
    };

    const _onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState({ checkedItems: stateCheckedItems });
    };

    const _renderHeaderLeft = () => {
        if (isOverride) return null;
        return <SettingTabsFilter list={state.listTab} onChange={_handleChangeTab} tabActive={state.currentTab} />;
    };

    const _renderHeaderRight = () => {
        if (isOverride) return null;

        return (
            <Fragment>
                {isHavePermissionOverride ? (
                    <div className="header-items">
                        <span className="v2-btn-default has-icon" onClick={() => _handleToggleOverride(true)}>
                            <IconSync />
                            {t('tax_global_override')}
                        </span>
                    </div>
                ) : null}

                <div className="modal-setting header-items">
                    <GdButton
                        className="v2-btn-main has-icon svg-white"
                        title={t('setting:add_tax')}
                        iconSvg={<IconPlus />}
                        onClick={_handleAddItem}
                    />
                    <TaxForm ref={refModal} handleSave={_handleSaveOnModal} />
                </div>
            </Fragment>
        );
    };

    const _renderActionHeader = () => {
        return (
            <div className="header --filter">
                <CheckBoxHeader checkedItems={checkedItems} />
                <ListButtonAction
                    list={LIST_BUTTONS[state.currentTab]}
                    fileTranslation="report"
                    onSubmit={_handleClickButtonAction}
                    disabled={!checkedItems.ids.length}
                />
            </div>
        );
    };

    const _handleToggleOverride = (value = false) => {
        dispatchState({ isOverride: value });
        if (!value) _getListTax(paramsTaxes);
    };

    if (isOverride && isHavePermissionOverride)
        return <SettingsGlobalOverride routes={routes} onClickBack={_handleToggleOverride} />;

    return (
        <>
            <MainHeaderSettings
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                tutorial={tutorial}
                isLoading={isLoading}
            />
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages maintables-page">
                    <StatusBar ref={refStatusBar} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.TAXES} onAddNew={_handleAddItem} />
                    {_renderActionHeader()}
                    <div className="tab-contents box-auto has-footer">
                        <div className="tab-conts tab-content-active">
                            <GdGridView
                                isLoading={state.isLoading}
                                classTable="scrolls-x has-checkbox"
                                content={state.data}
                                showCheckBox
                                fileTranslation={'setting'}
                                handleClick={_handleEditTaxes}
                                checkedItems={state.checkedItems}
                                onChangeStateCheckedItems={_onChangeStateCheckedItems}
                                handleClickHeader={_onClickHeaderCallBack}
                                {...getColumns({
                                    actionStatus: state.params?.order,
                                    currentTab: state.currentTab
                                })}
                                isScroll
                            />
                        </div>
                    </div>
                    <SettingPagination totalItem={state.total} namePage={TAXES} onSelect={_handleChangePage} />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:confirm')}
                        message={t('setting:are_you_sure_delete_this_item')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_onConfirmUpdateStatusTaxes}
                        onCancel={() => {}}
                    />
                </div>
            </div>
        </>
    );
}

export default SettingsTaxes;
